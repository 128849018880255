






























import { Component, Vue } from 'vue-property-decorator'
import OrderSummaryCard from '@/views/order-parts/complete-order/OrderSummaryCard.vue'
import Loading from '@/components/Loading.vue'
import { GETTERS as CART_GETTERS, ACTIONS as CART_ACTIONS, OrderedPart } from '@/store/modules/parts/cart'
import { PAGE_VIEW } from '@/views/order-parts/OrderParts.vue'
import { getPriceFromString } from '@/util'
import EditButton from '@/components/EditButton.vue'

@Component({
  components: {
    EditButton,
    OrderSummaryCard,
    Loading,
  }
})
export default class OrderSummary extends Vue {
  status = 'loading'

  mounted(): void {
    this.status = 'loaded'
  }

  get orderSummaryList(): OrderedPart[] {
    return this.$store.getters[CART_GETTERS.GET_SELECTED_PARTS]
  }

  get totalPrice(): string {
    return getPriceFromString(this.$store.getters[CART_GETTERS.TOTAL_PRICE_ESTIMATE])
  }

  removeItem(partId: number): void {
    this.$store.dispatch(CART_ACTIONS.REMOVE_PART, partId)
  }

  editOrder(): void {
    this.$router.push({ path: this.$route.path, query: { ...this.$router.currentRoute.query, view: PAGE_VIEW.NewOrder }})
  }
}
