

















import { Vue, Component } from "vue-property-decorator"
import FormWrapper from '@/components/forms/FormWrapper.vue'
import { Auth } from '@aws-amplify/auth'
@Component({
  components: {
    FormWrapper
  }
})
export default class PasswordForm extends Vue {
  errorMessage = ''
  currentPassword = ''
  newPassword = ''
  repeatedNewPassword = ''
  
  submit(): void {
    this.errorMessage = ''
    if (this.newPassword !== this.repeatedNewPassword) {
      this.errorMessage = "New password doesn't match"
      return
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, this.currentPassword, this.newPassword);
      })
      .then(() => this.$parent.$emit('close'))
      .catch(err => this.errorMessage = err.message);
  }
}
