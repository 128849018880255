









import Vue from 'vue'
import EditIcon from '@/assets/img/edit.svg'
import { Component } from 'vue-property-decorator'


@Component({
  components: {
    EditIcon,
  }
})
export default class EditButton extends Vue {}
