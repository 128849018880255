























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DatePicker } from 'v-calendar'

export interface DateRange {
  start: Date;
  end: Date;
}

@Component({
  components: {
    DatePicker
  }
})
export default class DatepickerWrapper extends Vue {
  @Prop({ default: false}) isRange!: boolean
  @Prop({ default: ''}) placeholder!: string
  @Prop({ default: ''}) rangeStartPlaceholder!: string
  @Prop({ default: ''}) rangeEndPlaceholder!: string
  @Prop({ default: false}) disabled!: boolean
  /** Amount of days to automatically set on load for ranges ex: 14 == now -> 2 weeks from now */
  @Prop({ default: 0 }) rollingDays!: number
  /** Debounce time in milliseconds */
  @Prop({ default: 200 }) inputDebounceTime!: number

  @Prop({ default: null }) dateValue!: Date | null
  @Prop({ default: null }) dateRangeValue!: DateRange | null

  date: Date | null = null
  range: DateRange | null = null

  @Watch('dateValue', { immediate: true })
  watchDateValue(value: Date | null): void {
    this.date = value
  }

  @Watch('dateRangeValue', { immediate: true })
  watchDateRangeValue(value: DateRange | null): void {
    this.range = value
  }

  mounted(): void {
    if (this.isRange && this.rollingDays > 0) {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + this.rollingDays);
      this.range = {
        start: new Date(),
        end: futureDate
      }
    }
  }

  rangeChanged(newRange: DateRange | null): void {
    // Set start to beginning of day and end to end of day
    if (newRange) {
      newRange.start.setHours(0, 0 ,0, 0)
      newRange.end.setHours(23, 59, 59, 999)
    }

    this.$emit('rangeChange', newRange)
  }

  dateChanged(newDate: Date | null): void {
    this.$emit('dateChange', newDate)
  }
}
