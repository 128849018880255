















import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    Multiselect,
  }
})
export default class SingleselectWrapper extends Vue {
  @Prop() propertyName!: string
  @Prop({ default: null }) required!: boolean | null
  @Prop() value!: null | string | Record<string, unknown>
  @Prop( { default: false }) clearable!: boolean

  @Ref('multiselect') multiselect!: Multiselect

  multiselectInput: HTMLInputElement | null = null

  get placeholderText(): string | unknown {
    if (this.value !== null) {
      if (typeof this.value === 'string') {
        return this.value !== '' ? this.value : this.propertyName
      } else {
        return this.$attrs['label'] ? this.value[this.$attrs['label']] : this.propertyName
      }
    } else {
      return this.propertyName
    }
  }

  @Watch('value')
  onValueChange(newValue: null | string | Record<string, unknown>): void {
    if (this.multiselectInput) {
      this.multiselectInput.required = this.required !== null && (newValue === null || newValue === '')
    }
  }

  mounted(): void {
    this.multiselectInput = this.multiselect.$el.querySelector('input')
    if (this.multiselectInput) {
      this.multiselectInput.required = this.required !== null && (this.value === null || this.value === '')
    }
  }
}
