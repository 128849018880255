import { Module } from 'vuex'
import { RootState } from '@/store'
import {
  namespaced,
  tableActions,
  tableGetters,
  tableMutations,
  tableState,
  TableState,
  TABLE_ACTIONS,
  TABLE_MUTATIONS,
  TABLE_GETTERS,
} from '@/store/mixins/table'
import { PaginatedResponse } from '@/api'
import devices, { CabinetTransaction, ScopeTrackingFilters } from '@/api/devices'

export enum SCOPE_TRACKING_ACTIONS {
  UPDATE_SCOPE_TRACKING_FILTERS = 'UPDATE_SCOPE_TRACKING_FILTERS',
}

export enum SCOPE_TRACKING_MUTATIONS {
  SET_SCOPE_TRACKING_FILTERS = 'SET_SCOPE_TRACKING_FILTERS',
}

export const namespace = 'scopeTrackingTable'
export const ACTIONS = namespaced({ ...TABLE_ACTIONS, ...SCOPE_TRACKING_ACTIONS }, namespace)
export const GETTERS = namespaced({ ...TABLE_GETTERS, ...SCOPE_TRACKING_MUTATIONS }, namespace)

interface ScopeTrackingState extends TableState<CabinetTransaction>{
  scopeTrackingFilters: ScopeTrackingFilters
}

export function defaultState(): ScopeTrackingState {
  return {
    ...tableState<CabinetTransaction, ScopeTrackingState>(),
    scopeTrackingFilters: {
      actions: [],
    },
  }
}
export const module: Module<ScopeTrackingState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: tableGetters<CabinetTransaction, ScopeTrackingState>(),
  mutations: tableMutations<CabinetTransaction, ScopeTrackingState>({
    [SCOPE_TRACKING_MUTATIONS.SET_SCOPE_TRACKING_FILTERS]: (state, scopeTrackingFilters: ScopeTrackingFilters) => {
      state.scopeTrackingFilters = scopeTrackingFilters
    },
  }),
  actions: tableActions<CabinetTransaction, ScopeTrackingState>({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<CabinetTransaction>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return await devices.getLatestScopeTransactions(
        offset,
        limit,
        state.order,
        undefined,
        state.filterIds,
        state.scopeTrackingFilters
      )
    },
    [SCOPE_TRACKING_ACTIONS.UPDATE_SCOPE_TRACKING_FILTERS]: async ({ state, commit, dispatch }, filters: Partial<ScopeTrackingFilters>): Promise<void> => {
      commit(SCOPE_TRACKING_MUTATIONS.SET_SCOPE_TRACKING_FILTERS, { ...state.scopeTrackingFilters, ...filters })
      // We set page to zero to stop filtering jank
      commit(TABLE_MUTATIONS.SET_PAGE, 0)
      await dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
    },
  }),
}
