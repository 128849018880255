












import { LoadingStatus } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class TableFooter extends Vue {
  @Prop() status!: LoadingStatus
  @Prop() errorMessage?: string
  @Prop() description!: string
  @Prop() moreAvailable!: boolean

  handleClick(): void {
    if (this.status === 'error') {
      this.$emit('retry')
    } else if (this.status === 'loaded' && this.moreAvailable) {
      this.$emit('more')
    }
  }
}

