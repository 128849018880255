import { FilterOp, FilterStatement } from '@/api/models'
import { DateTime } from 'luxon'

export async function asyncForEach<T>(array: T[], callback: (item: T, index: number, allItems: T[]) => void): Promise<void[]> {
  return await Promise.all(array.map(callback));
}

export type Optional<T> = T | undefined | null;

/**
  Determines if the given ISO strings are passed due

  If start is before the current date, current date will be used
  If start is after the current date, the start date will be used
*/
export function isPassedDue(start: string, end: string|null): boolean {
  if (!end) {
    return false
  }

  const startDate = new Date(start)
  const currentDate = new Date()
  const endDate = new Date(end)

  if (startDate < currentDate) {
    return monthDiff(currentDate, endDate) <= 0
  }

  return monthDiff(startDate, endDate) <= 0
}

/**
  Determines how many months are between two given Dates

  If date1 is before the current date, current date will be used
  If date1 is after the current date, date1 will be used

  https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
*/
export function monthDiff(date1: Date, date2: Date): number {
  let months;

  const current = new Date()
  if (date1 < current) {
    date1 = current
  }

  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months;
}

/**
  Extract just the full month and year form a given ISO date

  e.g.
  2022-10-21 11:16:38.369261-04
  October 2022
*/
export function getMonthYearFromIsoDate(date: string): string {
  return new Date(date).toLocaleString('default', { month: 'long', year: 'numeric' });
}

/**
 Convert a ISO string to a date string in locale format
 */
export function getLocaleStringFromIsoDate(date: string | number | null | undefined): string {
  return date ? new Date(date).toLocaleString('default') : '';
}

/**
  Converts given string to a USD formatted 'price tag'
  i.e. adds zeros if necessary or rounds to the nearest cent
 */
export function getPriceFromString(price: string): string {
  return Number(parseFloat(price)).toLocaleString('en-us', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  )
}

/**
  Converts given value to UUID array
  Generally going to be used to convert the whack datatype vue router gives us
  when we try to pluck queryParams from the URL into something we can use
 */
export function convertToUuidArray(queryParams: string|(string|null)[]|undefined): string[] {
  if (queryParams !== undefined) {
    if (Array.isArray(queryParams)) {
      return queryParams?.filter(value => value != null) as string[]
    }

    return [queryParams]
  }

  return []
}

/**
  Converts given Filters into to a query string that can filter results in the backend.
  Takes in an object containing keys to values stored in state, and an enum that should match to the key in state to the appropriate DB Column
  (See FilterType as an example)

  op - The operation to perform in db terms
  attr - the db column to search
  val - the value to match

  Will return a json representation of an object filters while dropping null/undefined values
  Converts Object
  {
    departmentName: [
      "Imperial Army",
      "TIE Fighter Pilots"
    ],
    deviceName: [
      "Death Star"
    ]
    userName: [],
  }

  Into String
  [
    {
      "op": "or",
      "val":
      [
        {
          "op": "==",
          "attr": "departmentName",
          "val": "Imperial Army"
        },
        {
          "op": "==",
          "attr": "departmentName",
          "val": "TIE Fighter Pilots"
        }
      ],
    },
    {
      "op": "==",
      "attr": "deviceName",
      "val": "Death Star"
    }
  ]
 */
export function getFilterQueryFromFilters(filters: Record<string, string[]|Date>, customEnum: { [_: string]: string }): (FilterStatement|FilterOp)[] {
  // Get all filter values from the filters passed in
  const values: FilterOp[][] = Object.entries(filters).map(([attrName, value]) => {
    if (value instanceof Date) {
      // This might not always be true but it should be
      if (attrName.endsWith('Start')) {
        return [{
          op: OPERATOR.GREATER_THAN_EQUAL_TO,
          attr: customEnum[attrName],
          val: value.toISOString()
        }]
      } else if (attrName.endsWith('End')) {
        return [{
          op: OPERATOR.LESS_THAN_EQUAL_TO,
          attr: customEnum[attrName],
          val: value.toISOString()
        }]
      }
    } else if (value) {
      return value.map((value: string) => {
        // Handle any properties that search via JSONB first
        if (customEnum[attrName] === 'device.attributes') {
          return {
            op: OPERATOR.JSON_PATH_MATCH,
            attr: customEnum[attrName],
            val: `$.lockType == "${value}"`
          }
        }

        const operatorAndValue: FilterOpValue = getOperatorAndValue(value)
        return {
          op: operatorAndValue.operator,
          attr: customEnum[attrName],
          val: operatorAndValue.value,
        }
      })
    }
    return []
  })

  // Remove all empty elements
  const valuesNoEmptyElements = values.filter(value => { return value.length !== 0 }) as FilterOp[][]

  // Add in 'or' logic if multiple present - else keep single object
  const result: (FilterStatement|FilterOp)[] = valuesNoEmptyElements.map((value) => {
    if (value.length > 1) {
      return {
        op: OPERATOR.OR,
        val: value,
      }
    } else {
      return value[0]
    }
  })

  return result
}

export function wrapFilterOp(op: OPERATOR, input: (FilterStatement|FilterOp)[]): FilterStatement {
  return {
    op: op,
    val: input
  }
}

export enum OPERATOR {
  OR = 'or',
  AND = 'and',
  LESS_THAN = '<',
  GREATER_THAN = '>',
  LESS_THAN_EQUAL_TO = '<=',
  GREATER_THAN_EQUAL_TO = '>=',
  EQUALS = '==',
  JSON_PATH_MATCH = 'jpm',
}

interface FilterOpValue {
  operator: OPERATOR,
  value: string
}

/**
 * Does evil magic and string manipulation to extract the operator and value out of a given value in state
 * to make our lives easier
 *
 * Most cases will just work with what's stored in state, but sometimes we'll need to have '<=' before the value
 * This will help in those cases and in the others nothing should be different
 */
function getOperatorAndValue(attribute: string): FilterOpValue {
  let operator = OPERATOR.EQUALS
  let value = attribute
  if (attribute.startsWith('>= ')) {
    operator = OPERATOR.GREATER_THAN_EQUAL_TO
    value = attribute.slice(3)
  } else if (attribute.startsWith('<= ')) {
    operator = OPERATOR.LESS_THAN_EQUAL_TO
    value = attribute.slice(3)
  } else if (attribute.startsWith('> ')) {
    operator = OPERATOR.GREATER_THAN
    value = attribute.slice(2)
  } else if (attribute.startsWith('< ')) {
    operator = OPERATOR.LESS_THAN
    value = attribute.slice(2)
  } else if (attribute.startsWith('$.')) {
    operator = OPERATOR.JSON_PATH_MATCH
    value = attribute
  } else if (attribute === '<<< 24HOURS') {
    operator = OPERATOR.LESS_THAN_EQUAL_TO
    value = DateTime.now().minus({ days: 1 })
  }

  return { operator, value }
}

export enum TIME_RESOLUTION {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
}
