








import { Component, Vue } from 'vue-property-decorator';
import Amplify from '@aws-amplify/core'

@Component
export default class SignIn extends Vue {
  launch(): void {
    Amplify.Auth.federatedSignIn()
  }
}
