





































import { Component } from 'vue-property-decorator';
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import { TableColumn } from '@/components/table-column'
import TableFooter from '@/components/TableFooter.vue'
import { ACTIONS, GETTERS } from '@/store/modules/user-management/website-user-management'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import WebsiteUserWizard from '@/components/forms/WebsiteUserWizard.vue'
import EmailLink from '@/components/EmailLink.vue'
import Modal from '@/components/Modal.vue'
import { User } from '@/api/users'
import EditButton from '@/components/EditButton.vue'
import { convertToUuidArray } from '@/util'
import UserManagementIcon from '@/assets/img/user-management-icon.svg'

@Component({
  components: {
    TableFooter,
    Modal,
    WebsiteUserWizard,
    EmailLink,
    EditButton,
    UserManagementIcon,
  }
})
export default class WebsiteUserTable extends VuexBackedTableBasedView<User> {
  MODAL_TYPE = MODAL_TYPE
  ACTIONS = ACTIONS
  GETTERS = GETTERS

  mounted(): void {
    this.loadData(this.proxyIds)
  }

  get proxyIds(): string[] {
    return convertToUuidArray(this.$router.currentRoute.query?.proxyId)
  }

  editUser(user: User): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.EDIT_USER, user: user.id })
  }

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }

  get columns(): TableColumn<User>[] {
    return [
      {
        title: 'User Name',
        headerColumn: true,
        headerClass: 'columnMedium',
        sticky: true,
        // https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/
        value: (row) => [row.last_name, row.first_name].join(', '),
        field: 'user.last_name',
      },
      {
        id: 'email',
        title: 'Email',
        value: (row) => row.username,
        field: 'user.username'
      },
      {
        title: 'User Type',
        value: (row) => row.user_type,
        field: 'user.user_type'
      },
      // TODO: Departments
      // {
      //   title: 'Departments',
      //   value: () => 'All',
      //   field: 'user.department'
      // },
      {
        title: 'Edit',
        headerClass: 'columnSmall',
      },
    ]
  }
}
