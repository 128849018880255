





















import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import axios from 'axios'
import clients, { Client } from '@/api/clients'
import { Department, DepartmentForm, DepartmentHack } from '@/api/departments'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import { DepartmentFormGroup } from "@/api/departments"
import { asyncForEach } from "@/util"

// multiselects have a property 'value' that holds the objects you're selecting, but we can't
// infer it easily, so this interface lets us make a wild assumption and move-on
interface DepartmentListHolder {
  value: Department[]
}

@Component({
  components: {
    MultiselectWrapper,
  }
})
export default class MultiClientDepartmentSelect extends Vue {
  @Prop({required: true}) clients!: Client[]
  @Prop({default: false}) disabled!: boolean

  @Ref('wrapper') wrapper!: MultiselectWrapper

  loading = false
  availableDepartmentFormGroups: DepartmentFormGroup[] = []
  newDepartmentTag = ''

  get currentSelectedDepartments(): Department[] {
    // This works most of the time... but let's just round that up to all of the time and move on
    return (this.wrapper.multiselect as unknown as DepartmentListHolder).value
  }

  // In order for tags to function with "group labels", we need to cram a secret "DepartmentHack"
  // into the department list in each group that serves as the tag target. If the user clicks on
  // it, we'll get all of the info we need to create a client in the @tag callback
  get availableDepartmentFormGroupsWithTags(): DepartmentFormGroup[] {
    const result = this.availableDepartmentFormGroups.map(dfg => {
      return {
        ...dfg,
        departments: this.newDepartmentTag !== '' && (dfg.departments.findIndex(dep => dep.name === this.newDepartmentTag) === -1) ? [{
          isTag: true,
          label: new DepartmentHack(this.newDepartmentTag, dfg.client_id),
          id: dfg.client_id + this.newDepartmentTag,
          client_id: dfg.client_id,
          name: this.newDepartmentTag,
        } as DepartmentForm].concat(dfg.departments) : dfg.departments,
      }
    })

    if (result.every(dfg => dfg.departments.length === 0)) {
      return []
    } else {
      return result
    }
  }

  // Adds a department with custom user entered name to the currently selected client
  async addDepartmentToClient(bundle: DepartmentHack): Promise<void> {
    this.loading = true
    try {
      const newDepartment = await clients.addDepartment(bundle.client_id, {
        id: bundle.name + bundle.client_id, // Just need an unique id for the dropdown, backend will ignore upon creation
        client_id: bundle.client_id,
        name: bundle.name,
      })
      const departmentFormGroup = this.availableDepartmentFormGroups.find((dfg) => dfg.client_id === bundle.client_id)
      departmentFormGroup?.departments.push(newDepartment)
      departmentFormGroup?.departments.sort((a, b) => a.name.localeCompare(b.name))

      this.$emit('input', this.currentSelectedDepartments.concat(newDepartment))
      this.$emit('departmentsLoaded', this.availableDepartmentFormGroups)

    } catch(error) {
      if (axios.isAxiosError(error)) {
        this.$emit('error', error.response?.data?.description ?? 'Failed to add department to client.')
      }
    }
    this.loading = false
  }

  @Watch('clients')
  async onClientListChange(newClients: Client[], oldClients: Client[]): Promise<void> {
    // Clear input of items that were removed
    let currentDepartments = this.currentSelectedDepartments

    // This watcher fires when the user initially loads, and we don't want to clear the input as the client lists load in
    let emitChanges = false

    oldClients.forEach((client: Client) => {
      if (newClients.includes(client)) return

      const idxToRemove = this.availableDepartmentFormGroups.findIndex((dfg) => dfg.client_id === client.id)
      this.availableDepartmentFormGroups.splice(idxToRemove, 1)

      currentDepartments = currentDepartments.filter((dep) => dep.client_id !== client.id)
      emitChanges = true
    })

    if (emitChanges) {
      this.$emit('input', currentDepartments)
    }

    // Add new department options if clients were added
    await asyncForEach(newClients, async (client: Client) => {
      if (oldClients.includes(client)) return

      this.availableDepartmentFormGroups.splice(1, 0, {
        client_id: client.id,
        clientName: client.name,
        departments: (await clients.getDepartments(client.id)).items.sort((a, b) => a.name.localeCompare(b.name)),
      })
    })

    this.availableDepartmentFormGroups.sort((a, b) => a.clientName.localeCompare(b.clientName))
  }
}
