
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import clients from '@/api/clients'
import { Department } from '@/api/departments'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import { uuid } from '@/api/models'

@Component({
  components: {
    SingleselectWrapper,
  }
})
export default class SingleDepartmentSelect extends Vue {
  @Prop({required: true}) clientId!: uuid | null
  @Prop({default: false}) disabled!: boolean

  loading = false
  availableDepartments: Department[] = []

  // Adds a department with custom user entered name to the currently selected client, should only be called if a client is selected.
  async addDepartmentToClient(departmentName: string): Promise<void> {
    if (this.clientId) {
      this.loading = true
      try {
        const newDepartment = await clients.addDepartment(this.clientId, {
          id: departmentName + this.clientId, // Just need an unique id for the dropdown, backend will ignore upon creation
          client_id: this.clientId,
          name: departmentName,
        })
        this.availableDepartments.push(newDepartment)
        this.availableDepartments.sort((a, b) => a.name.localeCompare(b.name))
        this.$emit('input', newDepartment)
        this.$emit('departmentsLoaded', this.availableDepartments)
      } catch(error) {
        if (axios.isAxiosError(error)) {
          this.$emit('error', error.response?.data?.description ?? 'Failed to add department to client.')
        }
      }
    }
    this.loading = false
  }

  @Watch('clientId')
  async onClientIdChange(newClientId: uuid | null, oldClientId: uuid | null): Promise<void> {
    if (oldClientId) {
      // clear input if the client changed
      this.$emit('input', null)
    }

    if (newClientId) {
      this.loading = true
      try {
        this.availableDepartments = (await clients.getDepartments(newClientId)).items
        this.availableDepartments.sort((a, b) => a.name.localeCompare(b.name))
        this.$emit('departmentsLoaded', this.availableDepartments)

        if (this.availableDepartments.length === 1) {
          this.$emit('input', this.availableDepartments[0])
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          this.$emit('error', error.response?.data?.description ?? 'Failed to fetch department list.')
        }
      }
      this.loading = false
    }
  }
}
