import instance, { API, formatOrderDescription, OrderDescription, OrderDirection, PaginatedResponse } from '.'
import { uuid } from './models'

export interface ClientGroup {
  id: uuid
  name: string
}

export class ClientGroupController {
  constructor(private api: API, private path: string = 'client_groups') {}

  async list(offset: number, limit: number, _order: OrderDescription[] = [], useCache = false): Promise<PaginatedResponse<ClientGroup>> {
    const fallback = { field: 'client_group.name', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)
    const cacheControl = useCache ? {} : { 'Cache-Control': 'no-cache' }

    const response = await this.api.authenticated.get<PaginatedResponse<ClientGroup>>('client_groups', {
      params: { limit, offset, order_by },
      headers: {
        ...cacheControl,
      }
    })

    return response.data
  }

  async create(name: string): Promise<ClientGroup> {
    const response = await this.api.authenticated.post<ClientGroup>(this.path, {
      name
    })

    return response.data
  }
}

export default new ClientGroupController(instance)
