






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { OrderedPart } from '@/store/modules/parts/cart'

@Component
export default class PartsListTable extends Vue {
  @Prop({ required: true }) data!: OrderedPart[]
  shortItemList: OrderedPart[] = []
  moreMessage = ''

  expanded = false

  mounted(): void {
    this.shortItemList = this.data.slice(0, 2)

    if (this.data.length > 2) {
      this.moreMessage = `+${this.data.length - 2} more`
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
  }
}
