

























import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import clients, { Client } from '@/api/clients'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import { convertToUuidArray } from '@/util'

@Component({
  components: {
    MultiselectWrapper,
    SingleselectWrapper,
  }
})
export default class ClientSelect extends Vue {
  @Prop({ default: false }) disabled!: boolean
  // By default results will be filtered when impersonating a client. This "proxyFilter" allows
  // parent components to simplify fetching all clients by fetching the client being impersonated.
  //   This behavior can be disabled if needed.
  @Prop({ default: null }) disableProxyFilter!: boolean | string | null
  // By default, this component will be a single select.
  @Prop({ default: null }) multiple!: boolean | string | null

  loading = false
  availableClients: Client[] = []

  private get proxyFilterEnabled(): boolean {
    return this.disableProxyFilter === null || this.disableProxyFilter === true
  }

  private get multipleEnabled(): boolean {
    return this.multiple === '' || this.multiple === true
  }

  async mounted(): Promise<void> {
    this.loading = true
    try {
      const proxyIds = convertToUuidArray(this.$route.query.proxyId)

      if (this.proxyFilterEnabled && proxyIds.length !== 0) {
        this.availableClients = [await clients.get(proxyIds[0])]
      } else {
        this.availableClients = (await clients.list(0, -1, [{ direction: "asc", field: "client.name" }])).items
      }
      this.$emit('clientsLoaded', this.availableClients)
      if (this.availableClients.length === 1) {
        this.$emit('input', this.multipleEnabled ? [this.availableClients[0]] : this.availableClients[0])
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.$emit('error', error.response?.data?.description ?? 'Failed to fetch client list.')
      }
    }
    this.loading = false
  }
}
