import { render, staticRenderFns } from "./PartsListTable.vue?vue&type=template&id=428f24b0&scoped=true&"
import script from "./PartsListTable.vue?vue&type=script&lang=ts&"
export * from "./PartsListTable.vue?vue&type=script&lang=ts&"
import style0 from "./PartsListTable.vue?vue&type=style&index=0&id=428f24b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428f24b0",
  null
  
)

export default component.exports