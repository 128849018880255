import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * Parent component used to give auto-closing functionality to popups in the top nav bar
 */
@Component
export default class NavBarPopup extends Vue {
  @Prop({ default: false }) shown!: boolean;

  // Watching this prop so that we can add document click listeners to trigger a close event when clicking outside this
  // menu. It removes the listener once the menu is no longer shown
  @Watch('shown', { immediate: true })
  shownChanged(newValue: boolean): void {
    if (newValue) {
      document.addEventListener('mousedown', this.close)
    } else {
      document.removeEventListener('mousedown', this.close)
    }
  }

  close(event: MouseEvent): void {
    if (!this.$el.parentNode?.contains(event.target as Node)) {
      this.$emit('close')
    }
  }
}