import { Module } from 'vuex'
import { RootState } from '@/store'
import {
  namespaced,
  tableActions,
  tableGetters,
  tableMutations,
  tableState,
  TableState,
  TABLE_ACTIONS,
  TABLE_GETTERS,
} from '@/store/mixins/table'
import { PaginatedResponse } from '@/api'
import devices, { DeviceType, Lock, ScopeTrackingFilters } from '@/api/devices'
import {
  BATTERY_PERCENTAGE_THRESHOLD,
  LAST_SEEN_THRESHOLD,
  LockSettingsFilters
} from '@/store/modules/reports/inter-connect-lock-reports'
import { getFilterQueryFromFilters, OPERATOR, wrapFilterOp } from '@/util'
import { FilterType } from '@/api/models'

export enum SMART_LOCK_TRACKING_ACTIONS {
  GET_CHART_DATA = 'GET_CHART_DATA',
}

export const namespace = 'InterConnectLockTrackingTable'
export const ACTIONS = namespaced({ ...TABLE_ACTIONS, ...SMART_LOCK_TRACKING_ACTIONS }, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)


interface InterConnectLockTrackingState extends TableState<Lock>{
  scopeTrackingFilters: ScopeTrackingFilters
}

export interface InterConnectLockTrackingChartData {
  totalLocks: number
  totalLowBatteryLocks: number
  totalMissingConnectionLocks: number
}

export function defaultState(): InterConnectLockTrackingState {
  return {
    ...tableState<Lock, InterConnectLockTrackingState>(),
  }
}
export const module: Module<InterConnectLockTrackingState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: tableGetters<Lock, InterConnectLockTrackingState>(),
  mutations: tableMutations<Lock, InterConnectLockTrackingState>(),
  actions: tableActions<Lock, InterConnectLockTrackingState>({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<Lock>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      // Add filters so this lock report only returns locks with issues
      const filters: LockSettingsFilters = {
        capabilities: [],
        batteryPercentageThresholds: [BATTERY_PERCENTAGE_THRESHOLD.LESS_THAN_10],
        lastSeenThresholds: [LAST_SEEN_THRESHOLD.TWENTY_FOUR_HOURS]
      }

      const query = wrapFilterOp(
        OPERATOR.OR,
        getFilterQueryFromFilters({ ...filters }, FilterType)
      )

      return await devices.list(
        offset,
        limit,
        state.order,
        state.filterIds,
        [DeviceType.LOCK],
        [query],
      ) as PaginatedResponse<Lock>
    },
    [SMART_LOCK_TRACKING_ACTIONS.GET_CHART_DATA]: async ({ state }): Promise<InterConnectLockTrackingChartData> => {
      const allLocksPromise = devices.list(
        0,
        0,
        [],
        state.filterIds,
        [DeviceType.LOCK],
        [],
      )

      const batteryThresholdFilter: LockSettingsFilters = {
        capabilities: [],
        batteryPercentageThresholds: [BATTERY_PERCENTAGE_THRESHOLD.LESS_THAN_10],
        lastSeenThresholds: [],
      }
      const batteryThresholdPromise = devices.list(
        0,
        0,
        [],
        state.filterIds,
        [DeviceType.LOCK],
        getFilterQueryFromFilters({ ...batteryThresholdFilter }, FilterType),
      )

      const lostConnectionThresholdFilter: LockSettingsFilters = {
        capabilities: [],
        batteryPercentageThresholds: [],
        lastSeenThresholds: [LAST_SEEN_THRESHOLD.TWENTY_FOUR_HOURS]
      }
      const lostConnectionPromise = devices.list(
        0,
        0,
        [],
        state.filterIds,
        [DeviceType.LOCK],
        getFilterQueryFromFilters({ ...lostConnectionThresholdFilter }, FilterType),
      )

      const [allLocks, allBatteryThresholdLocks, allLostConnectionLocks] = await Promise.all([allLocksPromise, batteryThresholdPromise, lostConnectionPromise])
      return {
        totalLocks: allLocks.total,
        totalLowBatteryLocks: allBatteryThresholdLocks.total,
        totalMissingConnectionLocks: allLostConnectionLocks.total,
      }
    }
  }),
}
