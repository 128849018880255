




























































































import { Component } from 'vue-property-decorator'
import { Client } from '@/api/clients'
import { singleLineAddressFormat } from '@/api/models'
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import Modal from '@/components/Modal.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import ClientWizard from '@/components/forms/ClientWizard.vue'
import WebsiteUserWizard from '@/components/forms/WebsiteUserWizard.vue'
import EditButton from '@/components/EditButton.vue'
import TableFooter from '@/components/TableFooter.vue'
import { TableColumn } from '@/components/table-column'
import { ACTIONS, GETTERS } from '@/store/modules/client-management'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import ClientManagementIcon from '@/assets/img/client-management-icon.svg'
import PlusIcon from '@/assets/img/plus-icon.svg'
import SwapIcon from '@/assets/img/people_alt_black_24dp.svg'
import becomeClient from '@/views/ClientSummaryCard.vue'
import { PROXY_ID, PAGE_NAMES } from '@/api/misc'
import { USER_ACTIONS } from '@/store/modules/user'

@Component({
  components: {
    EditButton,
    TableFooter,
    Modal,
    ClientWizard,
    WebsiteUserWizard,
    ClientManagementIcon,
    PlusIcon,
    SwapIcon,
    MultiselectWrapper,
    becomeClient,    
  }
})
export default class ClientManagement extends VuexBackedTableBasedView<Client> {

  MODAL_TYPE = MODAL_TYPE
  GETTERS = GETTERS
  ACTIONS = ACTIONS
  PAGE_NAMES = PAGE_NAMES

  
  filteredResults: Client[] = []
  accountNumberDropdownValue : string[] = []
  accountClientNameDropDown : string[] = []

  mounted(): void {
    this.loadData()
  }
  get accountNumberDropdownValueResults() : string[] {
    return this.accountNumberDropdownValue;
  }

  set accountNumberDropdownValueResults(newVal : string[]) {
    this.accountNumberDropdownValue = newVal;
    this.filteredResults = this.results.filter(client => newVal.includes(client.account_number))
    this.accountClientNameDropDown = this.filteredResults.map(client => client.name)
  }

  get clientNameDropDown(): string[] {
    return this.accountClientNameDropDown;
  }

  set clientNameDropDown(newVal: string[]) {
    this.accountClientNameDropDown = newVal;
    this.filteredResults = this.results.filter(client => newVal.includes(client.name))
    this.accountNumberDropdownValue = this.filteredResults.map(client => client.account_number)
  }
     
  becomeClient(destination: string, row: Client): void {
    this.$store.dispatch(USER_ACTIONS.BECOME_CLIENT)
    this.$router.push({ name: destination, query: { [PROXY_ID]: row.id}} )
    
  }
  
  //returns client list based on current selected client options
  get currentResults(): Client[] {
    return this.filteredResults.length ? this.filteredResults : this.results;
  }

  get columns(): TableColumn<Client>[] {
    return [
      {
        id: 'clientName',
        title: 'Client Name',
        field: 'client.name',
        value: (row) => row.name,
        headerColumn: true,
        headerClass: 'columnLarge',
        cellClass: 'enterClientLink',
        sticky: true,
      },
      {
        title: 'Hospital System',
        field: 'client_group.name',
        value: (row) => row.group?.name ?? '',
        headerClass: 'columnMedium'
      },
      {
        title: 'Account Number',
        field: 'client.account_number',
        value: (row) => row.account_number,
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      // { id: 'units',
      //   title: 'Drying Units',
      //   value: () => '#',
      //   cellClass: 'centered selectable',
      //   headerClass: 'columnSmall',
      // },
      // {
      //   id: 'future',
      //   title: 'Future Device',
      //   value: () => '#',
      //   cellClass: 'centered selectable',
      //   headerClass: 'columnSmall'
      // },
      {
        title: 'Address',
        value: singleLineAddressFormat,
        cellClass: 'selectable',
        headerClass: 'columnLarge'
      },
      {
        title: 'Edit',
        headerClass: 'columnSmall'
      }, 
      {
        id: 'impersonate',
        title: 'Impersonate Client',
        headerClass: 'columnSmall'
      },
    ]
  }

  newClient(): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.NEW_CLIENT })
  }

  editClient(client: Client): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.EDIT_CLIENT, client: client.id })
  }

  newUser(client: Client): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.NEW_USER, client: client.id })
  }

  enterClient(client: Client): void {
    this.$router.push({ path: `/settings/client-management/data/client-devices?clientId=${client.id}` })
  }

}
