










import { Vue, Component } from 'vue-property-decorator'
import { ACTIONS, GETTERS } from '@/store/modules/notification-management'
import { Auth } from '@aws-amplify/auth'
import { PAGE_NAMES } from '@/api/misc'

@Component

export default class NotificationTable extends Vue {
  ACTIONS = ACTIONS
  GETTERS = GETTERS
  PAGE_NAMES = PAGE_NAMES
  apiServerUrl = process.env.VUE_APP_SPARC_API_URL
  token =""
  clientIds: string[] = []
  proxyIds: string[] = []

  async loadData(): Promise<void> {
    const session = await Auth.currentSession();
    this.token = await session.getIdToken().getJwtToken();
  }

  get proxyId(): string{
    return this.$router.currentRoute.query?.proxyId as string;
  }

  get clientId(): string {
    let clientId = "";
    try {
      clientId = this.$store.getters.user.clients[0].id as string;
    } catch (error) {
      return clientId;
    }
    return clientId;
  }

  get userType(): string {
    return this.$store.getters.userType as string;
  }

  get userId(): string {
    let userId = "";
    try {
      userId = this.$store.getters.user.id as string;
    } catch (error) {
      return userId;
    }
    return userId;
  }

  get ecosystem(): string {
    return this.$route.name === PAGE_NAMES.NOTIFICATIONS_CABINETS ? 'ENDO' : 'SEC';
  }

}

