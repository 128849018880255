import instance, { API, formatOrderDescription, OrderDescription, OrderDirection, PaginatedResponse } from '.'
import { CRUD, HasAddress, uuid } from './models'
import { Department, DepartmentForm } from '@/api/departments'
import { ClientGroup } from '@/api/client-groups'

export enum PERMISSION_TYPE {
  ADMIN = 'admin',
  VIEW = 'view',
}

export interface ClientPermission {
  client_id: uuid
  action: PERMISSION_TYPE
}

export interface StateOption {
  name: string
  abbreviation: string
}

export interface NewClientForm extends HasAddress {
  name: string
  account_number: string
  group?: ClientGroup | null
  group_id?: string
}

export interface ClientLite {
  id: uuid
  name: string
}

export interface Client extends NewClientForm, CRUD {
  id: uuid
  total_cabinet_device_count: number
  total_lock_device_count: number
  subscribed_cabinet_device_count: number
  subscribed_lock_device_count: number
}

export class ClientController {
  constructor(private api: API, private path: string = 'clients') {}

  async list(offset: number, limit: number, _order: OrderDescription[], useCache = false): Promise<PaginatedResponse<Client>> {
    const fallback = { field: 'client.created_at', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)
    const cacheControl = useCache ? {} : { 'Cache-Control': 'no-cache' }

    const response = await this.api.authenticated.get<PaginatedResponse<Client>>(this.path, {
      params: { limit, offset, order_by },
      headers: {
        ...cacheControl,
      },
    })
    return response.data
  }

  async getDepartments(clientId: string): Promise<PaginatedResponse<Department>> {
    const response = await this.api.authenticated.get<PaginatedResponse<Department>>(`${this.path}/${clientId}/departments`, {
      headers: {
        'Cache-control': 'no-cache', // TODO This is a dirty rotten hack that should be illegal but is fine for beta testing and will get cleaned up during the department refactor
      }
    })
    return response.data
  }

  async addDepartment(clientId: string, departmentForm: DepartmentForm): Promise<Department> {
    const response = await this.api.authenticated.post<Department>(`${this.path}/${clientId}/departments`, departmentForm)
    return response.data
  }

  async create(newClientForm: NewClientForm): Promise<Client> {
    const response = await this.api.authenticated.post<Client>(this.path, newClientForm)
    return response.data
  }

  async update(client: Client): Promise<Client> {
    const response = await this.api.authenticated.post<Client>(`${this.path}/${client.id}`, client)
    return response.data
  }

  async get(clientId: string): Promise<Client> {
    const response = await this.api.authenticated.get<Client>(`${this.path}/${clientId}`)
    return response.data
  }

  async delete(clientId: uuid): Promise<unknown> {
    return this.api.authenticated.delete<unknown>(`${this.path}/${clientId}`)
  }

  async getClientSummary(): Promise<PaginatedResponse<Client>> {
    const response = await this.api.authenticated.get<PaginatedResponse<Client>>(this.path, {
      params: {
        limit: -1,
        offset: 0,
        has_devices: 'True',
      },
      headers: {
        'Cache-control': 'no-cache',
      }
    })
    return response.data
  }
}

export default new ClientController(instance)
