




import { Component, Vue } from 'vue-property-decorator'
import OrderHistoryAdminTable from '@/views/order-parts/OrderHistoryAdminTable.vue'

@Component({
  components: {
    OrderHistoryAdminTable,
  }
})
export default class OrderHistoryAdmin extends Vue {}
