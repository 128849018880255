import { Module } from 'vuex'
import devices, {
  IoTThing,
  Device,
  ClientSubscription,
  DeviceUpdate,
  ClientLinkUpdate,
  ClientLinkUpdateResponse,
  DeviceType,
  Lock
} from '@/api/devices'
import { RootState } from '@/store'
import { namespaced } from '@/store/mixins/table'
import {
  TABLE_ACTIONS,
  TABLE_GETTERS,
  TABLE_MUTATIONS,
  tableActions,
  tableGetters,
  tableMutations,
  TableState,
  tableState
} from '@/store/mixins/table'
import { PaginatedResponse } from '@/api'
import { uuid } from '@/api/models'
import { isPassedDue } from '@/util'

export enum DEVICE_ACTIONS {
  VERIFY = 'VERIFY',
  PAIR = 'PAIR',
  UPDATE_INFO = 'UPDATE_INFO',
  UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
  FETCH_ALL_CACHED_LOCKS = 'FETCH_ALL_CACHED_LOCKS',
  UPDATE_DEVICE_TYPES = 'UPDATE_DEVICE_TYPES',
  CHANGE_CLIENT = 'CHANGE_CLIENT',
}

export enum DEVICE_MUTATIONS {
  SET_DEVICE_TYPES = 'SET_DEVICE_TYPES',
}

interface DeviceManagementState extends TableState<Device>{
  deviceTypes: DeviceType[],
}

export const namespace = 'deviceManagement'
export const ACTIONS = namespaced({ ...TABLE_ACTIONS, ...DEVICE_ACTIONS }, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)

export const module: Module<DeviceManagementState, RootState> = {
  namespaced: true,
  state: tableState<Device, DeviceManagementState>({
    deviceTypes: []
  }),
  getters: tableGetters<Device, DeviceManagementState>(),
  mutations: tableMutations<Device, DeviceManagementState>({
    [TABLE_MUTATIONS.REPLACE_RESULT]: (state, updatedDevice: Device) => {
      const itemIdx = state.results.findIndex((item) => item.id === updatedDevice.id)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1, updatedDevice)
      }
    },
    [DEVICE_MUTATIONS.SET_DEVICE_TYPES]: (state, deviceTypes: DeviceType[]) => {
      state.deviceTypes = deviceTypes
    }
  }),
  actions: tableActions<Device, DeviceManagementState>({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<Device>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return devices.list(
        offset,
        limit,
        state.order,
        state.filterIds,
        state.deviceTypes ?? [],
        [],
        false,
      )
    },
    [DEVICE_ACTIONS.FETCH_ALL_CACHED_LOCKS]: async ({ state, commit }, proxyIds: uuid[]): Promise<PaginatedResponse<Lock>> => {
      const response = await devices.list(
        0,
        -1,
        state.order,
        proxyIds,
        [DeviceType.LOCK],
        [],
        state.useCache,
      ) as PaginatedResponse<Lock>
      commit(TABLE_MUTATIONS.SET_USE_CACHE, true)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    [TABLE_ACTIONS.GET]: async ({}, deviceId: uuid): Promise<Device> => {
      return devices.get(deviceId)
    },
    [DEVICE_ACTIONS.UPDATE_SUBSCRIPTION]: async({ commit }, { deviceId, deviceSubscription }: { deviceId: uuid, deviceSubscription: ClientSubscription }): Promise<Device> => {
      const updatedDevice = await devices.updateSubscription(deviceId, deviceSubscription)
      updatedDevice.passedDue = isPassedDue(updatedDevice.subscriptionStart, updatedDevice.subscriptionEnd)

      commit(TABLE_MUTATIONS.REPLACE_RESULT, updatedDevice)
      return updatedDevice
    },
    [DEVICE_ACTIONS.UPDATE_INFO]: async({ commit }, { deviceId, request }: { deviceId: uuid, request: DeviceUpdate }): Promise<Device> => {
      const deviceUpdate = { ...request }
      if (deviceUpdate.client) {
        deviceUpdate.clientId = deviceUpdate.client.id
        delete deviceUpdate.client
      }
      if (deviceUpdate.department) {
        deviceUpdate.departmentId = deviceUpdate.department.id
        delete deviceUpdate.department
      }
      const updatedDevice = await devices.updateInfo(deviceId, deviceUpdate)

      commit(TABLE_MUTATIONS.REPLACE_RESULT, updatedDevice)
      commit(TABLE_MUTATIONS.SET_USE_CACHE, false)
      return updatedDevice
    },
    // eslint-disable-next-line no-empty-pattern
    [DEVICE_ACTIONS.VERIFY]: async ({}, pairingCode: string): Promise<IoTThing> => {
      return devices.checkPairingCode(pairingCode)
    },
    [DEVICE_ACTIONS.PAIR]: async ({ dispatch, commit }, { pairingPin, request }: { pairingPin: string, request: DeviceUpdate}): Promise<Device> => {
      const deviceUpdate = { ...request }
      if (deviceUpdate.client) {
        deviceUpdate.clientId = deviceUpdate.client.id
        delete deviceUpdate.client
      }
      if (deviceUpdate.department) {
        deviceUpdate.departmentId = deviceUpdate.department.id
        delete deviceUpdate.department
      }
      const device = await devices.pairDevice(pairingPin, deviceUpdate) as Device
      commit(TABLE_MUTATIONS.SET_USE_CACHE, false)
      await dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
      return device
    },
    [DEVICE_ACTIONS.UPDATE_DEVICE_TYPES]: async ({ commit }, deviceTypes: DeviceType[]): Promise<void> => {
      await commit(DEVICE_MUTATIONS.SET_DEVICE_TYPES, deviceTypes)
    },
    [DEVICE_ACTIONS.CHANGE_CLIENT]: async ({ dispatch, commit }, { deviceId, request }: { deviceId: string, request: ClientLinkUpdate}): Promise<ClientLinkUpdateResponse> => {
      const res = await devices.changeClientLink(deviceId, request)

      if (request.dryRun === false) {
        commit(TABLE_MUTATIONS.SET_USE_CACHE, false)
        await dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
      }

      return res
    },
  }),
}
