import { Module } from 'vuex'
import { PaginatedResponse } from '@/api'
import { uuid } from '@/api/models'
import users, { User, NewUserForm } from '@/api/users'
import { RootState } from '@/store'
import {
  namespaced,
  tableActions,
  tableGetters,
  tableMutations,
  tableState,
  TableState,
  TABLE_ACTIONS,
  TABLE_GETTERS,
  TABLE_MUTATIONS,
} from '@/store/mixins/table'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserManagementState extends TableState<User> {
  // Put any module specific things in here
}

export const namespace = 'websiteUserManagement'
export const ACTIONS = namespaced(TABLE_ACTIONS, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)

export const module: Module<UserManagementState, RootState> = {
  namespaced: true,
  state: tableState<User, UserManagementState>(),
  getters: tableGetters(),
  mutations: tableMutations({
    [TABLE_MUTATIONS.REPLACE_RESULT]: (state, updatedUser: User) => {
      const itemIdx = state.results.findIndex((item) => item.id === updatedUser.id)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1, updatedUser)
      }
    },
    [TABLE_MUTATIONS.REMOVE_RESULT]: (state, userId: uuid) => {
      const itemIdx = state.results.findIndex((item) => item.id === userId)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1)
        state.total -= 1
      }
    },
  }),
  actions: tableActions({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<User>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return users.list(offset, limit, state.order, state.filterIds)
    },
    [TABLE_ACTIONS.CREATE]: async ({ dispatch }, newUserForm: NewUserForm): Promise<User> => {
      return users.create(newUserForm)
        .then((newUser) => {
          // TODO: This probably resets what page you're on, or removes too many entries if multiple pages are loaded.
          dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace'})
          return newUser
        })
    },
    // eslint-disable-next-line no-empty-pattern
    [TABLE_ACTIONS.GET]: async ({}, userId: uuid): Promise<User> => {
      return users.get(userId)
    },
    [TABLE_ACTIONS.UPDATE]: async ({ commit }, user: User): Promise<User> => {
      return users.update(user)
        .then((updatedUser) => {
          commit(TABLE_MUTATIONS.REPLACE_RESULT, updatedUser)
          return updatedUser
        });
    },
    [TABLE_ACTIONS.DELETE]: async ({ commit }, userId: uuid): Promise<unknown> => {
      return users.delete(userId)
        .then((response) => {
          commit(TABLE_MUTATIONS.REMOVE_RESULT, userId)
          return response
        });
    },
  })
}
