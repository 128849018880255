











































































































import { Component, Vue } from 'vue-property-decorator'
import {
  ACTIONS,
  BATTERY_PERCENTAGE_THRESHOLD,
  BatteryPercentThresholdOption,
  GETTERS,
  LAST_SEEN_THRESHOLD,
  LastSeenThresholdOption,
  LockSettingsFilters,
  REPORT_TYPE,
  TransactionLogFilters
} from '@/store/modules/reports/inter-connect-lock-reports'
import TransactionLogReportTable from '@/views/reports/inter-connect-locks/TransactionLogReportTable.vue'
import InterConnectLockSettingsReportTable from '@/views/reports/inter-connect-locks/InterConnectLockSettingsReportTable.vue'
import { TRANSACTION_LOG_ACTION, uuid } from '@/api/models'
import { DeviceFilters, SharedDeviceFilters } from '@/store/modules/reports'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import devices, { DeviceType, LATCH_CAPABILITY, InterConnectLockTransactionLogFilterOptions } from '@/api/devices'
import DatepickerWrapper, { DateRange } from '@/components/forms/Datepicker.vue'
import { convertToUuidArray } from '@/util'

@Component({
  components: {
    TransactionLogReportTable,
    InterConnectLockSettingsReportTable,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
  }
})
export default class InterConnectLockReports extends Vue {
  REPORT_TYPE = REPORT_TYPE

  clientIds: uuid[] = []
  proxyIds: uuid[] = []

  deviceFilterOptions: SharedDeviceFilters | null = null
  transactionLogOptions: InterConnectLockTransactionLogFilterOptions | null = null

  selectedLastSeenThreshold: LastSeenThresholdOption|null = null
  selectedBatteryPercentThreshold: BatteryPercentThresholdOption|null = null

  async mounted(): Promise<void> {
    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds

    this.deviceFilterOptions = await devices.getDeviceTypeaheadOptions(clientFilterIds, [DeviceType.LOCK])
    this.transactionLogOptions = await devices.getInterConnectLockTransactionLogOptions(clientFilterIds)

    // Array destructuring to get first or undefined filter, in case this is set from the dashboard, we need to find the value in our "generated" list
    const [lastSeenThresholdFromState] = this.$store.getters[GETTERS.LOCK_SETTINGS_FILTERS].lastSeenThresholds
    this.selectedLastSeenThreshold = this.lastSeenThresholds.find((threshold) => threshold.value === lastSeenThresholdFromState) ?? null
  }

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }

  get reportTypes(): REPORT_TYPE[] {
    return Object.values(REPORT_TYPE)
  }

  get selectedReportType(): REPORT_TYPE {
    return this.$store.getters[GETTERS.REPORT_TYPE]
  }

  async reportTypeSelected(reportType: REPORT_TYPE): Promise<void> {
    await this.$store.dispatch(ACTIONS.UPDATE_REPORT_TYPE, reportType)
  }

  /********************************************************
    DEVICE FILTERS
   *******************************************************/
  updateDeviceFilters(filters: Partial<DeviceFilters>, refreshTable=true): void {
    this.$store.dispatch(ACTIONS.UPDATE_DEVICE_REPORT_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(ACTIONS.REFRESH_TABLE)
    }
  }

  // Contacts
  contactsSelected(contacts: string[]): void {
    this.updateSharedDeviceFilters({ contacts })
  }

  get contacts(): string[] {
    return this.deviceFilterOptions?.contacts?.filter(contact => contact) ?? []
  }

  /********************************************************
   * SHARED FILTERS
   ********************************************************/

  get sharedDeviceFilters(): SharedDeviceFilters {
    return this.$store.getters[GETTERS.SHARED_DEVICE_REPORT_FILTERS]
  }

  updateSharedDeviceFilters(filters: Partial<SharedDeviceFilters>, refreshTable=true): void {
    this.$store.dispatch(ACTIONS.UPDATE_SHARED_DEVICE_REPORT_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(ACTIONS.REFRESH_TABLE)
    }
  }

  get deviceNames(): string[] {
    return this.deviceFilterOptions?.deviceNames?.filter(name => name) ?? []
  }

  deviceNamesSelected(deviceNames: string[]): void {
    this.updateSharedDeviceFilters({ deviceNames })
  }

  get deviceSerialNumbers(): string[] {
    return this.deviceFilterOptions?.deviceSerialNumbers?.filter(serialNumber => serialNumber) ?? []
  }

  deviceSerialNumbersSelected(deviceSerialNumbers: string[]): void {
    this.updateSharedDeviceFilters({ deviceSerialNumbers })
  }

  get departmentNames(): string[] {
    return this.deviceFilterOptions?.departmentNames?.filter(department => department) ?? []
  }

  departmentsSelected(departmentNames: string[]): void {
    this.updateSharedDeviceFilters({ departmentNames })
  }

  get deviceLocations(): string[] {
    return this.deviceFilterOptions?.locations?.filter(location => location) ?? []
  }

  deviceLocationsSelected(locations: string[]): void {
    this.updateSharedDeviceFilters({ locations })
  }

  /********************************************************
   * TRANSACTION LOG REPORTS BELOW
   ********************************************************/

  get appliedTransactionLogFilters(): TransactionLogFilters {
    return this.$store.getters[GETTERS.TRANSACTION_LOG_FILTERS]
  }

  updateAppliedTransactionLogFilters(filters: Partial<TransactionLogFilters>, refreshTable=true): void {
    this.$store.dispatch(ACTIONS.UPDATE_TRANSACTION_LOG_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(ACTIONS.REFRESH_TABLE)
    }
  }

  // Actions (Event Type)
  get actionOptions(): string[] {
    return Object.values(TRANSACTION_LOG_ACTION)
  }

  actionChanged(actions: TRANSACTION_LOG_ACTION[]): void {
    this.updateAppliedTransactionLogFilters({ actions })
  }

  // User Names
  get userNameOptions(): string[] {
    return this.transactionLogOptions?.names?.filter(name => name) ?? []
  }

  userNameChanged(userNames: string[]): void {
    this.updateAppliedTransactionLogFilters({ userNames })
  }

  // Event Date
  get eventDateUpdatedRange(): DateRange | null {
    const lastUpdatedStart = this.appliedTransactionLogFilters.eventDateStart ?? ''
    const lastUpdatedEnd = this.appliedTransactionLogFilters.eventDateEnd ?? ''
    if (lastUpdatedStart && lastUpdatedEnd) {
      return { start: lastUpdatedStart, end: lastUpdatedEnd }
    }
    return null
  }

  eventDateRangeChanged(range: { start: Date, end: Date } | null): void {
    this.updateAppliedTransactionLogFilters({ eventDateStart: range?.start, eventDateEnd: range?.end })
  }

  /********************************************************
   * LOCK SETTINGS REPORTS BELOW
   * ******************************************************/
  get appliedLockSettingsFilters(): LockSettingsFilters {
    return this.$store.getters[GETTERS.LOCK_SETTINGS_FILTERS]
  }

  updateAppliedLockSettingsFilters(filters: Partial<LockSettingsFilters>, refreshTable=true): void {
    this.$store.dispatch(ACTIONS.UPDATE_LOCK_SETTINGS_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(ACTIONS.REFRESH_TABLE)
    }
  }

  // Latch Capabilities
  get latchCapabilities(): LATCH_CAPABILITY[] {
    return Object.values(LATCH_CAPABILITY)
  }

  latchCapabilityChanged(capabilities: string[]): void {
    this.updateAppliedLockSettingsFilters({ capabilities })
  }

  // Battery Percentage Thresholds
  get batteryPercentageThresholds(): BatteryPercentThresholdOption[] {
    return [
      {
        title: '< 10%',
        value: BATTERY_PERCENTAGE_THRESHOLD.LESS_THAN_10
      },
      {
        title: '< 5%',
        value: BATTERY_PERCENTAGE_THRESHOLD.LESS_THAN_5
      }
    ]
  }

  batteryPercentageThresholdChanged(batteryPercentageThreshold: BatteryPercentThresholdOption): void {
    this.selectedBatteryPercentThreshold = batteryPercentageThreshold
    this.updateAppliedLockSettingsFilters({ batteryPercentageThresholds: batteryPercentageThreshold ? [batteryPercentageThreshold.value] : [] })
  }

  // Last Seen Thresholds
  get lastSeenThresholds(): LastSeenThresholdOption[] {
    return [
      {
        title: '24 Hours',
        value: LAST_SEEN_THRESHOLD.TWENTY_FOUR_HOURS,
      },
    ]
  }

  lastSeenThresholdChanged(threshold: LastSeenThresholdOption): void {
    this.selectedLastSeenThreshold = threshold
    this.updateAppliedLockSettingsFilters({ lastSeenThresholds: threshold ? [threshold.value] : [] })
  }

  // Event Date
  get settingsEventDateRange(): DateRange | null {
    const lastUpdatedStart = this.appliedLockSettingsFilters.settingsDateStart ?? ''
    const lastUpdatedEnd = this.appliedLockSettingsFilters.settingsDateEnd ?? ''
    if (lastUpdatedStart && lastUpdatedEnd) {
      return { start: lastUpdatedStart, end: lastUpdatedEnd }
    }
    return null
  }

  settingsEventDateRangeChanged(range: { start: Date, end: Date } | null): void {
    this.updateAppliedLockSettingsFilters({ settingsDateStart: range?.start, settingsDateEnd: range?.end })
  }
}
