import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { module as websiteUserManagement, namespace as websiteUserNamespace } from './website-user-management'
import { module as lockUserManagement, namespace as lockUserNamespace } from './lock-user-management'


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserManagementState {
  // Put any module specific things in here
}

export const namespace = 'userManagement'

export const module: Module<UserManagementState, RootState> = {
  namespaced: false,
  state: {} as UserManagementState,
  getters: {} as GetterTree<UserManagementState, RootState>,
  mutations: {} as MutationTree<UserManagementState>,
  actions: {} as ActionTree<UserManagementState, RootState>,
  modules: {
    [websiteUserNamespace]: websiteUserManagement,
    [lockUserNamespace]: lockUserManagement,
  } as ModuleTree<RootState>
}
