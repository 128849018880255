














































import { Component, Vue } from 'vue-property-decorator'
import { USER_ACTIONS } from '@/store/modules/user'
import { ACTIONS as CLIENT_ACTIONS } from '@/store/modules/client-management'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import SubscriptionsIcon from '@/assets/img/subscriptions-icon.svg'
import AllDevicesIcon from '@/assets/img/all-devices-icon.svg'
import SwapIcon from '@/assets/img/swap-horizontal-icon.svg'
import { PROXY_ID, PAGE_NAMES } from '@/api/misc'

@Component({
  components: {
    SubscriptionsIcon,
    AllDevicesIcon,
    SwapIcon,
    PageBannerHeader
  }
})
export default class ClientData extends Vue {
  PAGE_NAMES = PAGE_NAMES

  clientId = ''
  clientName = ''

  async mounted(): Promise<void> {
    this.clientId = this.$route.query.clientId as string

    await this.$store.dispatch(CLIENT_ACTIONS.GET, this.clientId)
      .then((client) => { this.clientName = client.name })
  }

  becomeClient(): void {
    this.$store.dispatch(USER_ACTIONS.BECOME_CLIENT)
    this.$router.push({ path: `/dashboard?${[PROXY_ID]}=${this.clientId}` })
  }
}
