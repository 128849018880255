
































import { Component, Vue } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import { PAGE_NAMES } from '@/api/misc'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import InterConnectLockIcon from '@/assets/img/inter-connect-lock-icon.svg'
import { Auth } from '@aws-amplify/auth'

@Component({
  components: {
    PageBannerHeader,
    DeviceCabinetIcon,
    InterConnectLockIcon
  }
})
export default class Reports extends Vue {
  PAGE_NAMES = PAGE_NAMES
  apiServerUrl = process.env.VUE_APP_API_SERVER_URL
  show_new_scope_reports: boolean = process.env.VUE_APP_SHOW_NEW_SCOPE_REPORTS === "true" ? true : false;
  token = ""
  clientIds: string[] = []
  proxyIds: string[] = []

  async mounted(): Promise<void> {
    const session = await Auth.currentSession();
    this.token = await session.getIdToken().getJwtToken();
  }

  get proxyId(): string{
    return this.$router.currentRoute.query?.proxyId as string;
  }

  get userType(): string {
    return this.$store.getters.userType as string;
  }

  // This assumes that the given user will only ever have one
  // client. In the case of a user having multiple clients, this
  // will select the first client in the store client array arbitrarily
  get clientId(): string {
    let clientId = "";
    try {
      clientId = this.$store.getters.user.clients[0].id as string;
    } catch (error) {
      return clientId;
    }
    return clientId;
  }
}
