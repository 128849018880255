






import { Component, Vue } from 'vue-property-decorator'
import OrderHistoryTable from '@/views/order-parts/OrderHistoryTable.vue'

@Component({
  components: {
    OrderHistoryTable,
  }
})
export default class OrderHistory extends Vue {}
