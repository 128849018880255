import { Optional } from "@/util";
import instance, {
  API,
  PaginatedResponse
} from "@/api/index";

export interface Part {
  id: number
  name: string
  description: Optional<string>
  categories: string[]
  price: string
  sku: string
}

export class PartsController {
  constructor(private api: API, private path: string = 'parts') {}

  async list(offset: number, limit: number): Promise<PaginatedResponse<Part>> {
    const response = await this.api.authenticated.get<PaginatedResponse<Part>>(this.path, {
      params: { limit, offset }
    })

    return response.data
  }
}

export default new PartsController(instance)
