


























import { Component } from 'vue-property-decorator';
import { TableColumn } from "@/components/table-column";
import { ACTIONS, GETTERS } from '@/store/modules/parts/parts-list';
import VuexBackedTableBasedView from "@/views/tables/VuexBackedTableBasedView";
import DataTable from "@/components/DataTable.vue";
import GroupIndicator from "@/components/GroupIndicator.vue";
import EmailLink from "@/components/EmailLink.vue";
import EditButton from "@/components/EditButton.vue";
import TableFooter from "@/components/TableFooter.vue";
import { Part } from "@/api/parts";
import Counter from "@/components/Counter.vue";
import PartsListIcon from '@/assets/img/list-icon.svg'
import { ACTIONS as CART_ACTIONS, GETTERS as CART_GETTERS, OrderedPart } from '@/store/modules/parts/cart'
import { getPriceFromString } from '@/util'

@Component({
  components: {
    DataTable,
    GroupIndicator,
    EmailLink,
    EditButton,
    TableFooter,
    Counter,
    PartsListIcon
  }
})
export default class PartsListTable extends VuexBackedTableBasedView<Part> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  mounted(): void {
    this.loadData()
  }

  initialCartValue(part: Part): string {
    const selectedParts: OrderedPart[] = this.$store.getters[CART_GETTERS.GET_SELECTED_PARTS]
    return selectedParts.find(selectedPart => selectedPart.id === part.id)?.count.toString() ?? '0'
  }

  get partListColumns(): TableColumn<Part>[] {
    return [
      {
        title: 'Part',
        headerColumn: true,
        headerClass: 'columnMedium',
        sticky: true,
        value: (part) => part.name,
        cellClass: 'selectable'
      },
      {
        title: 'SKU',
        value: (part) => part.sku,
        cellClass: 'selectable'
      },
      {
        title: 'Category',
        value: (part) => part.categories.join(", "),
        cellClass: 'selectable'
      },
      {
        title: 'Description',
        value: (part) => part.description ?? '',
        cellClass: 'selectable'
      },
      {
        title: 'Price',
        value: (part) => {
          if (part.price === '0') {
            return 'N/A'
          }
          return getPriceFromString(part.price)
        },
        headerClass: 'columnSmall',
        cellClass: 'selectable'
      },
      {
        title: 'Cart',
        headerClass: 'columnSmall',
        cellClass: 'noPadding'
      },
    ]
  }

  partCountChanged(part: Part, count: number): void {
    this.$store.dispatch(CART_ACTIONS.UPDATE_SELECTED_PARTS, { ...part, count } as OrderedPart)
  }
}
