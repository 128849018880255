
















import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import { ClientGroup } from '@/api/client-groups'
import { ACTIONS } from '@/store/modules/client-group-management'

@Component({
  components: {
    SingleselectWrapper,
  }
})
export default class SingleClientGroupSelect extends Vue {
  @Prop({default: false}) disabled!: boolean

  loading = false
  availableClientGroups: ClientGroup[] = []

  async addClientGroup(clientGroupName: string): Promise<void> {
    this.loading = true
    try {
      const newClientGroup = await this.$store.dispatch(ACTIONS.CREATE_CLIENT_GROUP, clientGroupName)
      this.availableClientGroups.push(newClientGroup)
      this.availableClientGroups.sort((a, b) => a.name.localeCompare(b.name))
      this.$emit('input', newClientGroup)
    } catch(error) {
      if (axios.isAxiosError(error)) {
        this.$emit('error', error.response?.data?.description ?? 'Failed to create hospital system.')
      }
    }
    this.loading = false
  }

  async mounted (): Promise<void> {
    this.loading = true
    try {
      this.availableClientGroups = (await this.$store.dispatch(ACTIONS.FETCH_LIST)).items
      this.availableClientGroups.sort((a, b) => a.name.localeCompare(b.name))
      this.$emit('clientGroupsLoaded', this.availableClientGroups)

      if (this.availableClientGroups.length === 1) {
        this.$emit('input', this.availableClientGroups[0])
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.$emit('error', error.response?.data?.description ?? 'Failed to fetch hospital system list.')
      }
    }
    this.loading = false
  }
}
