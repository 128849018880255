









































import { Component, Vue, Prop } from 'vue-property-decorator'
import { USER_ACTIONS } from '@/store/modules/user'
import { Client } from '@/api/clients'
import SwapIcon from '@/assets/img/people_alt_black_24dp.svg' 
import OrderPartsIcon from '@/assets/img/order-parts-icon-secondary.svg'
import ClientManagementIcon from '@/assets/img/client-management-icon.svg'
import SubscriptionsIcon from '@/assets/img/subscriptions-icon-secondary.svg'
import { PROXY_ID, PAGE_NAMES } from '@/api/misc'

@Component({
  components: {
    SwapIcon,
    OrderPartsIcon,
    ClientManagementIcon,
    SubscriptionsIcon,
  }
})
export default class ClientSummaryCard extends Vue {
  PAGE_NAMES = PAGE_NAMES

  @Prop({ required: true }) client!: Client

  "hover": boolean

  becomeClient(destination: string): void {
    this.$store.dispatch(USER_ACTIONS.BECOME_CLIENT)
    this.$router.push({ name: destination, query: { [PROXY_ID]: this.client.id}} )
  }

  enterClient(destination: string): void {
    this.$router.push({ name: destination, query: { clientId: this.client.id } })
  }
}

