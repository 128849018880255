import { Optional } from '@/util'
import { uuid } from '@/api/models'
import { Client } from '@/api/clients'
import qs from 'qs'
import instance, {
  API,
  formatOrderDescription,
  OrderDescription,
  PaginatedResponse,
  OrderDirection
} from '@/api/index'
import { OrderedPart } from '@/store/modules/parts/cart'

export interface Order {
  woocommerceOrderId: Optional<number>, // Optional because it only comes back after creation of order
  firstName: string
  lastName: string
  email: string
  phone: string
  address1: string
  address2: Optional<string>
  city: string
  state: string
  zipcode: string
  message: string
  clientId: uuid
  client: Optional<Client>
  parts: OrderedPart[]
  paymentMethod: string
  createdAt: Optional<string>
}

export class OrderController {
  constructor(private api: API, private path: string = 'orders') {}

  async create(newOrder: Order): Promise<Order> {
    const response = await this.api.authenticated.post<Order>(this.path, newOrder)
    return response.data
  }

  async list(offset: number, limit: number, _order: OrderDescription[], clientIds: string[] = []): Promise<PaginatedResponse<Order>> {
    const fallback = { field: 'order.created_at', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)

    const response = await this.api.authenticated.get<PaginatedResponse<Order>>(this.path, {
      params: { limit, offset, order_by, clientIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }
}

export default new OrderController(instance)
