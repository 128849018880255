import { Module } from 'vuex'
import { RootState } from '@/store'
import router from '@/router'
import { MODAL_ACTIONS } from '@/store/types'

interface ModalState {
  closeNormal: boolean
}

export const module: Module<ModalState, RootState> = {
  state: {
    closeNormal: false
  },
  getters: {
    closeNormal: ( state ) => state.closeNormal
  },
  actions: {
    [MODAL_ACTIONS.OPEN]: async ({ state }, query) => {
      state.closeNormal = true
      await router.push({ path: router.currentRoute.path, query: query }).catch((error) => {
        // This is... to stop the cursed router bug
        if (error.message.startsWith("Redirected when going from")) {
          return
        } else {
          throw error
        }
      })
    },
  },
}
