















































import { Component, Vue } from 'vue-property-decorator'
import {
  ArcElement,
  Chart,
  ChartConfiguration,
  ChartOptions,
  DoughnutController,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReadyIcon from '@img/ready.svg'
import ExpiredIcon from '@img/expired.svg'
import DryingIcon from '@img/drying.svg'
import QueuedIcon from '@img/queued.svg'
import CheckedOutIcon from '@img/checked-out.svg'
import { LoadingStatus } from '@/store/types'
import Loading from '@/components/Loading.vue'
import devices, { ScopeSummaryTotals } from '@/api/devices'
import { SCOPE_STATUS } from '@/api/scope-actions'
import { convertToUuidArray } from '@/util'
import Card from '@/components/Card.vue'

const READY_COLOR = '#24864b'
const DRYING_COLOR = '#6442bc'
const CHECKED_OUT_COLOR = '#4278bc'
const EXPIRED = '#a02e2e'
const QUEUED = '#eac300'
const NO_DATA = '#576475'

@Component({
  components: {
    ReadyIcon,
    ExpiredIcon,
    DryingIcon,
    QueuedIcon,
    CheckedOutIcon,
    Loading,
    Card,
  }
})
export default class ScopeStatus extends Vue {
  SCOPE_STATUS = SCOPE_STATUS

  status: LoadingStatus = 'loading'
  summaryValues = [0, 0, 0, 0, 0]

  clientIds: string[] = []
  proxyIds: string[] = []
  selectedActions: SCOPE_STATUS[] = []

  async mounted(): Promise<void> {
    try {
      // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
      this.clientIds = convertToUuidArray(this.$route.query.clientId)
      this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

      const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
      const totals: ScopeSummaryTotals = await devices.getLatestScopeTotals(clientFilterIds)
      this.status = 'loaded'
      const keys = Object.keys(totals).sort(this.compareSummaryDataItem)
      this.summaryValues = keys.map(key => totals[key]).splice(-5)
      this.createChart(
        SCOPE_STATUS.ALL_STATUSES,
        this.summaryValues
      )
    } catch (error) {
      this.status = 'error'
    }
  }

  toggleStatusFilter(status: SCOPE_STATUS): void {
    const idx = this.selectedActions.indexOf(status)
    if (idx === -1) {
      this.selectedActions.push(status);
    } else {
      this.selectedActions.splice(idx, 1);
    }
    this.$emit('filter', this.selectedActions)
  }

  compareSummaryDataItem(a: string, b: string): number {
    // anything that's unknown in this list will be shuffled to the front of the list.
    //   ['unknown1', 'unknown2', 'Dry Complete', 'Dry Start', 'Check In', 'Check Out', 'Expired']
    // Once the list is sorted, we can just grab the last 5 items from the list in known order
    const keyOrder = SCOPE_STATUS.ALL_ACTIONS
    return keyOrder.indexOf(a) - keyOrder.indexOf(b)
  }
  createChart(labels: string[], values: number[]): void {
    const hasData = values.some(value => value > 0)
    const canvas = document.getElementById('doughnut') as HTMLCanvasElement
    Chart.register(DoughnutController, ArcElement, ChartDataLabels);
    new Chart(canvas, {
      type: 'doughnut',
      plugins: [ChartDataLabels],
      data: {
        labels: hasData ? labels : ['No Data'],
        datasets: [{
          data: hasData ? values : [100],
          backgroundColor: hasData ? [
            READY_COLOR,
            DRYING_COLOR,
            CHECKED_OUT_COLOR,
            EXPIRED,
            QUEUED
          ] : [NO_DATA],
        }]
      },
      options: {
        cutout: '65%',
        responsive: true,
        layout: {
          padding: 32
        },
        plugins: {
          datalabels: {
            anchor: 'center',
            align: 'end',
            offset: 8,
            font: {
              family: "'Roboto', sans-serif",
              size: 16
            },
            display: 'auto',
            formatter: (value: number, context) => {
              if (hasData) {
                const data = context.dataset.data as number[]
                const total = data.reduce((a, b) => a + b, 0)
                const roundResult = (data[context.dataIndex] / total * 100)
                if (roundResult >= 1) {
                  return Math.round(roundResult) + '%'
                } else if (roundResult > 0.1 && roundResult < 1) {
                  return roundResult.toFixed(1) + '%'
                } else if (roundResult < 0.1) {
                  return ''
                }
              } else {
                return 'No Data'
              }
            }
          }
        }
      } as ChartOptions,
    } as ChartConfiguration);
  }
}
