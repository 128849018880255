var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"standardPageLayout clientManagement"},[_c('PageBannerHeader',{attrs:{"title":"Manage Clients"},scopedSlots:_vm._u([{key:"primaryActions",fn:function(){return [_c('button',{staticClass:"buttonStylePrimary",on:{"click":_vm.newClient}},[_vm._v("New Client")])]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('p',[_vm._v("Client Name:")]),_c('MultiselectWrapper',{attrs:{"propertyName":"Client Names Selected","options":_vm.results.map(function (client) { return client.name; })},model:{value:(_vm.clientNameDropDown),callback:function ($$v) {_vm.clientNameDropDown=$$v},expression:"clientNameDropDown"}}),_c('p',[_vm._v("Account Number:")]),_c('MultiselectWrapper',{attrs:{"propertyName":"Account Numbers Selected","options":_vm.results.map(function (client) { return client.account_number; })},model:{value:(_vm.accountNumberDropdownValueResults),callback:function ($$v) {_vm.accountNumberDropdownValueResults=$$v},expression:"accountNumberDropdownValueResults"}}),_c('br'),_c('DataTable',{attrs:{"data":_vm.currentResults,"columns":_vm.columns,"order":_vm.order},on:{"orderChanged":_vm.orderChanged,"headerCellClicked":function($event){return _vm.enterClient($event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('header',[_c('div',{staticClass:"iconHolder"},[_c('ClientManagementIcon',{staticClass:"clientManagementIcon"})],1),_c('h3',[_vm._v("Client Management")])])]},proxy:true},{key:"clientName",fn:function(ref){
var value = ref.value;
return [_c('div',[_c('span',[_vm._v(_vm._s(value))])])]}},{key:"units",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"capsule"},[_vm._v(_vm._s(value))])]}},{key:"future",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"capsule"},[_vm._v(_vm._s(value))])]}},{key:"Edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"inlineContainer"},[_c('EditButton',{on:{"click":function($event){return _vm.editClient(row)}}}),_c('button',{staticClass:"inlineContainer caption buttonStyleBorderless",on:{"click":function($event){return _vm.newUser(row)}}},[_c('span',[_vm._v("New User")]),_c('PlusIcon')],1)],1)]}},{key:"impersonate",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"inlineContainer"},[_c('button',{on:{"click":function($event){return _vm.becomeClient(_vm.PAGE_NAMES.DASHBOARD, row)}}},[_c('SwapIcon')],1)])]}},{key:"footer",fn:function(){return [_c('TableFooter',{attrs:{"description":_vm.contentDescription,"errorMessage":_vm.errorMessage,"moreAvailable":_vm.moreAvailable,"status":_vm.status},on:{"retry":_vm.loadRetry,"more":_vm.loadMore}})]},proxy:true}])}),_c('Modal',{attrs:{"name":_vm.MODAL_TYPE.NEW_CLIENT}},[_c('ClientWizard')],1),_c('Modal',{attrs:{"name":_vm.MODAL_TYPE.EDIT_CLIENT}},[_c('ClientWizard',{attrs:{"editMode":true}})],1),_c('Modal',{attrs:{"name":_vm.MODAL_TYPE.NEW_USER}},[_c('WebsiteUserWizard')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }