
















import { PROXY_ID } from '@/api/misc'
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Dictionary } from "vue-router/types/router"

@Component
export default class Modal extends Vue {
  @Prop() name!: string
  @Prop({ default: true }) shouldCloseOnBackgroundClick!: boolean

  // Determine how to manipulate the router in the event this modal self-dismisses
  // - Only has an effect when shouldCloseOnBackgroundClick is true
  // if 'push' then the modal will reopen if the user hits the brower back button - useful for informational modals
  // if 'pop' then the modal removes itself from the back stack - useful for creation modals where 'back' could be awkward.
  @Prop({ default: 'pop' }) dismissNavigationDirection!: 'push' | 'pop'

  show = false

  @Watch('$route.query', { deep: true, immediate: true })
  routeChanged(newQuery: Dictionary<string | (string | null)[]>): void {
    this.show = (newQuery.modal !== undefined && newQuery.modal === this.name)
  }

  @Watch('show', { immediate: true })
  showChanged(newValue: boolean): void {
    if (newValue) {
      // Lock body scrolling when there is a modal active so that the content
      // behind the overlay is locked in place
      document.getElementsByTagName('body')[0].classList.add('scrollLock')
    } else {
      // Unlock body scrolling when the modal is dismissed so things can resume normal
      document.getElementsByTagName('body')[0].classList.remove('scrollLock')
    }
  }

  destroyed(): void {
    // Fallback unlock in case the component is destroyed without setting show to false
    document.getElementsByTagName('body')[0].classList.remove('scrollLock')
  }

  closeIfConfigured(): void {
    if (this.shouldCloseOnBackgroundClick) {
      if (this.dismissNavigationDirection == 'push') {
        this.$router.push({ path: this.$route.path })
      } else {
        if (this.$store.getters.closeNormal) {
          this.$router.back()
        } else {
          let query = {}
          if (this.$route.query.proxyId) {
            Object.assign(query, { [PROXY_ID]: this.$route.query.proxyId });
          }

          this.$router.replace({ path: this.$route.path, query: query }).catch(() => { return })
        }
      }
    }
  }

  mounted(): void {
    this.$on("close", this.closeIfConfigured);
  }
}
