
















import { Component, Prop, Vue } from "vue-property-decorator"
import LockIcon from '@/assets/img/inter-connect-lock-icon.svg'
import { Notification } from "@/api/notifications"
import NotificationMenuItem from '@/components/nav-bar/notifications/NotificationMenuItem.vue'
import { TRANSACTION_LOG_ACTION } from '@/api/models'
import { LowBatteryFields } from '@/api/devices'


@Component({
  components: {
    LockIcon,
    NotificationMenuItem,
  },
})
export default class CabinetTransactionNotification extends Vue {
  TRANSACTION_LOG_ACTION = TRANSACTION_LOG_ACTION

  @Prop({ required: true}) lockTransaction!: Notification

  get titleText(): string {
    let result = this.lockTransaction.alertType

    if ((this.lockTransaction.lockTransaction.eventData as LowBatteryFields).battery_percentage) {
      result += ' - ' + (this.lockTransaction.lockTransaction.eventData as LowBatteryFields).battery_percentage + '%'
    }

    return result
  }
}
