




























































































import { OrderDescription, OrderDirection } from '@/api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableColumn } from '@/components/table-column'
import SortDescendingIcon from '@/assets/img/sort-descending.svg'
import SortAscendingIcon from '@/assets/img/sort-ascending.svg'
import SortInactiveIcon from '@/assets/img/sort-inactive.svg'

@Component({
  components: {
    SortDescendingIcon,
    SortAscendingIcon,
    SortInactiveIcon
  }
})
export default class DataTable<Data extends { id: string }> extends Vue {
  @Prop({ required: true }) data!: Data[]
  @Prop({ required: true }) columns!: TableColumn<Data>[]
  @Prop({ required: true }) order!: OrderDescription[]
  @Prop({ default: true }) enableHorizontalScrolling!: boolean
  /** Takes a property key to lookup a boolean value in the result set for altering row styles */
  @Prop({ default: '' }) customRowProp!: string
  @Prop({ default: false }) clickableRows!: boolean

  selectedRowId: string | null = null

  mounted(): void {
    if (this.enableHorizontalScrolling && this.$el.parentElement) {
      this.$el.parentElement.style.minWidth = '0'
    }
  }

  columnHeaderId(column: TableColumn<Data>): string {
    return this.columnId(column) + '-header'
  }

  columnId(column: TableColumn<Data>): string {
    return column.id || column.field || column.title
  }

  cellValue(column: TableColumn<Data>, row: Data): string {
    return column.value ? column.value(row) : ''
  }

  orderDirection(column: TableColumn<Data>): OrderDirection|null {
    if (!column.field) {
      return null
    }

    const match = this.order.find(element => element.field === column.field)
    if (match) {
      return match.direction
    } else {
      return null
    }
  }

  orderIndex(column: TableColumn<Data>): string|null {
    if (!column.field) {
      return null
    }

    const index = this.order.findIndex(element => element.field === column.field)
    if (index >= 0) {
      const ordinal = index + 1

      if (ordinal == 1) {
        return `${ordinal}st`
      } else if (ordinal == 2) {
        return `${ordinal}nd`
      } else if (ordinal == 3) {
        return `${ordinal}rd`
      } else {
        return `${ordinal}th`
      }
    } else {
      return null
    }
  }

  sort(column: TableColumn<Data>): void {
    if (!column.field) { return }
    this.$emit('orderChanged', column.field)
  }

  rowClicked(row: Data): void {
    // If selecting an already selected row, unselect it
    if (row.id === this.selectedRowId) {
      this.selectedRowId = null
      this.$emit('rowClicked', null)
    } else {
      this.selectedRowId = row.id
      this.$emit('rowClicked', row);
    }
  }
}
