





























import { Component, Watch } from 'vue-property-decorator'
import PageBannerHeader from "@/components/PageBannerHeader.vue"
import { Dictionary } from "vue-router/types/router"
import PartsListTable from "@/views/order-parts/PartsListTable.vue"
import { ACTIONS as CART_ACTIONS } from '@/store/modules/parts/cart'
import CompleteOrder from '@/views/order-parts/complete-order/CompleteOrder.vue'
import OrderHistory from '@/views/order-parts/OrderHistory.vue'
import OrderHistoryAdmin from '@/views/order-parts/OrderHistoryAdmin.vue'
import RoleBasedView from '@/views/RoleBasedView'

export enum PAGE_VIEW {
  Default = '',
  NewOrder = 'new-order',
  CompleteOrder = 'complete-order'
}

@Component({
  components: {
    PageBannerHeader,
    PartsListTable,
    CompleteOrder,
    OrderHistory,
    OrderHistoryAdmin
  }
})
export default class OrderParts extends RoleBasedView {
  PAGE_VIEW = PAGE_VIEW

  view = PAGE_VIEW.Default

  destroyed(): void {
    this.$store.dispatch(CART_ACTIONS.TOGGLE_CART_OPEN, false)
  }

  get pageTitle(): string {
    switch (this.view) {
      case PAGE_VIEW.Default:
        return "Order Summary"
      case PAGE_VIEW.NewOrder:
        return "New Order"
      case PAGE_VIEW.CompleteOrder:
        return "Complete order"
      default:
        return ""
    }
  }

  get pageMessage(): string {
    switch (this.view) {
      case PAGE_VIEW.NewOrder:
        return "Select the parts you want to order. Price does not include shipping or tax."
      case PAGE_VIEW.CompleteOrder:
        return "Select the parts you want to order. Then you can opt in for recurring orders."
      case PAGE_VIEW.Default:
      default:
        return ""
    }
  }

  @Watch('$route.query', { deep: true, immediate: true })
  routeChanged(newQuery: Dictionary<string>): void {
    this.view = newQuery.view === PAGE_VIEW.Default || newQuery.view === PAGE_VIEW.NewOrder || newQuery.view === PAGE_VIEW.CompleteOrder ?
      newQuery.view :
      PAGE_VIEW.Default
  }

  beginNewOrder(): void {
    this.$router.push({ path: this.$route.path, query: { ...this.$router.currentRoute.query, view: PAGE_VIEW.NewOrder }})
  }
}
