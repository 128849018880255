import instance, { API, formatOrderDescription, OrderDescription, OrderDirection, PaginatedResponse } from '.'
import { Client, ClientPermission } from './clients'
import { Department, DepartmentForm } from './departments'
import { CRUD, uuid } from './models'
import qs from 'qs'
import { Device } from '@/api/devices'

export enum USER_TYPE {
  NOT_SELECTED = '',
  HOSPITAL_ADMIN = 'Hospital Admin',
  DEPARTMENT_USER = 'Department User',
  SOLAIRE_ADMIN = 'Solaire Admin',
}

export interface UserLite {
  id: uuid | null
  first_name: string | null
  last_name: string | null
  is_solaire_admin: boolean
  user_type: USER_TYPE
  username: string
  client_permissions: ClientPermission[]
}

export interface NewUserForm extends UserLite {
  departments: DepartmentForm[]
  devices: Device[]
}

export interface User extends UserLite, CRUD {
  clients: Client[]
  departments: Department[]
  devices: Device[]
}

export class UserController {
  constructor(private api: API, private path: string = 'users') {}

  async list(offset: number, limit: number, _order: OrderDescription[], proxyIds: string[] = []): Promise<PaginatedResponse<User>> {
    const fallback = { field: 'user.created_at', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)
    const client_id = proxyIds
    const response = await this.api.authenticated.get<PaginatedResponse<User>>(this.path, {
      params: { limit, offset, order_by, client_id },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async create(newUserForm: NewUserForm): Promise<User> {
    const response = await this.api.authenticated.post<User>(this.path, newUserForm)
    return response.data
  }

  async get(userId: uuid): Promise<User> {
    const response = await this.api.authenticated.get<User>(`${this.path}/${userId}`)
    return response.data
  }

  async update(user: User): Promise<User> {
    const response = await this.api.authenticated.put<User>(`${this.path}/${user.id}`, user)
    return response.data
  }

  async delete(userId: uuid): Promise<unknown> {
    return this.api.authenticated.delete<unknown>(`${this.path}/${userId}`)
  }

  async me(): Promise<User> {
    const response = await this.api.authenticated.get<User>(`${this.path}/me`)
    return response.data
  }

  async updateName(firstName: string, lastName: string): Promise<void> {
    await this.api.authenticated.put<User>(`${this.path}/me`, { first_name: firstName, last_name: lastName })
  }
}

export default new UserController(instance)