import { Component } from 'vue-property-decorator'

import { OrderDescription } from "@/api"
import { TABLE_ACTIONS, TABLE_GETTERS } from "@/store/mixins/table"
import { TableColumn } from '@/components/table-column'

import TableBasedView from '@/views/tables/TableBasedView'

// A TableBasedView that is tightly coupled to a Vuex store.
//
// In order for subclasses of this component to work, they must declare their own
// types for ACTIONS and GETTERS so that the functions in this class call the
// correctly namespaced actions and surface the right data.
@Component
export default class VuexBackedTableBasedView<Item> extends TableBasedView {
  GETTERS!: typeof TABLE_GETTERS
  ACTIONS!: typeof TABLE_ACTIONS

  async loadData(proxyIds: string[] = []): Promise<void> {
    await this.$store.dispatch(this.ACTIONS.LOAD_PAGE, { mode: 'replace', ids: proxyIds })
  }

  async loadRetry(): Promise<void> {
    await this.$store.dispatch(this.ACTIONS.LOAD_PAGE)
  }

  get status(): boolean {
    return this.$store.getters[this.GETTERS.STATUS]
  }

  get results(): Item[] {
    return this.$store.getters[this.GETTERS.RESULTS]
  }

  get total(): number {
    return this.$store.getters[this.GETTERS.TOTAL]
  }

  get moreAvailable(): boolean {
    return this.$store.getters[this.GETTERS.HAS_ADDITIONAL_PAGES]
  }

  async loadMore(): Promise<void> {
    await this.$store.dispatch(this.ACTIONS.LOAD_NEXT_PAGE)
  }

  get order(): OrderDescription[] {
    return this.$store.getters[this.GETTERS.ORDER]
  }

  async orderChanged(field: string): Promise<void> {
    await this.$store.dispatch(this.ACTIONS.ORDER_BY, { field })
  }

  get columns(): TableColumn<Item>[] {
    console.error('ERROR')
    return []
  }

  get errorMessage(): string {
    return this.$store.getters[this.GETTERS.ERROR_MESSAGE]
  }

  get contentDescription(): string {
    return this.$store.getters[this.GETTERS.CONTENT_DESCRIPTION]
  }
}
