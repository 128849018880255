import instance, { API, formatOrderDescription, OrderDescription, OrderDirection, PaginatedResponse } from '@/api/index'
import { TimestampMixin, uuid } from '@/api/models'
import qs from 'qs'
import { CabinetMaintenanceLogEvent, CabinetTransaction, InterConnectLockTransactionLogEvent } from '@/api/devices'
import { timestamp } from '@/api/models'

export enum NOTIFICATION_ALERT_TYPE {
  EXPIRED_SCOPE = 'Expired Scope',
  EXPIRING_SCOPE = 'Expiring Scope',
  HEPA_FILTER = 'HEPA Filter Replacement',
  TUBING = 'Tube Replacement',
  TEMP_AND_HUMIDITY = 'Temperature and Humidity',
  LOW_BATTERY = 'Low Battery',
  SERVICE_NOW_LOW_BATTERY = 'Low Battery - Service Now',
  LOCK_OFFLINE = 'Lock Offline',
  LOCK_LOW_STOCK_REQUEST = 'Low Stock Request',
  SCOPE_ACTIVITY_SUMMARY = 'Scope Activity Summary',
  LOCK_ACTIVITY_SUMMARY = 'Lock Activity Summary',
}

export const NotificationAlertTypeDescriptionMap: { [key: string]: string } = {
  [NOTIFICATION_ALERT_TYPE.EXPIRED_SCOPE]: 'Receive an email alert when a scope expires while stored in a cabinet',
  [NOTIFICATION_ALERT_TYPE.EXPIRING_SCOPE]: 'Receive an email alert for an interval of hours before a scope is set to expire while stored in a cabinet',
  [NOTIFICATION_ALERT_TYPE.HEPA_FILTER]: 'Ventaire filter needs to be replaced every 6 months',
  [NOTIFICATION_ALERT_TYPE.TUBING]: 'Ventaire tubes need to be replaced every 6 months',
  [NOTIFICATION_ALERT_TYPE.LOW_BATTERY]: 'Receive an email alert when a lock needs to have batteries changed out',
  [NOTIFICATION_ALERT_TYPE.SERVICE_NOW_LOW_BATTERY]: 'Receive an email alert in the Service Now format when a lock needs to have batteries changed out',
  [NOTIFICATION_ALERT_TYPE.LOCK_OFFLINE]: 'Receive an email alert when a device remains offline for more than 24 hours',
  [NOTIFICATION_ALERT_TYPE.LOCK_LOW_STOCK_REQUEST]: 'Receive an email alert when a staff member requests re-stocking of the cart or cabinet',
  [NOTIFICATION_ALERT_TYPE.TEMP_AND_HUMIDITY]: "Receive an email alert when a cabinet's temperature or humidity is out of an acceptable range",
  [NOTIFICATION_ALERT_TYPE.SCOPE_ACTIVITY_SUMMARY]: 'Receive an email summary about device activity and alerts on Monday mornings',
  [NOTIFICATION_ALERT_TYPE.LOCK_ACTIVITY_SUMMARY]: 'Receive an email summary about device activity and alerts on Monday mornings',
}

export function getCabinetNotificationTypes(): NOTIFICATION_ALERT_TYPE[] {
  return [
    NOTIFICATION_ALERT_TYPE.EXPIRED_SCOPE,
    NOTIFICATION_ALERT_TYPE.EXPIRING_SCOPE,
    NOTIFICATION_ALERT_TYPE.HEPA_FILTER,
    NOTIFICATION_ALERT_TYPE.TUBING,
    NOTIFICATION_ALERT_TYPE.TEMP_AND_HUMIDITY,
    NOTIFICATION_ALERT_TYPE.SCOPE_ACTIVITY_SUMMARY,
  ]
}

export function getLockNotificationTypes(): NOTIFICATION_ALERT_TYPE[] {
  return [
    NOTIFICATION_ALERT_TYPE.LOW_BATTERY,
    NOTIFICATION_ALERT_TYPE.SERVICE_NOW_LOW_BATTERY,
    NOTIFICATION_ALERT_TYPE.LOCK_OFFLINE,
    NOTIFICATION_ALERT_TYPE.LOCK_LOW_STOCK_REQUEST,
    NOTIFICATION_ALERT_TYPE.LOCK_ACTIVITY_SUMMARY,
  ]
}

export interface NotificationPreferenceUpdate {
  alertType: string
  enabled: boolean
  interval: string | undefined
}

export interface NotificationUpdate {
  seen: boolean,
}

export interface NotificationPreference extends NotificationPreferenceUpdate, TimestampMixin {}

export interface Notification extends NotificationUpdate, TimestampMixin {
  id: uuid,
  alertType: string,
  cabinetTransactionId: uuid,
  cabinetTransaction: CabinetTransaction,
  cabinetMaintenanceId: uuid,
  cabinetMaintenance: CabinetMaintenanceLogEvent,
  lockTransactionId: uuid,
  lockTransaction: InterConnectLockTransactionLogEvent
  userId: uuid,
  // Sorta gross hack -
  // Used to highlight the list of cards per element to easily show the user which notifications are 'new'
  viewedLocally: boolean,
}

export class NotificationManagementController {
  constructor(private api: API, private path: string = 'users/me') {}

  async list(
    offset: number,
    limit: number,
    order: OrderDescription[] = [],
  ): Promise<PaginatedResponse<NotificationPreference>> {
    const fallback = { field: 'notification_preference.created_at', direction: 'desc' as OrderDirection }
    const _order = [...order, fallback]
    const orderBy = formatOrderDescription(_order)
    const response = await this.api.authenticated.get<PaginatedResponse<NotificationPreference>>(`${this.path}/notification_preferences`, {
      params: { limit, offset, orderBy },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })

    return response.data
  }

  async update(notificationPrefUpdate: NotificationPreferenceUpdate): Promise<void> {
    try {
      await this.api.authenticated.put<NotificationPreference>(`${this.path}/notification_preferences`, notificationPrefUpdate)
    } catch (error) {
      console.error(error)
    }
  }

  async getNotifications(
    offset: number,
    limit: number,
    order: OrderDescription[] = [],
  ): Promise<PaginatedResponse<Notification>> {
    const fallback = { field: 'notification.created_at', direction: 'desc' as OrderDirection }
    const _order = [...order, fallback]
    const orderBy = formatOrderDescription(_order)
    const response = await this.api.authenticated.get<PaginatedResponse<Notification>>(`${this.path}/notification`, {
      params: { limit, offset, orderBy },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })

    return response.data
  }

  async deleteNotification(notificationId: uuid): Promise<void> {
    await this.api.authenticated.delete<void>(`${this.path}/notification/${notificationId}`)
  }

  async deleteAllNotifications(eventDate: timestamp): Promise<void> {
    await this.api.authenticated.delete<void>(`${this.path}/notification`, {
      params: { timestamp: eventDate }
    })
  }

  async updateSeen(eventDate: timestamp): Promise<void> {
    await this.api.authenticated.put<void>(`${this.path}/notification`, { timestamp: eventDate })
  }

}

export default new NotificationManagementController(instance)
