import { render, staticRenderFns } from "./OrderHistoryAdmin.vue?vue&type=template&id=08d31718&scoped=true&"
import script from "./OrderHistoryAdmin.vue?vue&type=script&lang=ts&"
export * from "./OrderHistoryAdmin.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d31718",
  null
  
)

export default component.exports