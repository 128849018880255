

































































































import { Vue, Component, Prop, Ref } from "vue-property-decorator"
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import SingleClientGroupSelect from '@/components/forms/selects/SingleClientGroupSelect.vue'
import Wizard from '@/components/Wizard.vue'
import Page from '@/components/WizardPage.vue'
import Loading from '@/components/Loading.vue'
import FormWrapper from '@/components/forms/FormWrapper.vue'
import { stateListDict } from '@/api/misc'
import { Client, NewClientForm, StateOption } from '@/api/clients'
import { ClientGroup } from '@/api/client-groups'
import { ACTIONS } from '@/store/modules/client-management'
import axios from 'axios'


@Component({
  components: {
    Wizard,
    Page,
    SingleselectWrapper,
    MultiselectWrapper,
    SingleClientGroupSelect,
    Loading,
    FormWrapper,
  }
})
export default class ClientWizard extends Vue {
  ACTIONS = ACTIONS

  @Prop({ default: false }) editMode!: boolean

  @Ref('wizard') wizard!: Wizard

  loading = false
  clientGroupsLoading = false
  title = this.editMode ? 'Edit Client' : 'New Client'
  stateDict = stateListDict
  errorMessage = ''
  clientPosting = false
  selectedState: StateOption|null = null

  clientGroups: ClientGroup[] = []
  modelProxy: NewClientForm = {
    name: '',
    group: null,
    account_number: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
  }

  async deleteClient(): Promise<void> {
    this.errorMessage = ''
    this.clientPosting = true

    try {
      await this.$store.dispatch(this.ACTIONS.DELETE, (this.modelProxy as Client).id)
      this.$parent.$emit('close')
    } catch (error) {
      this.handleError(error)
    }
  }

  async nextOrSubmit(): Promise<void> {
    if (this.modelProxy.group) {
      this.modelProxy.group_id = this.modelProxy.group.id
    }
    this.modelProxy.state = this.selectedState?.abbreviation as string

    this.errorMessage = ''
    this.clientPosting = true

    try {
      if (this.editMode) {
        await this.$store.dispatch(this.ACTIONS.UPDATE, this.modelProxy)
      } else {
        await this.$store.dispatch(this.ACTIONS.CREATE, this.modelProxy)
      }
      this.$parent.$emit('close')
    } catch (error) {
      this.handleError(error)
    }
  }

  handleError(error: unknown): void {
    this.clientPosting = false
    if (axios.isAxiosError(error)) {
      switch(error.response?.data.description) {
        case null:
        case undefined:
          this.errorMessage = 'Undefined error'
          break
        default:
          this.errorMessage = error.response?.data.description
      }
    }
  }

  async mounted(): Promise<void> {
    const clientId = this.$route.query.client
    if (typeof clientId === 'string' && clientId !== null) {
      this.loading = true
      this.modelProxy = await this.$store.dispatch(this.ACTIONS.GET, clientId)
      this.selectedState = this.stateDict.find((element) => element.abbreviation === this.modelProxy.state) as unknown as StateOption
      this.loading = false
    }
  }
}
