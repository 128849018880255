














import { Vue, Component, Prop, Ref } from "vue-property-decorator"
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { Duration } from 'luxon'
import { TIME_RESOLUTION } from "@/util"

@Component({
  components: {
    VueTimepicker,
  }
})
export default class TimePickerWrapper extends Vue {
  @Prop({ required: true }) value!: number
  @Prop({ required: true }) resolution!: TIME_RESOLUTION

  @Ref('timepicker') timepicker!: Vue

  timeValue: number|null = this.value

  get inputFormat(): string {
    if (this.resolution === TIME_RESOLUTION.SECONDS) {
      return 'HH:mm:ss'
    } else if (this.resolution === TIME_RESOLUTION.MINUTES) {
      return 'HH:mm'
    }
    return 'hh:mm A'
  }

  get inputPlaceholder(): string {
    if (this.resolution === TIME_RESOLUTION.SECONDS) {
      return 'Hours Minutes Seconds'
    } else if (this.resolution === TIME_RESOLUTION.MINUTES) {
      return 'Hours Minutes'
    }
    return 'Hours Minutes AM/PM'
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  get filteredListeners(): { [k: string]: Function | Function[] } {
    // Performing evil listener filtering to prevent the v-model's @input event from being passed in
    // from the parent to the component we're wrapping so that WE can emit input events in its place
    return Object.fromEntries(Object.entries(this.$listeners).filter(([key]) => !key.includes('input')))
  }

  mounted(): void {
    if (this.$attrs.required !== undefined) {
      // Evil for the good of all
      // To allow us to set the field as 'required' since that's not a thing vue2-timepicker allows us to do...
      const input = this.timepicker.$refs.input as HTMLInputElement
      if (this.resolution === TIME_RESOLUTION.SECONDS) {
        input.setAttribute('title','Must be HH:mm:ss')
        input.setAttribute('pattern', '[0-9]{2}:[0-9]{2}:[0-9]{2}')
      } else if (this.resolution === TIME_RESOLUTION.MINUTES) {
        input.setAttribute('title','Must be HH:mm')
        input.setAttribute('pattern', '[0-9]{2}:[0-9]{2}')
      }
      input.setAttribute('required', '')
    }
  }

  get timeString(): string {
    let duration = null
    if (this.timeValue && !isNaN(this.timeValue)) {
      if (this.resolution === TIME_RESOLUTION.SECONDS) {
        duration = Duration.fromMillis(this.timeValue * 1000)
      } else if (this.resolution === TIME_RESOLUTION.MINUTES) {
        duration = Duration.fromMillis(this.timeValue * 1000 * 60)
      }
    }
    if (duration) {
      return duration.toISOTime()
    }

    return ''
  }

  set timeString(time: string) {
    let duration = Duration.fromISOTime(time).toMillis()
    if (isNaN(duration)) {
      duration = 0
    }
    if (this.resolution === TIME_RESOLUTION.SECONDS) {
      this.timeValue = duration / 1000
    } else if (this.resolution === TIME_RESOLUTION.MINUTES) {
      this.timeValue = duration / 1000 / 60
    }
    this.$emit('input', this.timeValue)
  }
}
