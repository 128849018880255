import { Module } from 'vuex'
import { PaginatedResponse } from '@/api'
import { uuid } from '@/api/models'
import { RootState } from '@/store'
import lockUsers, { LockUser, LockDeviceAccessGrant } from '@/api/lock-users'
import {
  namespaced,
  tableActions,
  tableGetters,
  tableMutations,
  tableState,
  TableState,
  TABLE_ACTIONS,
  TABLE_MUTATIONS,
  TABLE_GETTERS,
} from '@/store/mixins/table'

export enum LOCK_USER_ACTIONS {
  PAIR = 'PAIR',
  UNPAIR = 'UNPAIR',
  GET_LOCK_ASSOCIATIONS = 'GET_LOCK_ASSOCIATIONS',
  SEND_PIN_EMAIL = 'SEND_PIN_EMAIL',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LockUserState extends TableState<LockUser> {}

export const namespace = 'lockUserManagement'
export const ACTIONS = namespaced({...TABLE_ACTIONS, ...LOCK_USER_ACTIONS }, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)

export const module: Module<LockUserState, RootState> = {
  namespaced: true,
  state: tableState<LockUser, LockUserState>(),
  getters: tableGetters<LockUser, LockUserState>(),
  mutations: tableMutations<LockUser, LockUserState>({
    [TABLE_MUTATIONS.REPLACE_RESULT]: (state, updatedLockUser: LockUser) => {
      const itemIdx = state.results.findIndex((item) => item.id === updatedLockUser.id)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1, updatedLockUser)
      }
    },
    [TABLE_MUTATIONS.REMOVE_RESULT]: (state, lockUserId: uuid) => {
      const itemIdx = state.results.findIndex((item) => item.id === lockUserId)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1)
        state.total -= 1
      }
    },
  }),
  actions: tableActions<LockUser, LockUserState>({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<LockUser>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return await lockUsers.list(offset, limit, state.order, state.filterIds)
    },
    // eslint-disable-next-line no-empty-pattern
    [TABLE_ACTIONS.GET]: async ({}, lockUserId: uuid): Promise<LockUser> => {
      return await lockUsers.get(lockUserId)
    },
    [TABLE_ACTIONS.UPDATE]: async ({ commit }, lockUser: LockUser): Promise<LockUser> => {
      const updatedLockUser = await lockUsers.update(lockUser)
      commit(TABLE_MUTATIONS.REPLACE_RESULT, updatedLockUser)
      return updatedLockUser
    },
    [TABLE_ACTIONS.CREATE]: async ({ dispatch }, newLockUser: LockUser): Promise<LockUser> => {
      const createdLockUser = await lockUsers.create(newLockUser)
      await dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
      return createdLockUser
    },
    [TABLE_ACTIONS.DELETE]: async ({ commit }, lockUserId: uuid): Promise<unknown> => {
      const response = await lockUsers.delete(lockUserId)
      commit(TABLE_MUTATIONS.REMOVE_RESULT, lockUserId)
      return response
    },
    // eslint-disable-next-line no-empty-pattern
    [LOCK_USER_ACTIONS.PAIR]: async ({}, { lockUserId, lockDeviceGrants }: { lockUserId: uuid, lockDeviceGrants: LockDeviceAccessGrant[] }): Promise<unknown> => {
      return await lockUsers.pair(lockUserId, lockDeviceGrants)
    },
    // eslint-disable-next-line no-empty-pattern
    [LOCK_USER_ACTIONS.UNPAIR]: async ({}, { lockUserId, lockDeviceIds }: { lockUserId: uuid, lockDeviceIds: uuid[] }): Promise<unknown> => {
      return await lockUsers.unpair(lockUserId, lockDeviceIds)
    },
    // eslint-disable-next-line no-empty-pattern
    [LOCK_USER_ACTIONS.GET_LOCK_ASSOCIATIONS]: async ({}, lockUserId: uuid): Promise<PaginatedResponse<LockDeviceAccessGrant>> => {
      return await lockUsers.getLockAssociations(lockUserId)
    },
    // eslint-disable-next-line no-empty-pattern
    [LOCK_USER_ACTIONS.SEND_PIN_EMAIL]: async ({}, lockUserId: uuid): Promise<unknown> => {
      return await lockUsers.sendPinEmail(lockUserId)
    },
  }),
}
