export type LoadingStatus = 'loading'|'error'|'loaded'
export type PageMode = 'replace'|'extend'

// Had to lift this type out because it's used too early in the store initialization??? (I have no clue how)
export enum MODAL_ACTIONS {
  OPEN = 'MODAL_OPEN',
}

export enum MODAL_TYPE {
  ADD_DEVICE = 'add-device',
  EDIT_DEVICE_SUBSCRIPTION = 'edit-device-subscription',
  CHANGE_PASSWORD = 'change-password',
  NEW_CLIENT = 'new-client',
  EDIT_CLIENT = 'edit-client',
  NEW_USER = 'new-user',
  EDIT_USER = 'edit-user',
  EDIT_DEVICE = 'edit-device',
  NEW_LOCK_USER = 'new-lock-user',
  EDIT_LOCK_USER = 'edit-lock-user',
  BULK_LOCK_USERS = 'upload-bulk-lock-users',
}

export enum REPORTS_GETTERS {
  SHARED_DEVICE_REPORT_FILTERS = 'SHARED_DEVICE_REPORT_FILTERS',
  DEVICE_REPORT_FILTERS = 'DEVICE_REPORT_FILTERS',
}

export enum REPORTS_MUTATIONS {
  SET_SHARED_DEVICE_REPORT_FILTERS = 'SET_SHARED_DEVICE_REPORT_FILTERS',
  SET_DEVICE_REPORT_FILTERS = 'SET_DEVICE_REPORT_FILTERS',
}

export enum REPORTS_ACTIONS {
  UPDATE_SHARED_DEVICE_REPORT_FILTERS = 'UPDATE_SHARED_DEVICE_REPORT_FILTERS',
  UPDATE_DEVICE_REPORT_FILTERS = 'UPDATE_DEVICE_REPORT_FILTERS',
  CLEAR_ALL = 'CLEAR_ALL',
}