import Vue from 'vue'
import Vuex, { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from 'vuex'
import { module as scopeTrackingTable, namespace as scopeTrackingTableNamespace } from './scope-tracking-table'
import { module as InterConnectLockTrackingTable, namespace as InterConnectLockTrackingTableNamespace } from './inter-connect-lock-tracking-table'
import {ReportsState} from "@/store/modules/reports";

Vue.use(Vuex)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export const namespace = 'dashboard'

export const module: Module<ReportsState, RootState> = {
  namespaced: false,
  state: {},
  getters: {} as GetterTree<ReportsState, RootState>,
  mutations: {} as MutationTree<ReportsState>,
  actions: {} as ActionTree<ReportsState, RootState>,
  modules: {
    [scopeTrackingTableNamespace]: scopeTrackingTable,
    [InterConnectLockTrackingTableNamespace]: InterConnectLockTrackingTable,
  } as ModuleTree<RootState>,
}
