








































import { Component, Prop } from 'vue-property-decorator'
import VuexBackedTableBasedView from "@/views/tables/VuexBackedTableBasedView"
import DataTable from '@/components/DataTable.vue'
import TableFooter from '@/components/TableFooter.vue'
import { ACTIONS, GETTERS } from '@/store/modules/parts/order-history'
import { TableColumn } from '@/components/table-column'
import EditButton from '@/components/EditButton.vue'
import EnumerateParts from '@/views/order-parts/EnumerateParts.vue'
import { OrderedPart } from '@/store/modules/parts/cart'
import { Order } from '@/api/order'
import { getLocaleStringFromIsoDate } from '@/util'
import DownloadIcon from '@/assets/img/download-icon.svg'
import OrderHistoryIcon from '@/assets/img/order-history-icon.svg'

@Component({
  components: {
    DataTable,
    TableFooter,
    EnumerateParts,
    OrderHistoryIcon,
    EditButton,
    DownloadIcon,
  }
})
export default class OrderHistoryAdminTable extends VuexBackedTableBasedView<Order> {
  ACTIONS = ACTIONS
  GETTERS = GETTERS

  @Prop({ default: false }) newOrders!: boolean

  mounted(): void {
    this.loadData()
  }

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }

  get columns(): TableColumn<Order>[] {
    return [
      {
        title: 'Order ID',
        value: (order) => order.woocommerceOrderId?.toString() ?? '',
        field: 'order.woocommerce_order_id',
        headerColumn: true,
        headerClass: 'columnSmall',
      },
      {
        title: 'Client',
        value: (order) => order.client?.name ?? '',
        field: 'client.name',
        headerClass: 'columnLarge',
      },
      {
        title: 'Sent Date',
        value: (order) => getLocaleStringFromIsoDate(order.createdAt),
        field: 'order.created_at',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        title: 'Items',
      },
    ]
  }

  getPartList(partListSummary: Order): OrderedPart[] {
    return [...partListSummary.parts]
  }

  downloadOrder(order: Order): void {
    console.log(`Download ${order}`)
  }
}
