








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class GroupIndicator extends Vue {
  @Prop({ default: '#3077bc' }) color!: string
}
