













































import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import WebsiteUserWizard from '@/components/forms/WebsiteUserWizard.vue'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import { PAGE_NAMES } from '@/api/misc'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import Auth from '@aws-amplify/auth'


@Component({
  components: {
    Modal,
    WebsiteUserWizard,
    PageBannerHeader,
  }
})
export default class UserManagement extends Vue {
  PAGE_NAMES = PAGE_NAMES
  MODAL_TYPE = MODAL_TYPE
  apiToken = "";
  msApiUrl = process.env.VUE_APP_MS_API_URL;
  msServerApiUrl = process.env.VUE_APP_API_SERVER_URL;

  showWebUserList = true;

  mounted() : void{
    // Get the API ID token
    Auth.currentSession().then(session => {
      const token = session.getIdToken()
      this.apiToken = token.getJwtToken()
      if(token.payload.federated === "true") this.showWebUserList = false
    })
  }

  get proxyId(): string{
    return this.$router.currentRoute.query?.proxyId as string;
  }

  // This assumes that the given user will only ever have one
  // client. In the case of a user having multiple clients, this
  // will select the first client in the store client array arbitrarily
  get clientId(): string {
    let clientId = "";
    try {
      clientId = this.$store.getters.user.clients[0].id as string;
    } catch (error) {
      return clientId;
    }
    return clientId;
  }

  get userType(): string {
    return this.$store.getters.userType as string;
  }

  newUser(): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal:  MODAL_TYPE.NEW_USER })
  }
}
