






















































































































































































































































import { Component, Prop, Ref, Watch } from "vue-property-decorator"
import Wizard from '@/components/Wizard.vue'
import Page from '@/components/WizardPage.vue'
import FormWrapper from '@/components/forms/FormWrapper.vue'
import devices, {
  determineDeviceType,
  DeviceType,
  DeviceUpdate,
  IoTThingCabinetAttributes,
  IoTThingLockAttributes,
  LATCH_CAPABILITY,
  LatchMethod,
  Lock,
  LockSettings,
  LockSettingsUpdate,
  LockUpdate,
  ClientLinkUpdateResponse,
} from '@/api/devices'
import { ACTIONS as DEVICE_ACTIONS } from '@/store/modules/device-management'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import ClientSelect from '@/components/forms/selects/ClientSelect.vue'
import SingleDepartmentSelect from '@/components/forms/selects/SingleDepartmentSelect.vue'
import axios from 'axios'
import DatepickerWrapper from '@/components/forms/DatepickerWrapper.vue'
import TimePickerWrapper from '@/components/TimePickerWrapper.vue'
import Toggle from '@/components/Toggle.vue'
import SuccessIcon from '@/assets/img/success.svg'
import FailureIcon from '@/assets/img/error.svg'
import { TIME_RESOLUTION } from '@/util'
import { uuid } from '@/api/models'
import RoleBasedView from '@/views/RoleBasedView'
import { ClientLite } from "@/api/clients"
import { Department } from "@/api/departments"

@Component({
  components: {
    Wizard,
    Page,
    FormWrapper,
    SingleselectWrapper,
    ClientSelect,
    SingleDepartmentSelect,
    DatepickerWrapper,
    TimePickerWrapper,
    Toggle,
    SuccessIcon,
    FailureIcon
  }
})
export default class DeviceWizard extends RoleBasedView {
  DeviceType = DeviceType
  TIME_RESOLUTION = TIME_RESOLUTION

  deviceType: DeviceType|null = null

  // First page variables
  PAIRING_PIN_LENGTH = 6
  pinEntered = false
  pinCheckSuccess = false
  pairingPin = ""

  modelProxy = {
    name: '',
    clientId: '',
    client: null,
    departmentId: '',
    department: null,
    contact: '',
    note: '',
    location: '',
  } as DeviceUpdate
  departmentDropdownLoading = false

  // Shared variables
  loading = false
  deviceAttributes: IoTThingCabinetAttributes | IoTThingLockAttributes | null = null
  serialNumber = ''
  errorMessage = ''
  originalClient: ClientLite | null = null
  originalDepartment: Department | null = null
  deviceMoveRecordDeleteWarning = ''

  // Lock specific extras
  lockSettings: LockSettingsUpdate = {
    latch1Method: LatchMethod.PIN_OR_RFID,
    latch2Method: LatchMethod.PIN_OR_RFID,
    syncInterval: 360,
    relockDuration: 120,
    lockoutAttempts: 5,
    lockoutDuration: 120,
    lockoutEnable: false,
  }
  isDualLatch = false

  @Ref('wizard') wizard!: Wizard
  @Ref('pinInput') pinInput!: HTMLInputElement
  @Prop({ default: false }) editMode!: boolean

  get selectedClientId(): uuid | null {
    return this.modelProxy.client?.id || null
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true

      const deviceId = this.$route.query.device as string
      if (deviceId) {
        const device = await devices.get(deviceId)
        this.deviceType = determineDeviceType(device.awsThingType)

        this.modelProxy = {
          name: device.name,
          location: device.location,
          note: device.note,
          contact: device.contact,
          client: device.client,
          department: device.department,
        }
        this.originalClient = device.client
        this.originalDepartment = device.department

        this.serialNumber = device.serialNumber
        if (this.deviceType === DeviceType.CABINET) {
          this.deviceAttributes = device.attributes as IoTThingCabinetAttributes
        } else if (this.deviceType === DeviceType.LOCK) {
          this.deviceAttributes = device.attributes as IoTThingLockAttributes
          this.lockSettings = { ...(device as Lock).lockSettings } as LockSettings
          this.lockSettings.syncInterval = (device as Lock).lockSettings?.syncInterval ?? 360
          this.isDualLatch = this.deviceAttributes.lockType === LATCH_CAPABILITY.DUAL_LOCK
        }
      }
      this.loading = false
    } catch (error) {
      this.handlePairDeviceError(error)
    }

    if (!this.editMode) {
      this.$nextTick(() => { this.pinInput.focus() })
    }
  }

  async onClientChangeDepartmentsLoaded(availableDepartments: Department[]): Promise<void> {
    this.deviceMoveRecordDeleteWarning = ''

    const deviceId = this.$route.query.device as string
    if (deviceId && this.selectedClientId !== this.originalClient?.id && this.selectedClientId && availableDepartments.length > 0) {
      this.loading = true

      try {
        const r: ClientLinkUpdateResponse = await this.$store.dispatch(DEVICE_ACTIONS.CHANGE_CLIENT, { deviceId: deviceId, request: {
          clientId: this.selectedClientId,
          departmentId: availableDepartments[0].id,
          dryRun: true,
        }})
        if (this.deviceType === DeviceType.CABINET) {
          this.deviceMoveRecordDeleteWarning = `Changing this cabinet's client will permanently delete ${r.cabinet_scopes_deleted} scopes and ${r.cabinet_transactions_deleted + r.cabinet_maintenance_deleted} related records.`
        } else {
          this.deviceMoveRecordDeleteWarning = `Changing this lock's client will permanently delete ${r.lock_transactions_deleted} related records.`
        }
      } catch {
        this.deviceMoveRecordDeleteWarning = 'An unknown error has occured'
      }

      this.loading = false
    }
  }

  /** Listen to when the pairing pin input on the first page changes, verifies pin when length is PAIRING_PIN_LENGTH */
  @Watch('pairingPin')
  valueWatcher(newValue: string): void {
    if (newValue.length === this.PAIRING_PIN_LENGTH) {
      this.checkPairingPin()
    } else {
      this.pinEntered = false
    }
  }

  /** Handle the logic upon clicking the enter button of the device wizard form */
  async nextOrSubmit(): Promise<void> {
    const deviceId = this.$route.query.device as string
    if (deviceId) {
      this.loading = true

      let deviceUpdate = { ...this.modelProxy }
      if (this.deviceType === DeviceType.LOCK) {
        (deviceUpdate as LockUpdate).lockSettings = this.lockSettings
      }

      try {
        // If a solaire admin is trying to change the client, we need to treat "saving" differently
        if (this.originalClient?.id !== this.modelProxy.client?.id && this.modelProxy.client && this.modelProxy.department) {
          // overwrite the client and department to prevent them from changing initially
          deviceUpdate.client = this.originalClient
          deviceUpdate.department = this.originalDepartment
          // save all other changes
          await this.$store.dispatch(DEVICE_ACTIONS.UPDATE_INFO, { deviceId: deviceId, request: deviceUpdate })
          // initiate the actual move
          await this.$store.dispatch(DEVICE_ACTIONS.CHANGE_CLIENT, { deviceId: deviceId, request: {
            clientId: this.modelProxy.client.id,
            departmentId: this.modelProxy.department.id,
            dryRun: false,
          }})
        } else {
          // Otherwise make the request as normal
          await this.$store.dispatch(DEVICE_ACTIONS.UPDATE_INFO, { deviceId: deviceId, request: deviceUpdate })
        }
        this.$parent.$emit('close')
      } catch (error) {
        this.handlePairDeviceError(error)
      }
    } else if (this.wizard.selectedIndex === 0) {
      this.wizard.selectNextPage()
    } else {
      this.loading = true

      let deviceUpdate = { ...this.modelProxy }
      if (this.deviceType === DeviceType.LOCK) {
        (deviceUpdate as LockUpdate).lockSettings = this.lockSettings
      }

      try {
        await this.$store.dispatch(DEVICE_ACTIONS.PAIR, { pairingPin: this.pairingPin, request: deviceUpdate })
        this.$parent.$emit('close')
      } catch (error) {
        this.handlePairDeviceError(error)
      }
    }
  }

  /** From the second page of the wizard, if pairing fails, handle the error */
  handlePairDeviceError(err: unknown): void {
    this.loading = false
    if (axios.isAxiosError(err)) {
      switch(err.response?.data.description) {
        case null:
        case undefined:
          this.errorMessage = 'An unknown error occurred.'
          break
        default:
          this.errorMessage = err.response?.data.description
      }
    }
  }

  /** Verify the device pairing pin is valid, otherwise display appropriate errors */
  private async checkPairingPin(): Promise<void> {
    this.loading = true
    try {
      const device = await this.$store.dispatch(DEVICE_ACTIONS.VERIFY, this.pairingPin)
      this.deviceType = determineDeviceType(device.thingTypeName)

      if (this.deviceType === DeviceType.CABINET) {
        this.deviceAttributes = device.attributes as IoTThingCabinetAttributes
        this.serialNumber = this.deviceAttributes.serialNumber
      } else if (this.deviceType === DeviceType.LOCK) {
        this.deviceAttributes = device.attributes as IoTThingLockAttributes
        this.isDualLatch = this.deviceAttributes.lockType === LATCH_CAPABILITY.DUAL_LOCK
        this.serialNumber = this.deviceAttributes.serial

        // If it's a single lock, we need to clear out the latch2 lock type
        if (!this.isDualLatch) {
          this.lockSettings.latch2Method = null
        }
      }

      this.pinCheckSuccess = true
      this.pinEntered = true // Pin entered after we determine success/failure so it doesn't flash an old status on the screen
      this.loading = false
    } catch (error) {
      this.pinCheckSuccess = false
      this.pinEntered = true
      this.loading = false
    }
  }

  get titleText(): string {
    let result = ''
    if (this.editMode) {
      result += 'Edit '
    } else {
      result += 'Add '
    }

    switch (this.deviceType) {
      case DeviceType.LOCK:
        result += 'Lock'
        break;
      case DeviceType.CABINET:
        result += 'Cabinet'
        break;
      default:
        result += 'Device'
    }

    return result
  }

  get lockAccessValues(): string[] {
    return Object.values(LatchMethod)
  }
}
