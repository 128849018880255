










import DownloadIcon from '@/assets/img/download-icon.svg'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { LoadingStatus } from '@/store/types'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    DownloadIcon,
    Loading,
  }
})
export default class DownloadButton extends Vue {
  @Prop({ default: "Download" }) title!: string
  @Prop({ default: "loaded" }) status!: LoadingStatus
}
