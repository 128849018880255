














import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    Multiselect,
  }
})
export default class MultiselectWrapper extends Vue {
  @Prop() propertyName!: string
  @Prop() value!: string[] | Record<string, unknown>[]
  @Prop({ default: null }) required!: boolean | null

  @Ref('multiselect') multiselect!: Multiselect

  multiselectInput: HTMLInputElement | null = null

  get placeholderText(): string | unknown {
    if (this.value.length === 1) {
      if (typeof this.value[0] === 'string') {
        return this.value[0] !== '' ? this.value[0] : `${this.value.length} ${this.propertyName}`
      } else {
        return this.$attrs['label'] ? this.value[0][this.$attrs['label']] : `${this.value.length} ${this.propertyName}`
      }
    } else {
      return `${this.value.length} ${this.propertyName}`
    }
  }

  @Watch('value')
  onValueChange(newValue: string[] | Record<string, unknown>[]): void {
    if (this.multiselectInput) {
      this.multiselectInput.required = this.required !== null && newValue.length === 0
    }
  }

  mounted(): void {
    this.multiselectInput = this.multiselect.$el.querySelector('input')
    if (this.multiselectInput) {
      this.multiselectInput.required = this.required !== null && this.value.length === 0
    }
  }
}
