import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store";
import { namespaced } from "@/store/mixins/table";
import { Part } from '@/api/parts'

export enum CART_GETTERS {
  TOTAL_PART_COUNT = 'TOTAL_PART_COUNT',
  TOTAL_PRICE_ESTIMATE = 'TOTAL_PRICE_ESTIMATE',
  CART_OPEN = 'CART_OPEN',
  GET_SELECTED_PARTS = 'GET_SELECTED_PARTS',
}

export enum CART_MUTATIONS {
  UPDATE_SELECTED_PARTS = 'UPDATE_SELECTED_PARTS',
  REMOVE_PART = 'REMOVE_PART',
  TOGGLE_CART_OPEN = 'TOGGLE_CART_OPEN',
  CLEAR = 'CLEAR',
}

export enum CART_ACTIONS {
  UPDATE_SELECTED_PARTS = 'UPDATE_SELECTED_PARTS',
  REMOVE_PART = 'REMOVE_PART',
  TOGGLE_CART_OPEN = 'TOGGLE_CART_OPEN',
  CLEAR = 'CLEAR',
}

export interface OrderedPart extends Part {
  count: number
}

// Used for preserving last selected report type
const SELECTED_PARTS = 'SELECTED_PARTS'

export interface CartState {
  selectedParts: OrderedPart[]
  open: boolean
}

function defaultState(): CartState {
  return {
    selectedParts: JSON.parse(localStorage.getItem(SELECTED_PARTS) ?? '[]'),
    open: false
  }
}

export const namespace = 'cart'
export const ACTIONS = namespaced(CART_ACTIONS, namespace)
export const GETTERS = namespaced(CART_GETTERS, namespace)

export const module: Module<CartState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    [CART_GETTERS.TOTAL_PART_COUNT]: (state) => {
      return state.selectedParts
        .map(selectedPart => selectedPart.count)
        .reduce((accumulator, value) => {
          return accumulator + value
        }, 0)
    },
    [CART_GETTERS.TOTAL_PRICE_ESTIMATE]: (state) => {
      return state.selectedParts
        .map(selectedPart => selectedPart.price ? parseInt(selectedPart.price) * selectedPart.count : 0)
        .reduce((accumulator, value) => {
          return accumulator + value
        }, 0)
    },
    [CART_GETTERS.CART_OPEN]: (state) => state.open,
    [CART_GETTERS.GET_SELECTED_PARTS]: (state) => state.selectedParts
  } as GetterTree<CartState, RootState>,
  mutations: {
    [CART_MUTATIONS.UPDATE_SELECTED_PARTS]: (state, selectedPart: OrderedPart) => {
      const index = state.selectedParts.findIndex((value) => {
        return value.id === selectedPart.id
      })
      if (index !== -1) {
        state.selectedParts.splice(index, 1, selectedPart)
      } else {
        state.selectedParts.push(selectedPart)
      }
      // Update local storage with selected parts to preserve state upon refresh
      localStorage.setItem(SELECTED_PARTS, JSON.stringify(state.selectedParts))
    },
    [CART_MUTATIONS.REMOVE_PART]: (state, partId: number) => {
      const index = state.selectedParts.findIndex((value) => {
        return value.id === partId
      })

      state.selectedParts.splice(index, 1)

      // Update local storage so this doesn't magically reappear when you refresh
      localStorage.setItem(SELECTED_PARTS, JSON.stringify(state.selectedParts))
    },
    [CART_MUTATIONS.TOGGLE_CART_OPEN]: (state, override?: boolean) => {
      if (override !== undefined) {
        state.open = override
      } else {
        state.open = !state.open
      }
    },
    [CART_MUTATIONS.CLEAR]: (state) => {
      localStorage.removeItem(SELECTED_PARTS)
      Object.assign(state, defaultState())
    }
  } as MutationTree<CartState>,
  actions: {
    [CART_ACTIONS.UPDATE_SELECTED_PARTS]: ({ commit }, selectedPart: OrderedPart) => {
      commit(CART_MUTATIONS.UPDATE_SELECTED_PARTS, selectedPart)
    },
    [CART_ACTIONS.REMOVE_PART]: ({ commit }, partId: number) => {
      commit(CART_MUTATIONS.REMOVE_PART, partId)
    },
    [CART_ACTIONS.TOGGLE_CART_OPEN]: ({ commit }, override?: boolean) => {
      commit(CART_MUTATIONS.TOGGLE_CART_OPEN, override)
    },
    [CART_ACTIONS.CLEAR]: ({ commit }) => {
      commit(CART_MUTATIONS.CLEAR)
    }
  } as ActionTree<CartState, RootState>,
}
