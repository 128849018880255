import { Module } from 'vuex'
import { RootState } from '@/store'
import {
  namespaced,
  TABLE_ACTIONS,
  TABLE_GETTERS,
  tableActions,
  tableGetters,
  tableMutations,
  TableState,
  tableState
} from '@/store/mixins/table'
import order, { Order } from '@/api/order'
import { PaginatedResponse } from '@/api'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderHistoryState extends TableState<Order> {
  // Put any module specific things in here
}

export const namespace = 'orderHistory'
export const ACTIONS = namespaced(TABLE_ACTIONS, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)

export const module: Module<OrderHistoryState, RootState> = {
  namespaced: true,
  state: tableState<Order, OrderHistoryState>(),
  getters: tableGetters(),
  mutations: tableMutations(),
  actions: tableActions({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<Order>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return order.list(offset, limit, state.order, state.filterIds)
    },
  }),
}