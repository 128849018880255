



































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { LoadingStatus } from '@/store/types'
import Card from '@/components/Card.vue'
import { ArcElement, Chart, ChartConfiguration, ChartOptions, DoughnutController } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import HelpIcon from '@/assets/img/question-mark-icon.svg'
import CloseIcon from '@/assets/img/close-icon.svg'

// There should be a better way to do this
const EMPTY_CHART_COLOR = '#e2ebf5'
const NO_ISSUE_CHART_COLOR = '#049e89'

@Component( {
  components: {
    Loading,
    Card,
    HelpIcon,
    CloseIcon,
  }
})
export default class ChartCard extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) description!: string
  @Prop({ required: true }) helpText!: string
  @Prop({ required: true }) chartData!: number[]
  @Prop({ required: true }) color!: string
  @Prop({ required: true }) id!: string
  @Prop({ required: true }) status!: LoadingStatus

  chart: Chart|null = null
  showHelpOverlay = false

  @Watch('chartData')
  updateIssueNumber(): void {
    if (this.status === 'loaded') {
      this.createChart()
    }
  }

  get hasIssues(): boolean {
    return this.chartData[0] > 0
  }

  createChart(): void {
    const canvas = document.getElementById(this.id) as HTMLCanvasElement
    Chart.register(DoughnutController, ArcElement, ChartDataLabels)
    this.chart = new Chart(canvas, {
      type: 'doughnut',
      plugins: [ChartDataLabels],
      data: {
        datasets: [{
          data: this.hasIssues ? this.chartData : [100],
          backgroundColor: this.hasIssues ? [this.color, EMPTY_CHART_COLOR] : [NO_ISSUE_CHART_COLOR],
        }],
      },
      options: {
        cutout: '70%',
        plugins: {
          datalabels: {
            formatter: () => '' // This is what you have to do to remove the labels I guess
          }
        },
      } as ChartOptions,
    } as ChartConfiguration)
  }
}
