import { render, staticRenderFns } from "./CabinetTransactionNotification.vue?vue&type=template&id=672e129b&scoped=true&"
import script from "./CabinetTransactionNotification.vue?vue&type=script&lang=ts&"
export * from "./CabinetTransactionNotification.vue?vue&type=script&lang=ts&"
import style0 from "./CabinetTransactionNotification.vue?vue&type=style&index=0&id=672e129b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "672e129b",
  null
  
)

export default component.exports