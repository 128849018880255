








import { Component, Vue } from 'vue-property-decorator'
import ScopeStatus from '@/components/charts/drying-units/ScopeStatus.vue'
import ScopeTrackingTable from '@/components/ScopeTrackingTable.vue'
import MaintenanceAlerts from '@/components/charts/drying-units/MaintenanceAlerts.vue'
import { SCOPE_STATUS } from '@/api/scope-actions'

@Component({
  components: {
    ScopeStatus,
    ScopeTrackingTable,
    MaintenanceAlerts,
  }
})
export default class DryingUnitDashboard extends Vue {
  currentStatusFilters: SCOPE_STATUS[] = []

  statusFilterChanged(statusFilter: SCOPE_STATUS[]): void {
    this.currentStatusFilters = statusFilter
  }
}
