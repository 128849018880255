
export interface MaintenanceAlert {
  status: string,
  type: string,
  name: string,
  date: Date,
}

export enum MAINTENANCE_STATUS {
  EXPIRED = 'Expired',
  UPCOMING = 'Upcoming',
}

/** Doesn't exist right now

export class MaintenanceAlertController {
  constructor(private api: API, private path: string = 'maintenance_alerts') {}
}

 */
