















import { Component, Vue } from 'vue-property-decorator'
import TopNavBar from '@/router/TopNavBar.vue'
import SideNavBar from '@/router/SideNavBar.vue'
import '@fontsource/material-icons-round';

// Svelte Components
import '@solaire-medical/svelte-component-library/dist/index.esm.js'
import '@solaire-medical/components/dist/index.esm.js'
import '@solaire-medical/tailwind-components/dist/index.js';

@Component({
  components: {
    TopNavBar,
    SideNavBar,
  },
})
export default class App extends Vue {
  isSignedIn(): boolean {
    return this.$route.name !== 'Sign In'
  }
}
