import { Module } from 'vuex'
import { PaginatedResponse } from '@/api'
import devices, { Device, DeviceType, Lock, InterConnectLockTransactionLogEvent } from '@/api/devices'
import { RootState} from '@/store'
import { FilterType, TRANSACTION_LOG_ACTION, uuid } from '@/api/models'
import { DeviceFilters, SharedDeviceFilters } from '@/store/modules/reports'
import { REPORTS_ACTIONS, REPORTS_GETTERS, REPORTS_MUTATIONS } from '@/store/types'
import {
  namespaced,
  TABLE_ACTIONS,
  TABLE_GETTERS,
  TABLE_MUTATIONS,
  tableActions,
  tableGetters,
  tableMutations,
  TableState,
  tableState,
} from '@/store/mixins/table'
import { getFilterQueryFromFilters } from '@/util'

export enum SMART_LOCK_REPORTS_ACTIONS {
  UPDATE_TRANSACTION_LOG_FILTERS = 'UPDATE_TRANSACTION_LOG_FILTERS',
  UPDATE_LOCK_SETTINGS_FILTERS = 'UPDATE_LOCK_SETTINGS_FILTERS',
  REFRESH_TABLE = 'REFRESH_TABLE',
  UPDATE_REPORT_TYPE = 'UPDATE_REPORT_TYPE',
}

export enum SMART_LOCK_REPORTS_MUTATIONS {
  SET_TRANSACTION_LOG_FILTERS = 'SET_TRANSACTION_LOG_FILTERS',
  SET_LOCK_SETTINGS_FILTERS = 'SET_LOCK_SETTINGS_FILTERS',
  SET_REPORT_TYPE = 'SET_REPORT_TYPE',
}

export enum SMART_LOCK_REPORTS_GETTERS {
  TRANSACTION_LOG_FILTERS = 'TRANSACTION_LOG_FILTERS',
  LOCK_SETTINGS_FILTERS = 'LOCK_SETTINGS_FILTERS',
  REPORT_TYPE = 'REPORT_TYPE',
}

export interface TransactionLogFilters {
  userNames: string[]
  actions: TRANSACTION_LOG_ACTION[]
  eventDateStart?: Date
  eventDateEnd?: Date
}

export interface LockSettingsFilters {
  capabilities: string[]
  batteryPercentageThresholds: string[]
  settingsDateStart?: Date
  settingsDateEnd?: Date
  lastSeenThresholds: string[]
}

interface InterConnectLockReportsState extends TableState<Device> {
  transactionLogFilters: TransactionLogFilters
  sharedDeviceFilters: SharedDeviceFilters
  lockSettingsFilters: LockSettingsFilters
  deviceFilters: DeviceFilters
  reportType: REPORT_TYPE
}

export enum REPORT_TYPE {
  TRANSACTION_LOG = 'Transaction Log',
  CURRENT_SETTINGS = 'Current Settings',
}

export enum BATTERY_PERCENTAGE_THRESHOLD {
  LESS_THAN_10 = '< 10',
  LESS_THAN_5 = '< 5',
}

export interface BatteryPercentThresholdOption {
  title: string,
  value: BATTERY_PERCENTAGE_THRESHOLD,
}

/*
 * Look away and be happy
 *
 * Okay you're still here. The '<<<' is a result of us needing to store a unique value in state and <= is
 * already taken :(
 *
 * The end. I'm sorry.
 */
export enum LAST_SEEN_THRESHOLD {
  TWENTY_FOUR_HOURS = '<<< 24HOURS'
}

export interface LastSeenThresholdOption {
  title: string,
  value: LAST_SEEN_THRESHOLD,
}

export function defaultState(filterIds: uuid[] = [], clearShared=true): InterConnectLockReportsState {
  return {
    ...tableState<Device, InterConnectLockReportsState>({ filterIds }),
    ...(clearShared ? {
      sharedDeviceFilters: {
        deviceNames: [],
        deviceSerialNumbers: [],
        locations: [],
        departmentNames: [],
        contacts: [],
      }
    } : undefined),
    deviceFilters: {
      deviceIds: [],
    },
    transactionLogFilters: {
      userNames: [],
      actions: [],
      eventDateStart: undefined,
      eventDateEnd: undefined,
    },
    lockSettingsFilters: {
      capabilities: [],
      batteryPercentageThresholds: [],
      settingsDateStart: undefined,
      settingsDateEnd: undefined,
      lastSeenThresholds: [],
    },
    reportType: localStorage.getItem(SELECTED_REPORT_TYPE) as REPORT_TYPE ?? REPORT_TYPE.TRANSACTION_LOG
  }
}

export const namespace = 'InterConnectLockReports'
export const ACTIONS = namespaced({ ...TABLE_ACTIONS, ...REPORTS_ACTIONS, ...SMART_LOCK_REPORTS_ACTIONS }, namespace)
export const GETTERS = namespaced({ ...TABLE_GETTERS, ...REPORTS_GETTERS, ...SMART_LOCK_REPORTS_GETTERS }, namespace)

// Used for preserving last selected report type
const SELECTED_REPORT_TYPE = namespace + '/SELECTED_REPORT_TYPE'

export const module: Module<InterConnectLockReportsState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: tableGetters({
    [REPORTS_GETTERS.SHARED_DEVICE_REPORT_FILTERS]: (state: InterConnectLockReportsState) => state.sharedDeviceFilters,
    [REPORTS_GETTERS.DEVICE_REPORT_FILTERS]: (state: InterConnectLockReportsState) => state.deviceFilters,
    [SMART_LOCK_REPORTS_GETTERS.TRANSACTION_LOG_FILTERS]: (state: InterConnectLockReportsState) => state.transactionLogFilters,
    [SMART_LOCK_REPORTS_GETTERS.LOCK_SETTINGS_FILTERS]: (state: InterConnectLockReportsState) => state.lockSettingsFilters,
    [SMART_LOCK_REPORTS_GETTERS.REPORT_TYPE]: (state: InterConnectLockReportsState) => state.reportType
  }),
  mutations: tableMutations({
    [REPORTS_MUTATIONS.SET_SHARED_DEVICE_REPORT_FILTERS]: (state, sharedDeviceFilters: SharedDeviceFilters) => {
      state.sharedDeviceFilters = sharedDeviceFilters
    },
    [REPORTS_MUTATIONS.SET_DEVICE_REPORT_FILTERS]: (state, deviceFilters: DeviceFilters) => {
      state.deviceFilters = deviceFilters
    },
    [SMART_LOCK_REPORTS_MUTATIONS.SET_TRANSACTION_LOG_FILTERS]: (state, transactionLogFilters: TransactionLogFilters) => {
      state.transactionLogFilters = transactionLogFilters
    },
    [SMART_LOCK_REPORTS_MUTATIONS.SET_LOCK_SETTINGS_FILTERS]: (state, lockSettingsFilters: LockSettingsFilters) => {
      state.lockSettingsFilters = lockSettingsFilters
    },
    [SMART_LOCK_REPORTS_MUTATIONS.SET_REPORT_TYPE]: (state, reportType: REPORT_TYPE) => {
      state.reportType = reportType
      // Preserve the last selected report type in local storage for page refreshes
      localStorage.setItem(SELECTED_REPORT_TYPE, reportType)
    },
  }),
  actions: tableActions({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<InterConnectLockTransactionLogEvent|Lock>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      if (state.reportType === REPORT_TYPE.TRANSACTION_LOG) {
        return await devices.getInterConnectLockTransactionLogEvents(offset, limit, state.order, [], state.filterIds, state.sharedDeviceFilters, state.transactionLogFilters) as PaginatedResponse<InterConnectLockTransactionLogEvent>
      } else if (state.reportType === REPORT_TYPE.CURRENT_SETTINGS) {
        const query = getFilterQueryFromFilters({
          ...state.sharedDeviceFilters,
          ...state.lockSettingsFilters,
        }, FilterType)

        return await devices.list(
          offset,
          limit,
          state.order ?? {
            field: 'lock_settings.checkin_time',
            direction: 'desc',
          },
          state.filterIds,
          [DeviceType.LOCK],
          query
        ) as PaginatedResponse<Lock>
      }

      return {
        items: [],
        total: 0
      }
    },
    [TABLE_ACTIONS.CLEAR]: async ({ state, commit }): Promise<void> => {
      commit(TABLE_MUTATIONS.CLEAR, defaultState(state.filterIds, false))
    },
    [REPORTS_ACTIONS.CLEAR_ALL]: async ({ state, commit }): Promise<void> => {
      commit(TABLE_MUTATIONS.CLEAR, defaultState(state.filterIds, true))
    },
    [REPORTS_ACTIONS.UPDATE_SHARED_DEVICE_REPORT_FILTERS]: ({ state, commit }, filters: Partial<SharedDeviceFilters>): void => {
      commit(REPORTS_MUTATIONS.SET_SHARED_DEVICE_REPORT_FILTERS, { ...state.sharedDeviceFilters, ...filters })
    },
    [REPORTS_ACTIONS.UPDATE_DEVICE_REPORT_FILTERS]: ({ state, commit }, filters: Partial<DeviceFilters>): void => {
      commit(REPORTS_MUTATIONS.SET_DEVICE_REPORT_FILTERS, { ...state.deviceFilters, ...filters })
    },
    [SMART_LOCK_REPORTS_ACTIONS.REFRESH_TABLE]: async ({ commit, dispatch }): Promise<void> => {
      // We set page to zero to stop filtering jank
      commit(TABLE_MUTATIONS.SET_PAGE, 0)
      await dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
    },
    [SMART_LOCK_REPORTS_ACTIONS.UPDATE_TRANSACTION_LOG_FILTERS]: async ({ state, commit }, filters: Partial<TransactionLogFilters>): Promise<void> => {
      commit(SMART_LOCK_REPORTS_MUTATIONS.SET_TRANSACTION_LOG_FILTERS, { ...state.transactionLogFilters, ...filters })
    },
    [SMART_LOCK_REPORTS_ACTIONS.UPDATE_LOCK_SETTINGS_FILTERS]: async({ state, commit }, filters: Partial<LockSettingsFilters>): Promise<void> => {
      commit(SMART_LOCK_REPORTS_MUTATIONS.SET_LOCK_SETTINGS_FILTERS, { ...state.lockSettingsFilters, ...filters })
    },
    [SMART_LOCK_REPORTS_ACTIONS.UPDATE_REPORT_TYPE]: async ({ commit, dispatch }, reportType: REPORT_TYPE): Promise<void> => {
      await dispatch(TABLE_ACTIONS.CLEAR)
      commit(SMART_LOCK_REPORTS_MUTATIONS.SET_REPORT_TYPE, reportType)
    },
  })
}