import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@/store";
import { module as partsList, namespace as partsListNamespace } from './parts-list'
import { module as cart, namespace as cartNamespace } from './cart'
import { module as history, namespace as historyNamespace } from './order-history'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OrderPartsState {
  // Put any module specific things in here
}

export const namespace = 'orderParts'

export const module: Module<OrderPartsState, RootState> = {
  namespaced: false,
  state: {} as OrderPartsState,
  getters: {} as GetterTree<OrderPartsState, RootState>,
  mutations: {} as MutationTree<OrderPartsState>,
  actions: {} as ActionTree<OrderPartsState, RootState>,
  modules: {
    [partsListNamespace]: partsList,
    [cartNamespace]: cart,
    [historyNamespace]: history
  } as ModuleTree<RootState>
}
