














import { Component, Vue } from 'vue-property-decorator'
import InterConnectLockTrackingTable from '@/components/InterConnectLockTrackingTable.vue'
import LowBatteryChart from '@/components/charts/inter-connect-locks/LowBatteryChart.vue'
import LostConnectionChart from '@/components/charts/inter-connect-locks/LostConnectionChart.vue'
import { ACTIONS, GETTERS, InterConnectLockTrackingChartData } from '@/store/modules/dashboard/inter-connect-lock-tracking-table'
import { LoadingStatus } from '@/store/types'

@Component({
  components: {
    InterConnectLockTrackingTable,
    LowBatteryChart,
    LostConnectionChart,
  }
})
export default class InterConnectLockDashboard extends Vue {
  status: LoadingStatus = 'loading'
  chartData: InterConnectLockTrackingChartData = {
    totalLocks: 0,
    totalLowBatteryLocks: 0,
    totalMissingConnectionLocks: 0,
  }

  get totalDevices(): number {
    return this.$store.getters[GETTERS.TOTAL]
  }

  mounted(): void {
    this.$store.dispatch(ACTIONS.GET_CHART_DATA)
      .then((chartData) => {
        this.chartData = chartData
        this.status = 'loaded'
      })
      .catch(() => {
        this.status = 'error'
      })
  }
}
