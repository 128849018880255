














































































import { Component, Prop, Watch } from 'vue-property-decorator'
import DataTable from '@/components/DataTable.vue'
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import { TableColumn } from '@/components/table-column'
import { ACTIONS, GETTERS, defaultState } from '@/store/modules/dashboard/scope-tracking-table'
import TrackingIcon from '@/assets/img/tracking.svg'
import TableFooter from '@/components/TableFooter.vue'
import { CabinetTransaction, ScopeTrackingFilters } from '@/api/devices'
import { getLocaleStringFromIsoDate } from '@/util'
import ReadyIcon from '@img/ready.svg'
import ExpiredIcon from '@img/expired.svg'
import DryingIcon from '@img/drying.svg'
import QueuedIcon from '@img/queued.svg'
import CheckedOutIcon from '@img/checked-out.svg'
import router from '@/router'
import { SCOPE_STATUS } from '@/api/scope-actions'
import { convertToUuidArray } from '@/util'

@Component({
  components: {
    DataTable,
    TrackingIcon,
    TableFooter,
    ReadyIcon,
    ExpiredIcon,
    DryingIcon,
    QueuedIcon,
    CheckedOutIcon,
  }
})
export default class ScopeTrackingTable extends VuexBackedTableBasedView<CabinetTransaction> {
  ACTIONS = ACTIONS
  GETTERS = GETTERS
  SCOPE_STATUS = SCOPE_STATUS

  protected date: Date = new Date()
  protected timestamp: number = this.date.getTime()
  private tickInterval: number|undefined = undefined

  @Prop({ default: [] }) statusFilters!: SCOPE_STATUS[]

  selectedTransaction: CabinetTransaction | null = null

  mounted(): void {
    this.loadData(this.proxyIds)
    this.tick()
  }

  beforeDestroy (): void {
    if (this.tickInterval) {
      clearInterval(this.tickInterval)
    }
  }

  // There really isn't a way to get move this to a mixin because
  // we need to extend from VuexBackedTableBasedView
  protected tick (): void {
    if (this.tickInterval) {
      clearInterval(this.tickInterval)
    }

    this.date = new Date()
    this.timestamp = this.date.getTime()

    // Reschedule next tick for the start of the next clock second
    this.tickInterval = setInterval(this.tick, 1000 - this.date.getMilliseconds())
  }

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR, defaultState())
  }

  get proxyIds(): string[] {
    return convertToUuidArray(router.currentRoute.query?.proxyId)
  }

  slotActive(rowIndex: number, columnIndex: number): boolean {
    return (rowIndex - 1) * this.selectedCabinetColumnCount + (columnIndex - 1) === this.selectedTransaction?.slot
  }

  get selectedCabinetColumnCount(): number {
    return this.selectedTransaction?.device.attributes.cabinetColumnCount ?? 0
  }

  get selectedCabinetRowCount(): number {
    return this.selectedTransaction?.device.attributes.cabinetRowCount ?? 0
  }

  get columns(): TableColumn<CabinetTransaction>[] {
    const injectedColumns: TableColumn<CabinetTransaction>[] = []
    const hasInternalIds = this.results.some(transaction => transaction.internalId)
    if (hasInternalIds) {
      injectedColumns.push({
        id: 'internalId',
        title: 'Internal ID',
        value: (row) => row ? row?.internalId ?? row.scope?.internalId ?? '' : '',
        field: 'cabinet_transaction.internal_id',
        headerClass: 'columnSmall',
      })
    }
    const hasBrands = this.results.some(transaction => transaction.brand)
    if (hasBrands) {
      injectedColumns.push({
        id: 'brand',
        title: 'Brand',
        value: (row) => row?.brand ?? row.scope?.brand ?? '',
        field: 'cabinet_transaction.brand',
        headerClass: 'columnSmall',
      })
    }
    const hasModelNumbers = this.results.some(transaction => transaction.modelNumber)
    if (hasModelNumbers) {
      injectedColumns.push({
        id: 'modelNumber',
        title: 'Model Number',
        value: (row) => row?.modelNumber ?? row.scope?.modelNumber ?? '',
        field: 'cabinet_transaction.model_number',
        headerClass: 'columnSmall',
      })
    }
    const hasDescriptions = this.results.some(transaction => transaction.description)
    if (hasDescriptions) {
      injectedColumns.push({
        id: 'description',
        title: 'Description',
        value: (row) => row?.description ?? row.scope?.description ?? '',
        field: 'cabinet_transaction.description',
        headerClass: 'columnSmall',
      })
    }
    const columnsToAdd: TableColumn<CabinetTransaction>[] = [
      {
        id: 'serialNumber',
        title: 'Serial Number',
        headerColumn: true,
        sticky: true,
        value: (row) => row?.serialNumber ?? row.scope?.serialNumber ?? '',
        field: 'cabinet_transaction.serial_number',
        headerClass: 'columnSmall',
      },
      {
        id: 'status',
        title: 'Status',
        value: (row) => SCOPE_STATUS.mapActionToStatusString(row.action),
        field: 'cabinet_transaction.action',
        headerClass: 'columnSmall',
      },
      {
        id: 'expirationTime',
        title: 'Expiration Time',
        value: (row) => row.expiresAt ?? '',
        field: 'cabinet_transaction.expires_at',
        headerClass: 'columnSmall',
      },
      {
        id: 'dryingCabinetName',
        title: 'Drying Cabinet Name',
        value: (row) => row.device.name,
        field: 'device.name',
        headerClass: 'columnSmall',
      },
      {
        id: 'location',
        title: 'Location',
        value: (row) => row.device.location ?? '',
        field: 'device.location',
        headerClass: 'columnSmall',
      }
    ]

    columnsToAdd.splice(1, 0, ...injectedColumns)

    return columnsToAdd
  }

  updateScopeTrackingFilters(filters: Partial<ScopeTrackingFilters>): void {
    this.$store.dispatch(this.ACTIONS.UPDATE_SCOPE_TRACKING_FILTERS, filters)
  }

  @Watch("statusFilters")
  statusFiltersChanged(statuses: SCOPE_STATUS[]): void {
    const actions = statuses.map((status) => status.toString())
    this.updateScopeTrackingFilters({ actions })
  }


  transactionSelected(transaction: CabinetTransaction | null): void {
    this.selectedTransaction = transaction
  }

  convertedTimestamp(timestamp: string): string {
    return getLocaleStringFromIsoDate(timestamp)
  }

  hasNegativeTimeRemaining(timestamp: string | null): boolean {
    if (!timestamp) {
      return false
    }
    const timeleft = new Date(timestamp).getTime() - new Date().getTime()
    // If the time remaining is >1 minute, return true to make text red.
    return timeleft < -(1000 * 60)
  }

  timeRemaining(timestamp: string): string {
    if (!timestamp) {
      return ''
    }
    const timeleft = new Date(timestamp).getTime() - this.timestamp

    if (timeleft >= 0) {
      // This is not how you do localization... like at all... why didn't we bring in a library......
      const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

      const hourString = hours > 9 ? `${hours}` : `0${hours}`
      const minuteString = minutes > 9 ? `${minutes}` : `0${minutes}`
      const secondString = seconds > 9 ? `${seconds}` : `0${seconds}`

      if (days > 0) {
        return `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`
      } else if (hours > 0) {
        return `${hourString}:${minuteString}:${secondString}`
      } else {
        return `${minuteString}:${secondString}`
      }
    } else {
      // Show the date & time instead... and if it's 1 minute old, make the text red.
      return this.convertedTimestamp(timestamp)
    }
  }
}
