




























import { Component, Prop, Vue } from 'vue-property-decorator'
import Toggle from '@/components/Toggle.vue'
import TrashCanIcon from '@/assets/img/trash-can-icon.svg'
import { getPriceFromString } from '@/util'
import { OrderedPart } from '@/store/modules/parts/cart'

@Component({
  components: {
    Toggle,
    TrashCanIcon,
  }
})
export default class OrderSummaryCard extends Vue {
  @Prop({ required: true }) orderSummary!: OrderedPart

  get price(): string {
    return parseFloat(this.orderSummary.price) === 0 ? 'N/A' : getPriceFromString(this.orderSummary.price)
  }

  removeItem(): void {
    this.$emit('removeItem', this.orderSummary.id)
  }
}
