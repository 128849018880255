export type uuid = string
export type timestamp = string

export interface HasAddress {
  address1: string
  address2: string|null
  city: string
  state: string
  zipcode: string
}

export function singleLineAddressFormat(address: HasAddress): string {
  /**
  Defensively build up an address string in the format
  1234 Street St., Apt 101, City MI 40000
  Omitting parts of the address that are null and unnecessary punctuation as needed
  */

  /**
  e.g.
  1234 Street St., Apt 101
  1234 Street St.
  Apt 101
  */
  const street = [address.address1, address.address2].filter(e => !!e).join(', ')

  /**
  e.g.
  City MI 40000
  City MI
  City 40000
  MI 40000
  40000
  */
  const city = [address.city, address.state, address.zipcode].filter(e => !!e).join(' ')

  // Combine but remove a part entirely if it is empty
  return [street, city].filter(e => !!e).join(', ')
}

export interface TimestampMixin {
  createdAt: timestamp
  lastUpdated: timestamp
}

export interface CRUD {
  created_at: timestamp
  is_deleted: boolean
  last_updated: timestamp
}

// TODO: I'd like to force our python schemas to use camelCase when serialized
export interface CRUDCamelCase extends TimestampMixin {
  isDeleted: boolean
}

export interface UnitValue {
  unit: string
  value: number
}

//TODO this is a temp fix and we likely need a more sophisticated way to handle this
/**
  Enum to assist when building filter queries
  It's setup to use the value we store in state and mapped to the corresponding db column
 */
export enum FilterType {
  // Shared Device Filters
  deviceIds='device.id',
  contacts='device.contact',
  clientIds='device.client_id',
  deviceNames='device.name',
  deviceSerialNumbers='device.serial_number',
  departmentNames='department.name',
  locations='device.location',
  lastSeenThresholds='device.last_seen',

  // Cabinet Filters
  lastUpdatedStart='device.last_updated',
  lastUpdatedEnd='device.last_updated',

  // Sensor Data Filters
  sensorDataDateStart='cabinet_sensor_reading.timestamp',
  sensorDataDateEnd='cabinet_sensor_reading.timestamp',

  // Transaction Log Filters
  userNames='lock_user.name',
  actions='lock_transaction.event_type',
  eventDateStart='lock_transaction.timestamp',
  eventDateEnd='lock_transaction.timestamp',

  // Lock Settings Filters
  capabilities='device.attributes',
  batteryPercentageThresholds='lock_settings.battery_percentage',
  settingsDateStart='lock_settings.last_updated',
  settingsDateEnd='lock_settings.last_updated',
}

export interface FilterOp {
  op: string,
  attr: string,
  val: string,
}

export interface FilterStatement {
  op: string,
  val: (FilterStatement|FilterOp)[],
}

export enum TRANSACTION_LOG_ACTION {
  LATCH_ACCESS='latchAccess',
  SYNC_SUCCESS='syncSuccess',
  SYNC_FAIL='syncFail',
  LOW_BATTERY='lowBattery',
  STOCKING_NOTIFICATION='stockingNotification',
  UNAUTHORIZED_ACCESS_ATTEMPT='unauthorizedAccessAttempt',
}

export enum ExpiredEvents {
  CABINET_HOSE='cabinetHoseExpired',
  CABINET_FILTER='cabinetFilterExpired',
}

export enum ReminderEvents {
  CABINET_HOSE='cabinetHoseReminder',
  CABINET_FILTER='cabinetFilterReminder',
}
