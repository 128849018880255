

























import { Component } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import CsvVuexBackedTableBasedView from '@/views/tables/CsvVuexBackedTableBasedView'
import { ACTIONS, GETTERS } from '@/store/modules/reports/inter-connect-lock-reports'
import TableFooter from '@/components/TableFooter.vue'
import { TableColumn } from '@/components/table-column'
import DownloadButton from '@/components/DownloadButton.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper from '@/components/forms/Datepicker.vue'
import { uuid, TRANSACTION_LOG_ACTION } from '@/api/models'
import { InterConnectLockTransactionLogEvent, LowBatteryFields, LatchAccessFields } from '@/api/devices'
import { convertToUuidArray } from '@/util'
import InterConnectLockIcon from '@/assets/img/inter-connect-lock-icon.svg'

@Component({
  components: {
    PageBannerHeader,
    DataTable,
    TableFooter,
    InterConnectLockIcon,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DownloadButton,
  }
})
export default class TransactionLogReportTable extends CsvVuexBackedTableBasedView<InterConnectLockTransactionLogEvent> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  clientIds: uuid[] = []
  proxyIds: uuid[] = []

  async mounted(): Promise<void> {
    this.fetchAction = this.ACTIONS.FETCH_LIST
    this.reportName = 'Lock Transactions Report.csv'

    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(clientFilterIds)
  }

  getEventTypeText(row: InterConnectLockTransactionLogEvent): string {
    let result = "";
    switch (row.eventType) {
      case TRANSACTION_LOG_ACTION.LOW_BATTERY:
        result = `${result} (${(row.eventData as LowBatteryFields)?.battery_percentage}%)`
        break;
      case TRANSACTION_LOG_ACTION.UNAUTHORIZED_ACCESS_ATTEMPT:
        result = `${result} (${(row.eventData as LatchAccessFields)?.access_method || row.eventType})`
        break;
      default:
        result = `${row.eventType}`
    }
    return result;
  }

  get columns(): TableColumn<InterConnectLockTransactionLogEvent>[] {
    return [
      {
        id: 'deviceName',
        title: 'Lock Name',
        headerColumn: true,
        sticky: true,
        value: (row: InterConnectLockTransactionLogEvent) => row.device.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'deviceId',
        title: 'Device ID',
        value: (row: InterConnectLockTransactionLogEvent) => row.device.serialNumber,
        field: 'device.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'department',
        title: 'Department',
        value: (row: InterConnectLockTransactionLogEvent) => row.device.department?.name ?? '',
        field: 'department.name',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'location',
        title: 'Location',
        value: (row: InterConnectLockTransactionLogEvent) => row.device.location ?? '',
        field: 'device.location',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'contact',
        title: 'Contact',
        value: (row: InterConnectLockTransactionLogEvent) => row.device.contact ?? '',
        field: 'device.contact',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'eventType',
        title: 'Action',
        value: (row: InterConnectLockTransactionLogEvent) => this.getEventTypeText(row),
        headerClass: 'columnMedium',
      },
      {
        id: 'userName',
        title: 'User Name',
        value: (row: InterConnectLockTransactionLogEvent) => row.lockUser?.name ?? '',
        field: 'lock_user.name',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'timestamp',
        title: 'Event Timestamp',
        value: (row: InterConnectLockTransactionLogEvent) => row.timestamp ? new Date(row.timestamp).toLocaleString() : '',
        field: 'lock_transaction.timestamp',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
    ]
  }
}
