import { Module } from 'vuex'
import { PaginatedResponse } from '@/api'
import devices, { Cabinet, CabinetMaintenanceLogEvent, CabinetTransaction, CabinetSensorReadingEvent, Device, DeviceType } from '@/api/devices'
import { RootState } from '@/store'
import { FilterType, uuid } from '@/api/models'
import { DeviceFilters, SharedDeviceFilters } from '@/store/modules/reports'
import { REPORTS_ACTIONS, REPORTS_GETTERS, REPORTS_MUTATIONS } from '@/store/types'
import {
  namespaced,
  TABLE_ACTIONS,
  TABLE_GETTERS,
  TABLE_MUTATIONS,
  tableActions,
  tableGetters,
  tableMutations,
  TableState,
  tableState,
} from '@/store/mixins/table'
import { getFilterQueryFromFilters } from '@/util'

export enum DRYING_UNIT_ACTIONS {
  UPDATE_SCOPE_ACTIVITY_FILTERS = 'UPDATE_SCOPE_ACTIVITY_FILTERS',
  UPDATE_MAINTENANCE_LOG_FILTERS = 'UPDATE_MAINTENANCE_LOG_FILTERS',
  UPDATE_SENSOR_READINGS_FILTERS = 'UPDATE_SENSOR_READINGS_FILTERS',
  REFRESH_TABLE = 'REFRESH_TABLE',
  UPDATE_REPORT_TYPE = 'UPDATE_REPORT_TYPE',
}

export enum DRYING_UNIT_MUTATIONS {
  SET_SCOPE_ACTIVITY_FILTERS = 'SET_SCOPE_ACTIVITY_FILTERS',
  SET_MAINTENANCE_LOG_FILTERS = 'SET_MAINTENANCE_LOG_FILTERS',
  SET_SENSOR_READINGS_FILTERS = 'SET_SENSOR_READINGS_FILTERS',
  SET_REPORT_TYPE = 'SET_REPORT_TYPE',
}

export enum DRYING_UNIT_GETTERS {
  SCOPE_ACTIVITY_FILTERS = 'SCOPE_ACTIVITY_FILTERS',
  MAINTENANCE_LOG_FILTERS = 'MAINTENANCE_LOG_FILTERS',
  SENSOR_READINGS_FILTERS = 'SENSOR_READINGS_FILTERS',
  REPORT_TYPE = 'REPORT_TYPE',
}

export interface ScopeActivityFilters {
  timestampStart?: Date
  timestampEnd?: Date
  serialNumbers: string[]
  modelNumbers: string[]
  internalIds: string[]
  caseNumbers: string[]
  actions: string[]
  users: string[]
  brands: string[]
  endoscopistNames: string[]
}

export interface MaintenanceLogFilters {
  actions: string[]
  timestampStart?: Date
  timestampEnd?: Date
}

export interface SensorReadingsFilters {
  sensorDataDateStart?: Date
  sensorDataDateEnd?: Date
}

export enum DRYING_UNIT_REPORT_TYPE {
  SCOPE_ACTIVITY = 'Scope Activity',
  MAINTENANCE_LOG = 'Maintenance Log',
  CURRENT_SETTINGS = 'Current Settings',
  SENSOR_READINGS = 'Sensor Readings',
}

interface DryingUnitReportsState extends TableState<Device> {
  scopeActivityFilters: ScopeActivityFilters
  maintenanceLogFilters: MaintenanceLogFilters
  sensorReadingsFilters: SensorReadingsFilters
  sharedDeviceFilters?: SharedDeviceFilters
  deviceFilters: DeviceFilters
  reportType: DRYING_UNIT_REPORT_TYPE
}

function defaultState(filterIds: uuid[] = [], clearShared=true): DryingUnitReportsState {
  return {
    ...tableState<Device, DryingUnitReportsState>({ filterIds }),
    ...(clearShared ? {
      sharedDeviceFilters: {
        deviceNames: [],
        deviceSerialNumbers: [],
        locations: [],
        departmentNames: [],
        contacts: [],
      }
    } : undefined),
    deviceFilters: {
      deviceIds: [],
    },
    scopeActivityFilters: {
      timestampStart: undefined,
      timestampEnd: undefined,
      serialNumbers: [],
      modelNumbers: [],
      internalIds: [],
      caseNumbers: [],
      actions: [],
      users: [],
      brands: [],
      endoscopistNames: [],
    },
    maintenanceLogFilters: {
      actions: [],
      timestampStart: undefined,
      timestampEnd: undefined,
    },
    sensorReadingsFilters: {
      sensorDataDateStart: undefined,
      sensorDataDateEnd: undefined,
    },
    reportType: localStorage.getItem(SELECTED_REPORT_TYPE) as DRYING_UNIT_REPORT_TYPE ?? DRYING_UNIT_REPORT_TYPE.SCOPE_ACTIVITY
  }
}

export const namespace = 'dryingUnitReports'
export const ACTIONS = namespaced({ ...TABLE_ACTIONS, ...REPORTS_ACTIONS, ...DRYING_UNIT_ACTIONS }, namespace)
export const GETTERS = namespaced({ ...TABLE_GETTERS, ...REPORTS_GETTERS, ...DRYING_UNIT_GETTERS }, namespace)

// Used for preserving last selected report type
const SELECTED_REPORT_TYPE = namespace + '/SELECTED_REPORT_TYPE'

export const module: Module<DryingUnitReportsState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: tableGetters({
    [REPORTS_GETTERS.SHARED_DEVICE_REPORT_FILTERS]: (state: DryingUnitReportsState) => state.sharedDeviceFilters,
    [REPORTS_GETTERS.DEVICE_REPORT_FILTERS]: (state: DryingUnitReportsState) => state.deviceFilters,
    [DRYING_UNIT_GETTERS.SCOPE_ACTIVITY_FILTERS]: (state: DryingUnitReportsState) => state.scopeActivityFilters,
    [DRYING_UNIT_GETTERS.MAINTENANCE_LOG_FILTERS]: (state: DryingUnitReportsState) => state.maintenanceLogFilters,
    [DRYING_UNIT_GETTERS.SENSOR_READINGS_FILTERS]: (state: DryingUnitReportsState) => state.sensorReadingsFilters,
    [DRYING_UNIT_GETTERS.REPORT_TYPE]: (state: DryingUnitReportsState) => state.reportType
  }),
  mutations: tableMutations({
    [REPORTS_MUTATIONS.SET_SHARED_DEVICE_REPORT_FILTERS]: (state, sharedDeviceFilters: SharedDeviceFilters) => {
      state.sharedDeviceFilters = sharedDeviceFilters
    },
    [REPORTS_MUTATIONS.SET_DEVICE_REPORT_FILTERS]: (state, deviceFilters: DeviceFilters) => {
      state.deviceFilters = deviceFilters
    },
    [DRYING_UNIT_MUTATIONS.SET_SCOPE_ACTIVITY_FILTERS]: (state, scopeActivityFilters: ScopeActivityFilters) => {
      state.scopeActivityFilters = scopeActivityFilters
    },
    [DRYING_UNIT_MUTATIONS.SET_MAINTENANCE_LOG_FILTERS]: (state, maintenanceLogFilters: MaintenanceLogFilters) => {
      state.maintenanceLogFilters = maintenanceLogFilters
    },
    [DRYING_UNIT_MUTATIONS.SET_SENSOR_READINGS_FILTERS]: (state, sensorReadingsFilters: SensorReadingsFilters) => {
      state.sensorReadingsFilters = sensorReadingsFilters
    },
    [DRYING_UNIT_MUTATIONS.SET_REPORT_TYPE]: (state, reportType: DRYING_UNIT_REPORT_TYPE) => {
      state.reportType = reportType
      // Preserve the last selected report type in local storage for page refreshes
      localStorage.setItem(SELECTED_REPORT_TYPE, reportType)
    },
  }),
  actions: tableActions({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<Cabinet|CabinetTransaction|CabinetMaintenanceLogEvent|CabinetSensorReadingEvent>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      if (state.reportType === DRYING_UNIT_REPORT_TYPE.CURRENT_SETTINGS) {
        const query = getFilterQueryFromFilters({
          ...state.sharedDeviceFilters,
          ...state.deviceFilters,
        }, FilterType)
        return await devices.list(offset, limit, state.order, state.filterIds, [DeviceType.CABINET], query) as PaginatedResponse<Cabinet>
      } else if (state.reportType === DRYING_UNIT_REPORT_TYPE.SCOPE_ACTIVITY) {
        return await devices.getCabinetTransactions(offset, limit, state.order, state.filterIds, undefined, state.sharedDeviceFilters, state.scopeActivityFilters)
      } else if (state.reportType === DRYING_UNIT_REPORT_TYPE.MAINTENANCE_LOG) {
        return await devices.getCabinetMaintenanceEvents(offset, limit, state.order, undefined, state.filterIds, state.sharedDeviceFilters, state.maintenanceLogFilters)
      } else if (state.reportType === DRYING_UNIT_REPORT_TYPE.SENSOR_READINGS) {
        return await devices.getCabinetSensorReadingEvents(offset, limit, state.order, undefined, state.filterIds, state.sharedDeviceFilters, state.sensorReadingsFilters)
      }

      return {
        items: [],
        total: 0
      }
    },
    [TABLE_ACTIONS.CLEAR]: async ({ state, commit }): Promise<void> => {
      commit(TABLE_MUTATIONS.CLEAR, defaultState(state.filterIds, false))
    },
    [REPORTS_ACTIONS.CLEAR_ALL]: async ({ state, commit }): Promise<void> => {
      commit(TABLE_MUTATIONS.CLEAR, defaultState(state.filterIds, true))
    },
    [REPORTS_ACTIONS.UPDATE_SHARED_DEVICE_REPORT_FILTERS]: ({ state, commit }, filters: Partial<SharedDeviceFilters>): void => {
      commit(REPORTS_MUTATIONS.SET_SHARED_DEVICE_REPORT_FILTERS, { ...state.sharedDeviceFilters, ...filters })
    },
    [REPORTS_ACTIONS.UPDATE_DEVICE_REPORT_FILTERS]: ({ state, commit }, filters: Partial<DeviceFilters>): void => {
      commit(REPORTS_MUTATIONS.SET_DEVICE_REPORT_FILTERS, { ...state.deviceFilters, ...filters })
    },
    [DRYING_UNIT_ACTIONS.UPDATE_SCOPE_ACTIVITY_FILTERS]: ({ state, commit }, filters: Partial<ScopeActivityFilters>): void => {
      commit(DRYING_UNIT_MUTATIONS.SET_SCOPE_ACTIVITY_FILTERS, { ...state.scopeActivityFilters, ...filters })
    },
    [DRYING_UNIT_ACTIONS.UPDATE_MAINTENANCE_LOG_FILTERS]: ({ state, commit }, filters: Partial<MaintenanceLogFilters>): void => {
      commit(DRYING_UNIT_MUTATIONS.SET_MAINTENANCE_LOG_FILTERS, { ...state.maintenanceLogFilters, ...filters })
    },
    [DRYING_UNIT_ACTIONS.UPDATE_SENSOR_READINGS_FILTERS]: ({ state, commit }, filters: Partial<SensorReadingsFilters>): void => {
      commit(DRYING_UNIT_MUTATIONS.SET_SENSOR_READINGS_FILTERS, { ...state.sensorReadingsFilters, ...filters })
    },
    [DRYING_UNIT_ACTIONS.REFRESH_TABLE]: async ({ commit, dispatch }): Promise<void> => {
      // We set page to zero to stop filtering jank
      commit(TABLE_MUTATIONS.SET_PAGE, 0)
      await dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
    },
    [DRYING_UNIT_ACTIONS.UPDATE_REPORT_TYPE]: async ({ commit, dispatch }, reportType: DRYING_UNIT_REPORT_TYPE): Promise<void> => {
      await dispatch(TABLE_ACTIONS.CLEAR)
      commit(DRYING_UNIT_MUTATIONS.SET_REPORT_TYPE, reportType)
    },
  })
}
