
import { Component } from 'vue-property-decorator'
import TableFooter from '@/components/TableFooter.vue'
import { ACTIONS } from '@/store/modules/notification-management'
import Modal from '@/components/Modal.vue'
import EditButton from '@/components/EditButton.vue'
import Toggle from '@/components/Toggle.vue'
import NotificationIcon from '@/assets/img/notifications-icon.svg'
import { DeviceType } from '@/api/devices'
import NotificationTable from '@/views/notification-management/NotificationTable.vue'

@Component({
  components: {
    TableFooter,
    Modal,
    EditButton,
    Toggle,
    NotificationIcon
  }
})
export default class CabinetNotificationTable extends NotificationTable {
  ACTIONS = ACTIONS

  mounted(): void {
    this.$store.dispatch(ACTIONS.SET_NOTIFICATIONS_TYPE, DeviceType.CABINET)
    this.loadData()
  }
}
