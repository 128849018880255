











import EditIcon from '@/assets/img/edit.svg'
import { Component } from 'vue-property-decorator'
import { USER_ACTIONS } from '@/store/modules/user'
import { PROXY_ID } from '@/api/misc'
import RoleBasedView from '@/views/RoleBasedView'
import SwapIcon from '@/assets/img/people_alt_black_24dp.svg'


@Component({
  components: {
    SwapIcon,
    EditIcon,
  }
})
export default class ExitClientViewButton extends RoleBasedView {
  returnToAdmin(): void {
    this.$store.dispatch(USER_ACTIONS.RETURN_TO_ADMIN)
    const queryParams = this.$router.currentRoute.query
    delete queryParams[PROXY_ID]
    this.$router.push({ path: '/settings/client-management', query: queryParams })
  }
}
