

























import { Component } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import CsvVuexBackedTableBasedView from '@/views/tables/CsvVuexBackedTableBasedView'
import { ACTIONS, GETTERS } from '@/store/modules/reports/inter-connect-lock-reports'
import TableFooter from '@/components/TableFooter.vue'
import { TableColumn } from '@/components/table-column'
import DownloadButton from '@/components/DownloadButton.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper from '@/components/forms/Datepicker.vue'
import { uuid } from '@/api/models'
import InterConnectLockIcon from '@/assets/img/inter-connect-lock-icon.svg'
import { Lock } from '@/api/devices'
import { convertToUuidArray, getLocaleStringFromIsoDate } from "@/util"

@Component({
  components: {
    PageBannerHeader,
    DataTable,
    TableFooter,
    InterConnectLockIcon,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DownloadButton,
  }
})
export default class InterConnectLockSettingsReportTable extends CsvVuexBackedTableBasedView<Lock> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  clientIds: uuid[] = []
  proxyIds: uuid[] = []

  async mounted(): Promise<void> {
    this.fetchAction = this.ACTIONS.FETCH_LIST
    this.reportName = 'Lock Settings Report.csv'

    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(clientFilterIds)
  }

  get columns(): TableColumn<Lock>[] {
    return [
      {
        id: 'deviceName',
        title: 'Lock Name',
        headerColumn: true,
        sticky: true,
        value: (row: Lock) => row.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'deviceId',
        title: 'Device ID',
        value: (row: Lock) => row.serialNumber,
        field: 'device.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'department',
        title: 'Department',
        value: (row: Lock) => row.department?.name ?? '',
        field: 'department.name',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'location',
        title: 'Location',
        value: (row: Lock) => row.location ?? '',
        field: 'device.location',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'contact',
        title: 'Contact',
        value: (row: Lock) => row.contact ?? '',
        field: 'device.contact',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'serialNumber',
        title: 'Serial Number',
        value: (row: Lock) => row.serialNumber ?? '',
        field: 'device.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'batteryPercentage',
        title: 'Battery Percentage',
        value: (row: Lock) => row.lockSettings?.batteryPercentage?.toString() ?? '',
        field: 'lock_settings.battery_percentage',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'latchCapability',
        title: 'Latch Capability',
        value: (row: Lock) => row.attributes?.lockType ?? '',
        field: 'device.attributes.lockType',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'latch1',
        title: 'Latch 1',
        value: (row: Lock) => row.lockSettings?.latch1Method ?? '',
        field: 'lock_settings.latch1_method',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'latch2',
        title: 'Latch 2',
        value: (row: Lock) => row.lockSettings?.latch2Method ?? '',
        field: 'lock_settings.latch2_method',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'lastAccessed',
        title: 'Last Accessed',
        value: (row: Lock) => getLocaleStringFromIsoDate(row.lastAccessed),
        field: 'lock_settings.last_accessed',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'lastSeen',
        title: 'Last Connected',
        value: (row: Lock) => getLocaleStringFromIsoDate(row.lastSeen),
        field: 'lock_settings.last_seen',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'lastUpdated',
        title: 'Last Updated',
        value: (row: Lock) => getLocaleStringFromIsoDate(row.lockSettings?.lastUpdated),
        field: 'lock_settings.last_updated',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
    ]
  }
}
