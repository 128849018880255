import { Component } from 'vue-property-decorator'

import { TableColumn } from '@/components/table-column'

import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import { LoadingStatus } from '@/store/types'

// A Wrapper around VuexBackedTableBasedView to converting the table on screen
// into a CSV the user can download.
//
// In order for subclasses of this component to work, you will need to overwrite
// this.fetchAction and this.reportName in mounted()
@Component
export default class CsvVuexBackedTableBasedView<Item> extends VuexBackedTableBasedView<Item> {
  downloadCSVstatus: LoadingStatus = 'loaded'
  fetchAction: string|null = null
  reportName:string|null = null

  downloadCSV(filename: string, text: string): void {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  sanitizeCSVEntry(value: string|null): string {
    return value ? `"${value.replaceAll('"', '""')}"` : ''
  }

  convertResultsToCSV(results: Item[]): string {
    const header = this.columns.map((column: TableColumn<Item>) => this.sanitizeCSVEntry(column.title)).join(',')
    return header + '\n' +
      results.map((row: Item) => {
        return this.columns.map((column: TableColumn<Item>) => {
          return column.value ? this.sanitizeCSVEntry(column.value(row)) : ''
        }).join(',')
      }).join('\n')
  }

  downloadReport(): void {
    if (this.fetchAction === null || this.reportName === null) {
      console.error('ERROR - fetchAction or reportName not set')
      return
    }
    this.downloadCSVstatus = 'loading'
    this.$store.dispatch(this.fetchAction, false)
      .then((response) => {
        this.downloadCSV(this.reportName as string, this.convertResultsToCSV(response.items))
        this.downloadCSVstatus = 'loaded'
      })
      .then(() => {
        this.downloadCSVstatus = 'error'
      })
  }
}
