







import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class WizardPage extends Vue {
  @Prop() pageName!: string

  // Override the title and subtitle props of the Wizard as needed
  @Prop() title?: string
  @Prop() subtitle?: string

  isActive = false
}
