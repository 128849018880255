import { Module } from 'vuex'
import { PaginatedResponse } from '@/api'
import clients, { Client, NewClientForm } from '@/api/clients'
import { RootState } from '@/store'
import {
  namespaced,
  tableActions,
  tableGetters,
  tableMutations,
  tableState,
  TableState,
  TABLE_ACTIONS,
  TABLE_GETTERS,
  TABLE_MUTATIONS,
} from '@/store/mixins/table'
import { uuid } from "@/api/models";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ClientManagementState extends TableState<Client> {
  // Put any module specific things in here
}

export const namespace = 'clientManagement'
export const ACTIONS = namespaced(TABLE_ACTIONS, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)

export const module: Module<ClientManagementState, RootState> = {
  namespaced: true,
  state: tableState<Client, ClientManagementState>(),
  getters: tableGetters(),
  mutations: tableMutations({
    [TABLE_MUTATIONS.REPLACE_RESULT]: (state, updatedClient: Client) => {
      const itemIdx = state.results.findIndex((item) => item.id === updatedClient.id)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1, updatedClient)
      }
    },
    [TABLE_MUTATIONS.REMOVE_RESULT]: (state, clientId: uuid) => {
      const itemIdx = state.results.findIndex((item) => item.id === clientId)
      if (itemIdx >= 0) {
        state.results.splice(itemIdx, 1)
        state.total -= 1
      }
    },
  }),
  actions: tableActions({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<Client>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return clients.list(offset, limit, state.order)
    },
    [TABLE_ACTIONS.CREATE]: async ({ dispatch }, newClientForm: NewClientForm): Promise<Client> => {
      const fields = { ...newClientForm } // make a copy that we can delete from
      if (fields.group) {
        fields.group_id = fields.group.id
        delete fields.group
      }
      return clients.create(fields)
        .then((newClient) => {
          // TODO: This probably resets what page you're on, or removes too many entries if multiple pages are loaded.
          dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace'})
          return newClient
        });
    },
    [TABLE_ACTIONS.UPDATE]: async ({ commit }, client: Client): Promise<Client> => {
      const fields = { ...client } // make a copy that we can delete from
      if (fields.group) {
        fields.group_id = fields.group.id
        delete fields.group
      }
      return clients.update(fields)
        .then((updatedClient) => {
          commit(TABLE_MUTATIONS.REPLACE_RESULT, client)
          return updatedClient
        });
    },
    // eslint-disable-next-line no-empty-pattern
    [TABLE_ACTIONS.GET]: async ({}, clientId: string): Promise<Client> => {
      return clients.get(clientId)
        .then((client: Client) => client)
    },
    [TABLE_ACTIONS.DELETE]: async ({ commit }, clientId: uuid): Promise<unknown> => {
      return clients.delete(clientId)
        .then((response) => {
          commit(TABLE_MUTATIONS.REMOVE_RESULT, clientId)
          return response
        });
    },
  })
}
