import instance, { API, formatOrderDescription, OrderDescription, OrderDirection, PaginatedResponse } from '.'
import { Department } from '@/api/departments'
import {
  CRUDCamelCase,
  UnitValue,
  uuid,
  TRANSACTION_LOG_ACTION,
  timestamp,
  FilterStatement,
  FilterOp
} from '@/api/models'
import qs from 'qs'
import { SharedDeviceFilters } from '@/store/modules/reports'
import { MaintenanceLogFilters, ScopeActivityFilters, SensorReadingsFilters } from '@/store/modules/reports/drying-unit-reports'
import { TransactionLogFilters } from '@/store/modules/reports/inter-connect-lock-reports'
import { ClientLite } from '@/api/clients'
import { isPassedDue, Optional, getFilterQueryFromFilters } from '@/util'
import api from '@/api/index'
import { LockUserLite } from '@/api/lock-users'
import { FilterType } from '@/api/models'

export interface Device extends CRUDCamelCase, ClientSubscription {
  id: uuid
  name: string
  awsThingName: string
  awsThingType: string
  client: ClientLite
  department: Department
  serialNumber: string
  hardwareVersion: string
  location: string | null
  contact: string | null
  note: string | null
  lastSeen: Optional<timestamp>
  lastAccessed: Optional<timestamp>
}

export interface IoTThing {
  thingName: string
  thingTypeName: string
  attributes: IoTThingCabinetAttributes|IoTThingLockAttributes
}

export interface Cabinet extends Device {
  cabinetSettings: Optional<CabinetSettings>
  attributes: IoTThingCabinetAttributes
}

export interface Lock extends Device {
  lockSettings: Optional<LockSettings>
  attributes: Optional<IoTThingLockAttributes>
}

export interface DeviceUpdate {
  name: string
  client?: ClientLite | null
  clientId?: uuid
  department?: Department | null
  departmentId?: uuid
  location: Optional<string>
  contact: Optional<string>
  note: Optional<string>
}

export interface ClientLinkUpdate {
  clientId: uuid
  dryRun: boolean
}

export interface ClientLinkUpdateResponse {
  cabinet_transactions_deleted: number
  cabinet_maintenance_deleted: number
  cabinet_scopes_deleted: number
  lock_transactions_deleted: number
}

// For PUT purposes of a Lock Device
export interface LockUpdate extends DeviceUpdate {
  lockSettings: Optional<LockSettingsUpdate>
}

export interface IoTThingAttributes {
  env: string
}

export interface IoTThingCabinetAttributes extends IoTThingAttributes {
  cabinetType: string
  cabinetRowCount: number
  cabinetColumnCount: number
  serialNumber: string
  hardwareVersion: string
}

export interface IoTThingLockAttributes extends IoTThingAttributes {
  hardwareId: string
  wifiMac: string
  serial: string
  chipId: string,
  group: string,
  lockType: LATCH_CAPABILITY,
  lockGroup: LOCK_GROUP,
  mac: string,
}

export interface CabinetSettings {
  thingName: string // AWS thing name
  pairingCode: string
  cabinetFilterLastReset: string // ISO 8601 date string
  cabinetHoseLastReset: string // ISO 8601 date string
  checkInFieldsTable: FieldsTableItem[]
  checkOutFieldsTable: FieldsTableItem[]
  dryingTime: UnitValue
  ventingTime: UnitValue
  ventingInterval: UnitValue
  expirationTime: UnitValue
}

export interface LockSettings extends CRUDCamelCase {
  thingName: string // AWS thing name
  latch1Method: string
  latch2Method: Optional<string>
  relockDuration: Optional<number>
  lockoutEnable: boolean
  lockoutAttempts: Optional<number>
  lockoutDuration: Optional<number>
  syncInterval: number
  batteryPercentage: Optional<number> // Decimal?
  firmware: Optional<string>
  hardwareId: Optional<string>
  serialNumber: Optional<string>
}

export interface LockSettingsUpdate {
  syncInterval: number
  latch1Method: string
  latch2Method: Optional<string>
  relockDuration: Optional<number>
  lockoutEnable: boolean
  lockoutAttempts: Optional<number>
  lockoutDuration: Optional<number>
}

export interface FieldsTableItem {
  autocomplete: boolean
  display: string
  isCustomField: boolean
  key: string
  visibility: string
}

export interface ClientSubscription {
  subscriptionStart: string
  subscriptionEnd: string | null
  subscriptionActive: boolean
  subscriptionPaid: string | null
  passedDue?: boolean
}

export interface CabinetTransaction {
  id: uuid
  internalId: string | null
  device: Omit<Cabinet, "cabinetSettings" | "client">
  thingName: string
  action: string
  slot: number
  serialNumber: string | null
  modelNumber: string | null
  description: string | null
  brand: string | null;
  user: string | null
  endoscopistName: string | null
  caseNumber: string | null
  // When the event was received on the IoT platform
  receivedAt: string // ISO 8601
  // How long the scope was in the previous state (in seconds)
  completedDuration: number | null,
  // When the event will expire on the device
  expiresAt: string | null // ISO 8601
  // The transaction timestamp as reported by the device
  timestamp: string // ISO 8601


  scope: Scope
  customFieldEntries: {
    [key: string]: string
  } | null
}

export interface CabinetTransactionFilterOptions {
  internalIds: string[] | null
  serialNumbers: string[] | null
  modelNumbers: string[] | null
  brands: string[] | null
  users: string[] | null
  endoscopistNames: string[] | null
  caseNumbers: string[] | null
}

export interface InterConnectLockTransactionLogFilterOptions {
  names: Optional<string[]>
}

export interface Scope {
  thingName: string
  serialNumber: string
  internalId: string | null
  modelNumber: string | null
  description: string | null
  brand: string | null
  infoFields: unknown
}

export interface LowBatteryFields {
  battery_percentage: number
}

export interface LatchAccessFields {
  latch: string,
  access_method: LatchMethod,
  access_value: string,
}

export interface InterConnectLockTransactionLogEvent {
  id: uuid
  timestamp: string // when the event happened
  eventType: TRANSACTION_LOG_ACTION
  eventData: Optional<LowBatteryFields|LatchAccessFields>
  device: Lock
  lockUser: LockUserLite
}

export interface CabinetSensorReadingEvent {
  id: uuid
  thingName: string
  receivedAt: string // when the event was received on the IoT platform
  timestamp: string // when the maintenance event happened
  samplingDuration: number
  averageTemperature: number | null
  temperatureSampleCount: number | null
  averageHumidity: number | null
  humiditySampleCount: number | null
  device: Omit<Device, "cabinetSettings" | "client">
}

export interface CabinetMaintenanceLogEvent {
  id: uuid
  thingName: string
  receivedAt: string // when the event was received on the IoT platform
  timestamp: string // when the maintenance event happened
  action: string
  user: string
  oldValue: Optional<string>
  newValue: Optional<string>
  device: Omit<Device, "cabinetSettings" | "client">
}

export const MAINTENANCE_ACTION_KEYS: { [key: string]: string } = {
  dryingTimeChanged: 'Drying time changed',
  ventingTimeChanged: 'Venting time changed',
  expirationTimeChanged: 'Expiration time changed',
  ventIntervalChanged: 'Venting interval changed',
  cabinetHoseReset: 'Manifold tube replaced',
  cabinetFilterReset: 'Compressor filter replaced',
  cabinetHoseExpired: 'Manifold tube expired',
  cabinetFilterExpired: 'Compressor filter expired',
  adminPinChanged: 'Admin PIN changed',
}

export interface ScopeSummaryTotals {
  [key: string]: number
}

export interface ScopeTrackingFilters {
  actions: string[]
}

export enum DeviceType {
  LOCK = 'lock',
  CABINET = 'cabinet',
  DRYINGUNIT = 'tabletop',
  LEAKTESTER = 'leaktester',
}

export enum LatchMethod {
  PIN = 'PIN',
  RFID = 'RFID',
  PIN_AND_RFID = 'PIN and RFID',
  PIN_OR_RFID = 'PIN or RFID',
}

export enum LATCH_CAPABILITY {
  LOCK = 'Lock',
  DUAL_LOCK = 'Dual_Lock',
}

export enum LOCK_GROUP {
  PACE = 'Pace',
  ROAM = 'Roam',
}

export function determineDeviceType(deviceType: string): DeviceType {
  if (deviceType.startsWith('cabinet')) {
    return DeviceType.CABINET
  } else {
    return DeviceType.LOCK
  }
}

export class DeviceController {
  constructor(private api: API, private path: string = 'devices') {}

  async list(
    offset: number,
    limit: number,
    _order: OrderDescription[] = [],
    clientIds: string[] = [],
    deviceTypes: DeviceType[] = [],
    query: (FilterStatement|FilterOp)[] = [],
    useCache = true,
  ): Promise<PaginatedResponse<Device>> {
    const fallback = { field: 'device.created_at', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const orderBy = formatOrderDescription(order)
    const cacheControl = useCache ? {} : { 'Cache-Control': 'no-cache' }

    const clientIdQuery = getFilterQueryFromFilters({ clientIds }, FilterType)
    const modelFilter = JSON.stringify(query.concat(clientIdQuery))

    const response = await this.api.authenticated.get<PaginatedResponse<Device>>(`${this.path}`, {
      params: { limit, offset, orderBy, types: deviceTypes, modelFilter },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      headers: {
        ...cacheControl,
      },
    })

    // Determine if their subscription has expired or is about to. Then add a property to this row so we know to highlight it
    const results = response.data.items
    for (const device of results) {
      device.passedDue = isPassedDue(device.subscriptionStart, device.subscriptionEnd)
    }

    return response.data
  }

  async get(deviceId: uuid): Promise<Lock|Cabinet> {
    const response = await this.api.authenticated.get<Lock|Cabinet>(`${this.path}/${deviceId}`)
    return response.data
  }

  async updateSubscription(deviceId: uuid, deviceSubscription: ClientSubscription): Promise<Device> {
    const response = await this.api.authenticated.put<Device>(`${this.path}/${deviceId}/subscription`, deviceSubscription)
    return response.data
  }

  async updateInfo(deviceId: uuid, deviceUpdate: DeviceUpdate): Promise<Device> {
    const response = await this.api.authenticated.put<Device>(`${this.path}/${deviceId}`, deviceUpdate)
    return response.data
  }

  async checkPairingCode(code: string): Promise<IoTThing> {
    const response = await this.api.authenticated.get<IoTThing>(`${this.path}/pair/${code}`)
    return response.data
  }

  async pairDevice(pairingPin: string, pairingRequest: DeviceUpdate): Promise<Device> {
    const response = await this.api.authenticated.post<Device>(`${this.path}/pair/${pairingPin}`, pairingRequest)
    return response.data
  }

  async changeClientLink(deviceId: uuid, clientLinkUpdate: ClientLinkUpdate): Promise<ClientLinkUpdateResponse> {
    const response = await this.api.authenticated.put<ClientLinkUpdateResponse>(`${this.path}/${deviceId}/change_client`, clientLinkUpdate)
    return response.data
  }

  async getDeviceTypeaheadOptions(clientIds: string[] = [], types=[DeviceType.CABINET]): Promise<SharedDeviceFilters> {
    const response = await this.api.authenticated.get<SharedDeviceFilters>(`${this.path}/typeahead`, {
      params: { client_id: clientIds, types: types },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getCabinetTransactions(
    offset: number,
    limit: number,
    _order: OrderDescription[] = [],
    clientIds: string[] = [],
    deviceIds: string[] = [],
    sharedDeviceFilters?: SharedDeviceFilters,
    scopeActivityFilters?: ScopeActivityFilters,
  ): Promise<PaginatedResponse<CabinetTransaction>> {
    const fallback = { field: 'cabinet_transaction.timestamp', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)
    const response = await this.api.authenticated.get<PaginatedResponse<CabinetTransaction>>(`${this.path}/cabinet_transactions`, {
      params: { limit, offset, order_by, client_id: clientIds, device_id: deviceIds, ...sharedDeviceFilters, ...scopeActivityFilters },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    return response.data
  }

  async getCabinetTransactionTypeaheadOptions(clientIds: string[] = []): Promise<CabinetTransactionFilterOptions> {
    const response = await this.api.authenticated.get<CabinetTransactionFilterOptions>(`${this.path}/cabinet_transactions/typeahead`, {
      params: { client_id: clientIds },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getInterConnectLockTransactionLogOptions(clientIds: string[] = []): Promise<InterConnectLockTransactionLogFilterOptions> {
    const response = await this.api.authenticated.get<InterConnectLockTransactionLogFilterOptions>(`/lock_users/typeahead`, {
      params: { clientIds },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getLatestScopeTransactions(
    offset: number,
    limit: number,
    _order: OrderDescription[] = [],
    deviceIds: string[] = [],
    clientIds: string[] = [],
    scopeTrackingFilters?: ScopeTrackingFilters,
  ): Promise<PaginatedResponse<CabinetTransaction>> {
    const fallback = { field: 'cabinet_transaction.timestamp', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)

    const response = await this.api.authenticated.get<PaginatedResponse<CabinetTransaction>>(`${this.path}/cabinet_transactions/latest_per_scope`, {
      params: { limit, offset, order_by, device_id: deviceIds, client_id: clientIds, ...scopeTrackingFilters },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getLatestScopeTotals(clientIds: string[] = [], deviceIds: string[] = []): Promise<ScopeSummaryTotals> {
    const response = await api.authenticated.get<ScopeSummaryTotals>(`devices/cabinet_transactions/latest_scope_totals`, {
      params: { client_id: clientIds, device_id: deviceIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getCabinetMaintenanceEvents(
    offset: number,
    limit: number,
    _order: OrderDescription[] = [],
    deviceIds: string[] = [],
    clientIds: string[] = [],
    sharedDeviceFilters?: SharedDeviceFilters,
    maintenanceLogFilters?: MaintenanceLogFilters,
  ): Promise<PaginatedResponse<CabinetMaintenanceLogEvent>> {
    const fallback = { field: 'cabinet_maintenance.timestamp', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const order_by = formatOrderDescription(order)

    const response = await this.api.authenticated.get<PaginatedResponse<CabinetMaintenanceLogEvent>>(`${this.path}/cabinet_maintenance`, {
      params: { limit, offset, order_by, device_ids: deviceIds, client_ids: clientIds, ...sharedDeviceFilters, ...maintenanceLogFilters },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getCabinetSensorReadingEvents(
    offset: number,
    limit: number,
    _order: OrderDescription[] = [],
    deviceIds: string[] = [],
    clientIds: string[] = [],
    sharedDeviceFilters?: SharedDeviceFilters,
    sensorReadingsFilters?: SensorReadingsFilters,
  ): Promise<PaginatedResponse<CabinetSensorReadingEvent>> {
    const fallback = { field: 'cabinet_sensor_reading.timestamp', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const orderBy = formatOrderDescription(order)
    const filters = {
      ...sharedDeviceFilters,
      ...sensorReadingsFilters,
      deviceIds: deviceIds,
      clientIds: clientIds,
    }

    const query = JSON.stringify(getFilterQueryFromFilters(filters, FilterType))
    const response = await this.api.authenticated.get<PaginatedResponse<CabinetSensorReadingEvent>>(`${this.path}/cabinet_sensor_readings`, {
      params: { limit, offset, orderBy, modelFilter: query },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }

  async getInterConnectLockTransactionLogEvents(
    offset: number,
    limit: number,
    _order: OrderDescription[] = [],
    deviceIds: string[] = [],
    clientIds: string[] = [],
    sharedDeviceFilters?: SharedDeviceFilters,
    transactionLogFilters?: TransactionLogFilters,
  ): Promise<PaginatedResponse<InterConnectLockTransactionLogEvent>> {
    const fallback = { field: 'lock_transaction.timestamp', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const orderBy = formatOrderDescription(order)
    const filters = {
      ...sharedDeviceFilters,
      ...transactionLogFilters,
      deviceIds: deviceIds,
      clientIds: clientIds,
    }

    const query = JSON.stringify(getFilterQueryFromFilters(filters, FilterType))
    const response = await this.api.authenticated.get<PaginatedResponse<InterConnectLockTransactionLogEvent>>(`${this.path}/lock_transactions`, {
      params: { limit, offset, orderBy, modelFilter: query },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response.data
  }
}

export default new DeviceController(instance)
