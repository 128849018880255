import { Module } from "vuex";
import { RootState } from "@/store";
import {
  namespaced, TABLE_ACTIONS,
  TABLE_GETTERS,
  tableActions, tableGetters,
  tableMutations,
  TableState,
  tableState
} from "@/store/mixins/table";
import parts, { Part } from "@/api/parts";
import { PaginatedResponse } from "@/api";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PartsListState extends TableState<Part> {
  // Put any module specific things in here
}

export const namespace = `partsList`
export const ACTIONS = namespaced(TABLE_ACTIONS, namespace)
export const GETTERS = namespaced(TABLE_GETTERS, namespace)

export const module: Module<PartsListState, RootState> = {
  namespaced: true,
  state: tableState<Part, PartsListState>(),
  getters: tableGetters<Part, PartsListState>(),
  mutations: tableMutations<Part, PartsListState>(),
  actions: tableActions<Part, PartsListState>({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }, paged=true): Promise<PaginatedResponse<Part>> => {
      const offset = paged ? state.offset : 0
      const limit = paged ? state.limit : -1
      return await parts.list(offset, limit)
    },
  }),
}
