


































































import { Vue, Component } from "vue-property-decorator"
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import Wizard from '@/components/Wizard.vue'
import Page from '@/components/WizardPage.vue'
import Loading from '@/components/Loading.vue'
import FormWrapper from '@/components/forms/FormWrapper.vue'
import { ACTIONS } from '@/store/modules/device-management'
import { ClientSubscription } from '@/api/devices'
import Toggle from '@/components/Toggle.vue'
import DatepickerWrapper from '@/components/forms/DatepickerWrapper.vue'

@Component({
  components: {
    Wizard,
    Page,
    SingleselectWrapper,
    MultiselectWrapper,
    Loading,
    FormWrapper,
    Toggle,
    DatepickerWrapper
  }
})
export default class ClientDeviceSubscriptionWizard extends Vue {
  ACTIONS = ACTIONS

  errorMessage = ''
  deviceId = ''

  loading = true

  modelProxy: ClientSubscription = {
    subscriptionStart: '',
    subscriptionEnd: '',
    subscriptionPaid: '',
    subscriptionActive: true,
  }

  async mounted(): Promise<void> {
    this.loading = true
    this.deviceId = this.$route.query.device as string

    // Load the specific device info
    try {
      const device = await this.$store.dispatch(this.ACTIONS.GET, this.deviceId)

      if (!isNaN(parseFloat(device.subscriptionPaid ?? ''))) {
        device.subscriptionPaid = this.convertToPrice(device.subscriptionPaid)
      } else {
        device.subscriptionPaid = null
      }

      this.modelProxy = {
        subscriptionStart: device.subscriptionStart,
        subscriptionEnd: device.subscriptionEnd,
        subscriptionPaid: device.subscriptionPaid,
        subscriptionActive: device.subscriptionActive,
      }

      this.loading = false
    } catch (error) {
      this.loading = false
      this.errorMessage = error.response?.data.description
    }
  }

  formatSubscriptionPaid(): void {
    if (isNaN(Number(this.modelProxy.subscriptionPaid))) {
      return
    }

    this.modelProxy.subscriptionPaid = this.convertToPrice(this.modelProxy.subscriptionPaid)
  }

  convertToPrice(price: string|null): string {
    if (price == null || price.length === 0) {
      price = '0.00'
    }

    return parseFloat(price).toFixed(2)
  }

  endDateChanged(date: string): void {
    this.modelProxy.subscriptionEnd = date
  }

  startDateChanged(date: string): void {
    this.modelProxy.subscriptionStart = date
  }

  submit(): void {
    this.loading = true

    if (isNaN(parseFloat(this.modelProxy.subscriptionPaid ?? ''))) {
      this.modelProxy.subscriptionPaid = null
    }

    this.$store.dispatch(this.ACTIONS.UPDATE_SUBSCRIPTION, { deviceId: this.deviceId, deviceSubscription: this.modelProxy })
      .then(() => { this.$parent.$emit('close') })
      .catch((error) => {
        this.errorMessage = error.response?.data.description

        this.loading = false
      })
  }
}
