



















import { Component, Prop, Vue } from "vue-property-decorator"
import { uuid } from "@/api/models"
import CloseIcon from '@/assets/img/close-icon.svg'
import { ACTIONS } from '@/store/modules/notification-alerts'

@Component({
  components: {
    CloseIcon,
  },
})
export default class NotificationMenuItem extends Vue {
  @Prop({ required: true }) itemId!: uuid

  clearItem(): void {
    this.$store.dispatch(ACTIONS.DELETE_NOTIFICATION, this.itemId)
  }
}
