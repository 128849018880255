// https://stackoverflow.com/questions/41179474/use-object-literal-as-typescript-enum-values
export class SCOPE_STATUS {
  static readonly READY = new SCOPE_STATUS('Dry Complete', 'Ready')
  static readonly DRYING = new SCOPE_STATUS('Dry Start', 'Drying')
  static readonly CHECKED_OUT = new SCOPE_STATUS('Check Out', 'Checked Out')
  static readonly EXPIRED = new SCOPE_STATUS('Expired', 'Expired')
  static readonly QUEUED = new SCOPE_STATUS('Check In', 'Queued')

  // private to disallow creating other instances of this type
  private constructor(private readonly action: string, public readonly status: string) {}

  toString(): string {
    return this.action;
  }

  static readonly ALL_ACTIONS = [
    SCOPE_STATUS.READY.action,
    SCOPE_STATUS.DRYING.action,
    SCOPE_STATUS.CHECKED_OUT.action,
    SCOPE_STATUS.EXPIRED.action,
    SCOPE_STATUS.QUEUED.action,
  ]

  static readonly ALL_STATUSES = [
    SCOPE_STATUS.READY.status,
    SCOPE_STATUS.DRYING.status,
    SCOPE_STATUS.CHECKED_OUT.status,
    SCOPE_STATUS.EXPIRED.status,
    SCOPE_STATUS.QUEUED.status,
  ]

  static mapActionToStatusString(action: string): string {
    const idx = SCOPE_STATUS.ALL_ACTIONS.indexOf(action)
    if (idx >= 0) {
      return SCOPE_STATUS.ALL_STATUSES[idx]
    } else {
      return 'Unknown'
    }
  }
}
