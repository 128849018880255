












import { Component, Vue } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import Modal from '@/components/Modal.vue'
import DeviceWizard from '@/components/forms/DeviceWizard.vue'
import DevicesTable from '@/views/manage-devices/tables/DevicesTable.vue'
import { Auth } from '@aws-amplify/auth'

@Component({
  components: {
    DeviceWizard,
    PageBannerHeader,
    Modal,
    DevicesTable
  }
})
export default class ManageScopes extends Vue {
  url = process.env.VUE_APP_API_SERVER_URL
  token = ""
  clientIds: string[] = []
  proxyIds: string[] = []
  loading = true;

  async mounted(): Promise<void> {
    const session = await Auth.currentSession();
    this.token = await session.getIdToken().getJwtToken();
    this.loading = false;
  }

  get proxyId(): string{
    return this.$router.currentRoute.query?.proxyId as string;
  }

  // This assumes that the given user will only ever have one
  // client. In the case of a user having multiple clients, this
  // will select the first client in the store client array arbitrarily
  get clientId(): string {
    let clientId = "";
    try {
      clientId = this.$store.getters.user.clients[0].id as string;
    } catch (error) {
      return clientId;
    }
    return clientId;
  }

  get userType(): string {
    return this.$store.getters.userType as string;
  }
}
