
































import { Component, Prop, Vue } from "vue-property-decorator"
import CabinetIcon from "@/assets/img/device-cabinet-icon.svg"
import { Notification } from "@/api/notifications"
import NotificationMenuItem from '@/components/nav-bar/notifications/NotificationMenuItem.vue'
import { ExpiredEvents, ReminderEvents } from '@/api/models'
import WarningIcon from '@/assets/img/warning-icon.svg'
import ExpiredIcon from '@/assets/img/error.svg'

@Component({
  components: {
    NotificationMenuItem,
    CabinetIcon,
    ExpiredIcon,
    WarningIcon,
  },
})
export default class CabinetMaintenanceAlertNotification extends Vue {
  @Prop({ required: true }) maintenanceAlert!: Notification

  get expiredEventNames(): string[] {
    return Object.values(ExpiredEvents)
  }

  get reminderEventNames(): string[] {
    return Object.values(ReminderEvents)
  }
}
