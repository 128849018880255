




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Loading extends Vue {
  @Prop({ default: 'large' }) size!: 'large'|'small'|'icon'
}
