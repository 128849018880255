





































import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import NavBarPopup from '@/components/nav-bar/NavBarPopup'
import { ACTIONS, GETTERS } from '@/store/modules/notification-alerts'
import { Notification } from '@/api/notifications'
import MenuPopup from '@/components/nav-bar/MenuPopup.vue'
import ExitClientViewButton from '@/components/ExitClientViewButton.vue'
import RoleBasedView from '@/views/RoleBasedView'
import CabinetMaintenanceNotification from '@/components/nav-bar/notifications/CabinetMaintenanceNotification.vue'
import CabinetTransactionNotification from '@/components/nav-bar/notifications/CabinetTransactionNotification.vue'
import LockTransactionNotification from '@/components/nav-bar/notifications/LockTransactionNotification.vue'
import CloseIcon from '@/assets/img/close-icon.svg'
import CabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import LockIcon from '@/assets/img/inter-connect-lock-icon.svg'

// 5 minutes
const NOTIFICATION_DELAY = 300000

@Component({
  components: {
    MenuPopup,
    CabinetMaintenanceNotification,
    CabinetTransactionNotification,
    LockTransactionNotification,
    ExitClientViewButton,
    CloseIcon,
    CabinetIcon,
    LockIcon,
  },
})
export default class NotificationMenu extends mixins(NavBarPopup, RoleBasedView) {
  items: Notification[] = []

  clearItem(item: Notification): void {
    this.$store.dispatch(ACTIONS.DELETE_NOTIFICATION, item.id)
  }

  clearAll(): void {
    this.$store.dispatch(ACTIONS.DELETE_ALL_NOTIFICATIONS, this.$store.getters[GETTERS.LATEST_EVENT])
  }

  async mounted(): Promise<void> {
    try {
      await this.loadNotifications()

    } catch (error) {
      console.error(error)
    }
  }

  async loadNotifications(): Promise<void> {
    await this.$store.dispatch(ACTIONS.LOAD_NOTIFICATIONS)
    this.items = this.$store.getters[GETTERS.NOTIFICATIONS]
    this.items.forEach((item: Notification) => item.viewedLocally = item.seen)

    setTimeout(this.loadNotifications, NOTIFICATION_DELAY)
  }
}
