import { ActionTree, GetterTree, MutationTree } from "vuex"
import { OrderDescription, PaginatedResponse } from "@/api"
import { LoadingStatus, PageMode } from "@/store/types"
import { RootState } from "@/store"
import { uuid } from '@/api/models'

// Modules that extend from TableState must be namespaced to avoid name collisions.
export interface TableState<Item> {
  status: LoadingStatus
  results: Item[]

  offset: number
  limit: number
  total: number
  pageMode: PageMode

  order: OrderDescription[]

  filterIds: uuid[]
  useCache: boolean
}

export enum TABLE_GETTERS {
  HAS_ADDITIONAL_PAGES = 'HAS_ADDITIONAL_PAGES',
  ORDER = 'ORDER',
  RESULTS = 'RESULTS',
  STATUS = 'STATUS',
  TOTAL = 'TOTAL',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
  CONTENT_DESCRIPTION = 'CONTENT_DESCRIPTION',
}

export enum TABLE_MUTATIONS {
  SET_STATUS = 'SET_STATUS',
  ORDER_BY = 'ORDER_BY',
  SET_PAGE = 'SET_PAGE',
  INCREMENT_PAGE = 'INCREMENT_PAGE',
  REPLACE_RESULTS = 'REPLACE_RESULTS',
  EXTEND_RESULTS = 'EXTEND_RESULTS',
  SET_TOTAL = 'SET_TOTAL',
  REPLACE_RESULT = 'REPLACE_RESULT',
  REMOVE_RESULT = 'REMOVE_RESULT',
  CLEAR = 'CLEAR',
  SET_FILTER_IDS = 'SET_FILTER_IDS',
  SET_USE_CACHE = 'SET_USE_CACHE',
}


export enum TABLE_ACTIONS {
  LOAD_PAGE = 'LOAD_PAGE',
  LOAD_NEXT_PAGE = 'LOAD_NEXT_PAGE',
  ORDER_BY = 'ORDER_BY',
  FETCH_LIST = 'FETCH_LIST',
  CLEAR = 'CLEAR',

  CREATE = 'CREATE',
  GET = 'GET',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

// This function generates an array of keys from an enum that we can use later
// to reassemble another enum.
//
// from - https://www.petermorlion.com/iterating-a-typescript-enum/
//
// eslint-disable-next-line @typescript-eslint/ban-types
function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

// Just don't look at it... Please
//
// OK in reality what we are doing here is providing a function that modules
// can use to generate an enum where the cases are correct, but the values
// have been modified to include the provided namespace. We need to do this
// because namespaced modules register their mutations/actions/getters without
// the namespace prefix, but we need to commit/dispatch them using the namespace
// to disambiguate
//
// eslint-disable-next-line @typescript-eslint/ban-types
export function namespaced<EnumType extends object>(type: EnumType, namespace: string): EnumType {
  const newEnum = Object.fromEntries(
    enumKeys(type).map(k => [k, `${namespace}/${type[k]}`])
  )

  // Cool people don't look at explosions 😎
  return newEnum as unknown as EnumType
}

export function tableState<Item, State extends TableState<Item>>(override?: Partial<State>, useCache = true): State {
  const standard = {
    status: 'loading',
    results: [] as Item[],
    offset: 0,
    limit: 25,
    total: 0,
    pageMode: 'extend',
    order: [] as OrderDescription[],
    filterIds: [] as uuid[],
    useCache: useCache,
  }

  if (override) {
    return Object.assign(standard, override) as State
  } else {
    return standard as State
  }
}

export function tableGetters<Item, State extends TableState<Item>>(override?: GetterTree<State, RootState>): GetterTree<State, RootState> {
  const standard = {
    [TABLE_GETTERS.ORDER]: (state: State): OrderDescription[] => {
      return state.order
    },

    [TABLE_GETTERS.HAS_ADDITIONAL_PAGES]: (state: State): boolean => {
      return state.offset + state.limit < state.total
    },

    [TABLE_GETTERS.STATUS]: (state: State): LoadingStatus => {
      return state.status
    },

    [TABLE_GETTERS.RESULTS]: (state: State): Item[] => {
      return state.results
    },

    [TABLE_GETTERS.TOTAL]: (state: State): number => {
      return state.total
    },

    [TABLE_GETTERS.ERROR_MESSAGE]: (): string => {
      return 'Something went wrong... Please try again.'
    },

    [TABLE_GETTERS.CONTENT_DESCRIPTION]: (state: State): string => {
      if (state.pageMode === 'extend') {
        return `Viewing ${state.results.length} of ${state.total}`
      } else {
        return `Viewing ${state.offset + 1} - ${state.offset + state.results.length} of ${state.total}`
      }
    },
  }

  return {
    ...standard,
    ...override
  }
}

export function tableMutations<Item, State extends TableState<Item>>(override?: MutationTree<State>): MutationTree<State> {
  const standard = {
    [TABLE_MUTATIONS.SET_STATUS]: (state: State, status: LoadingStatus) => {
      state.status = status
    },

    [TABLE_MUTATIONS.ORDER_BY]: (state: State, field: string) => {
      const match = state.order.find(element => element.field === field)

      if (!match) {
        state.order.push({ field, direction: 'asc' } as OrderDescription)
      } else if (match.direction === 'asc') {
        match.direction = 'desc'
      } else {
        state.order = state.order.filter(element => element.field !== field)
      }
    },

    [TABLE_MUTATIONS.SET_PAGE]: (state: State, page: number) => {
      state.offset = state.limit * page
    },

    [TABLE_MUTATIONS.INCREMENT_PAGE]: (state: State) => {
      state.offset += state.limit
    },

    [TABLE_MUTATIONS.REPLACE_RESULTS]: (state: State, results: Item[]) => {
      state.results = results
    },

    [TABLE_MUTATIONS.EXTEND_RESULTS]: (state: State, results: Item[]) => {
      state.results = state.results.concat(results)
    },

    [TABLE_MUTATIONS.SET_TOTAL]: (state: State, total: number) => {
      state.total = total
    },

    [TABLE_MUTATIONS.CLEAR]: (state: State, clearState: State) => {
      Object.assign(state, tableState(clearState, state.useCache))
    },

    [TABLE_MUTATIONS.SET_FILTER_IDS]: (state: State, filterIds: uuid[]) => {
      state.filterIds = filterIds
    },

    [TABLE_MUTATIONS.SET_USE_CACHE]: (state: State, useCache: boolean) => {
      state.useCache = useCache
    },

  }

  return {
    ...standard,
    ...override
  }
}


export function tableActions<Item, State extends TableState<Item>>(override?: ActionTree<State, RootState>): ActionTree<State, RootState> {
  const standard: ActionTree<State, RootState> = {
    [TABLE_ACTIONS.FETCH_LIST]: async (): Promise<PaginatedResponse<Item>> => {
      const message = 'Please override this action with one that returns a paginated response of your table\'s datatype.'
      console.error(message)
      return Promise.reject(message)
    },

    [TABLE_ACTIONS.LOAD_PAGE]: async ({ state, commit, dispatch }, payload: { mode?: PageMode, ids?: string[] } = {}): Promise<void> => {
      const mode = payload.mode || state.pageMode

      if (payload.ids) {
        commit(TABLE_MUTATIONS.SET_FILTER_IDS, payload.ids)
      }

      commit(TABLE_MUTATIONS.SET_STATUS, 'loading')

      try {
        const results = await dispatch(TABLE_ACTIONS.FETCH_LIST)
        commit(TABLE_MUTATIONS.SET_STATUS, 'loaded')
        if (mode == 'replace') {
          commit(TABLE_MUTATIONS.REPLACE_RESULTS, results.items)
        } else {
          commit(TABLE_MUTATIONS.EXTEND_RESULTS, results.items)
        }

        commit(TABLE_MUTATIONS.SET_TOTAL, results.total)
      } catch (error) {
        commit(TABLE_MUTATIONS.SET_STATUS, 'error')
        return Promise.reject()
      }
    },

    [TABLE_ACTIONS.LOAD_NEXT_PAGE]: ({ commit, dispatch }, payload: { mode: PageMode|undefined } = { mode: undefined }): Promise<void> => {
      commit(TABLE_MUTATIONS.INCREMENT_PAGE)
      return dispatch(TABLE_ACTIONS.LOAD_PAGE, payload)
    },

    [TABLE_ACTIONS.ORDER_BY]: ({ commit, dispatch }, payload: { field: string }): Promise<void> => {
      commit(TABLE_MUTATIONS.ORDER_BY, payload.field)
      commit(TABLE_MUTATIONS.SET_PAGE, 0)
      return dispatch(TABLE_ACTIONS.LOAD_PAGE, { mode: 'replace' })
    },

    [TABLE_ACTIONS.CLEAR]: ({ commit }, clearState: State) => {
      commit(TABLE_MUTATIONS.CLEAR, clearState)
    },
  }

  return {
    ...standard,
    ...override
  }
}
