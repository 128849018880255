

















import {Component, Prop, Vue} from 'vue-property-decorator'
import ChartCard from '@/components/charts/ChartCard.vue'
import { BATTERY_PERCENTAGE_THRESHOLD } from '@/store/modules/reports/inter-connect-lock-reports'
import LowBatteryIcon from '@/assets/img/low-battery-icon.svg'
import NoLowBatteryIcon from '@/assets/img/no-low-battery-icon.svg'
import { PAGE_NAMES, PROXY_ID } from '@/api/misc'
import { ACTIONS, REPORT_TYPE } from '@/store/modules/reports/inter-connect-lock-reports'
import { LoadingStatus } from '@/store/types'
import { InterConnectLockTrackingChartData } from '@/store/modules/dashboard/inter-connect-lock-tracking-table'

@Component({
  components: {
    ChartCard,
    LowBatteryIcon,
    NoLowBatteryIcon,
  }
})
export default class LowBatteryChart extends Vue {
  @Prop({ required: true }) chartData!: InterConnectLockTrackingChartData
  @Prop({ required: true }) status!: LoadingStatus

  get selectedChartData(): number[] {
    return [
      this.chartData.totalLowBatteryLocks,
      this.chartData.totalLocks - this.chartData.totalLowBatteryLocks,
    ]
  }

  get issues(): number {
    return this.chartData.totalLowBatteryLocks
  }

  get description(): string {
    return this.issues > 0 ? 'need batteries replaced' : 'All devices are sufficiently charged'
  }

  viewReport(): void {
    this.$store.dispatch(ACTIONS.CLEAR_ALL)
    this.$store.dispatch(ACTIONS.UPDATE_REPORT_TYPE, REPORT_TYPE.CURRENT_SETTINGS)
    this.$store.dispatch(ACTIONS.UPDATE_LOCK_SETTINGS_FILTERS, { batteryPercentageThresholds: [BATTERY_PERCENTAGE_THRESHOLD.LESS_THAN_10] })
    this.$router.push({ name: PAGE_NAMES.SMART_LOCK_REPORTS, query: { [PROXY_ID]: this.$router.currentRoute.query.proxyId }})
  }
}
