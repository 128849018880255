import { CRUD, uuid } from './models'

export interface DepartmentFormGroup {
  client_id: uuid
  clientName: string
  departments: DepartmentForm[]
}

export interface DepartmentForm {
  id: uuid
  client_id: uuid
  name: string
}

export interface Department extends DepartmentForm, CRUD {}

// This is just a string... shhhh
export class DepartmentHack {
  name: string
  client_id: uuid

  constructor(name: string, client_id: uuid) {
    this.name = name
    this.client_id = client_id
  }

  toString(): string {
    return this.name
  }
}