



















import { Component, Vue } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import ClientSummaryCard from '@/views/ClientSummaryCard.vue'
import { Client } from '@/api/clients'
import clients from '@/api/clients'
import { LoadingStatus } from '@/store/types'
import Loading from '@/components/Loading.vue'
import { PaginatedResponse } from "@/api"

@Component({
  components: {
    PageBannerHeader,
    ClientSummaryCard,
    Loading,
  }
})
export default class ClientSummaryDashboard extends Vue {
  status: LoadingStatus = 'loading'
  clientList: PaginatedResponse<Client> = {
    items: [],
    total: 0
  }

  async mounted(): Promise<void> {
    clients.getClientSummary()
      .then((clients) => {
        this.status = 'loaded'
        this.clientList = clients
      })
      .catch(() => {
        this.status = 'error'
      })
  }
}
