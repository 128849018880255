import { Vue, Component } from "vue-property-decorator"
import { PROXY_ID } from '@/api/misc'

// RoleBasedView should be used as the parent class for any Vue component that
// wants to display results that change based on current users role
@Component
export default class RoleBasedView extends Vue {
  /**
   * Checks the logged-in user is a solaire admin
   */
  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  /**
   * Checks if user is an admin and they ARE impersonating a client
   */
  get isAdminImpersonating(): boolean {
    return this.$store.getters.isAdmin && this.$route.query[PROXY_ID]
  }

  /**
   * Checks if user is an admin and they are NOT impersonating a client
   */
  get isAdminNotImpersonating(): boolean {
    return this.$store.getters.isAdmin && !this.$route.query[PROXY_ID]
  }

  /**
   * Checks current user is a hospital admin or they have proxy ID in their URL
   *
   * Only Solaire Admins can have proxy_id in the URL so this is essentially checking
   * that a Solaire Admin impersonating and a hospital admin are treated the same
   */
  get isHospitalAdmin(): boolean {
    return this.$store.getters.isHospitalAdmin || this.$route.query[PROXY_ID]
  }

}
