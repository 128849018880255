





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import WizardPage from './WizardPage.vue'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading,
  }
})
export default class Wizard extends Vue {
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop({ default: () => [] }) disabledPageIndexes!: number[]
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: true }) showPages!: boolean

  selectedIndex = 0
  children: WizardPage[] = []

  get pages(): WizardPage[] {
    return this.children.slice(1)
  }

  get selectedPage(): WizardPage|undefined {
    return this.pages[this.selectedIndex]
  }

  get selectedTitle(): string {
    return this.selectedPage?.title || this.title
  }

  get selectedSubtitle(): string {
    return this.selectedPage?.subtitle || this.subtitle
  }

  created(): void {
    this.children = this.$children as WizardPage[]
  }

  selectPage(i: number): void {
    if (this.disabledPageIndexes.includes(i) || i < 0 || i >= this.pages.length) {
      return
    }

    this.selectedIndex = i

    // loop over all the pages
    this.pages.forEach((page, index) => {
      page.isActive = (index === i)
    })
  }

  selectNextPage(): void {
    this.selectPage(this.selectedIndex + 1)
  }

  selectPreviousPage(): void {
    this.selectPage(this.selectedIndex - 1)
  }

  mounted(): void {
    this.selectPage(0)
  }

  @Watch('pages')
  wizardPagesChanged(): void {
    // Wizard watch!
    this.selectPage(this.selectedIndex)
  }
}
