import Auth from '@aws-amplify/auth'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'


export type OrderDirection = 'asc'|'desc'

export interface PaginatedResponse<Item> {
  items: Item[]
  total: number
}

export interface OrderDescription {
  direction: OrderDirection
  field: string
}

export function formatOrderDescription(order: OrderDescription[]): string {
  return order.map(element => element.direction === 'asc' ? element.field : `-${element.field}`).join(",")
}

export class API {
  authenticated: AxiosInstance

  constructor (baseURL: string) {
    const settings = {
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    } as AxiosRequestConfig

    this.authenticated = axios.create({
      ...settings,
    })

    this.authenticated.interceptors.request.use(async config => {
      try {
        const session = await Auth.currentSession()
        const token = session.getIdToken()
        const jwt = token.getJwtToken()

        config.headers.Authorization = `Bearer ${jwt}`
        return config
      } catch {
        return config
      }
    })
  }
}


const baseURL = process.env.VUE_APP_API_URL
export default new API(baseURL)
