





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DataTable from '@/components/DataTable.vue'
import devices, { Cabinet, determineDeviceType, DeviceType, Device, Lock } from '@/api/devices'
import { OrderDescription } from '@/api'
import { TableColumn } from '@/components/table-column'
import { Client } from '@/api/clients'
import SearchIcon from '@/assets/img/search.svg'
import axios from 'axios'
import { uuid } from '@/api/models'

@Component({
  components: {
    DataTable,
    SearchIcon,
  }
})
export default class SelectDeviceTable extends Vue {
  @Prop({required: true}) value!: Device[]
  @Prop({required: true}) clients!: Client[]

  availableDevices: Device[] = []
  deviceListOrder: OrderDescription[] = []
  filterText = ''

  get deviceIds(): uuid[] {
    return this.value.map((device) => device.id)
  }
  set deviceIds(val: uuid[]) {
    this.$emit('input', val.map((id) => this.availableDevices.find((dev) => dev.id === id)))
  }

  deviceSorted(field: string): void {
    const match = this.deviceListOrder.find(element => element.field === field)
    if (!match) {
      this.deviceListOrder.push({ field, direction: 'asc' } as OrderDescription)
    } else if (match.direction === 'asc') {
      match.direction = 'desc'
    } else {
      this.deviceListOrder = this.deviceListOrder.filter(element => element.field !== field)
    }
    this.loadDevices()
  }

  @Watch('clients', { immediate: true })
  async loadDevices(): Promise<void> {
    const clientIds = this.clients.map((client) => client.id)
    try {
      this.availableDevices = (await devices.list(
        0,
        -1,
        this.deviceListOrder,
        clientIds,
        [DeviceType.CABINET, DeviceType.LOCK]
      )).items
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.$emit('error', err.response?.data.description)
      }
    }

    // if the client list shrinks, then remove selected devices that aren't real options anymore
    this.$emit('input', this.value.filter((dev) => clientIds.includes(dev.client.id)))
  }

  get filteredDevices(): Device[] {
    return this.availableDevices?.filter(availableDevices => availableDevices.name.toUpperCase().includes(this.filterText.toUpperCase()))
  }

  get columns(): TableColumn<Cabinet | Lock>[] {
    return [
      {
        id: 'select',
        title: 'Select',
        sticky: true,
        cellClass: 'selectCheckbox'
      },
      {
        id: 'deviceName',
        title: 'Device Name',
        field: 'device.name',
        value: (row) => row.name,
        headerColumn: true,
        headerClass: 'columnLarge',
      },
      {
        id: 'deviceType',
        title: 'Type',
        field: 'device.aws_thing_type',
        value: (row) => {
          const deviceType = determineDeviceType(row.awsThingType)
          if (deviceType === DeviceType.CABINET) {
            return 'Cabinet'
          } else if (deviceType === DeviceType.LOCK) {
            return 'Lock'
          } else {
            return 'Unknown'
          }
        },
        headerClass: 'columnMedium'
      },
      {
        id: 'department',
        title: 'Department',
        field: 'department.name',
        value: (row) => row.department.name,
        headerClass: 'columnMedium'
      },
      {
        id: 'location',
        title: 'Location',
        field: 'device.location',
        value: (row) => row.location ?? '',
        headerClass: 'columnMedium'
      },
      {
        id: 'contact',
        title: 'Contact',
        field: 'device.contact',
        value: (row) => row.contact ?? '',
        headerClass: 'columnMedium'
      },
      {
        id: 'notes',
        title: 'Notes',
        field: 'device.note',
        value: (row) => row.note ?? '',
        headerClass: 'columnMedium'
      }
    ]
  }
}
