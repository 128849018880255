




import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class EmailLink extends Vue {
  @Prop({ required: true }) email!: string
}
