






















import { Component } from 'vue-property-decorator'
import { GETTERS as CART_GETTERS } from '@/store/modules/parts/cart'
import { PAGE_NAMES } from '@/api/misc'
import { PAGE_VIEW } from '@/views/order-parts/OrderParts.vue'
import NavBarPopup from '@/components/nav-bar/NavBarPopup'
import { getPriceFromString } from '@/util'
import MenuPopup from '@/components/nav-bar/MenuPopup.vue'

@Component({
  components: {
    MenuPopup,
  }
})
export default class Cart extends NavBarPopup {

  get totalPartCount(): number {
    return this.$store.getters[CART_GETTERS.TOTAL_PART_COUNT]
  }

  get displayPartCount(): string {
    return this.$store.getters[CART_GETTERS.TOTAL_PART_COUNT].toLocaleString()
  }

  get displayPriceEstimate(): string {
    return getPriceFromString(this.$store.getters[CART_GETTERS.TOTAL_PRICE_ESTIMATE])
  }

  get routeIsOrderParts(): boolean {
    return this.$route.name === PAGE_NAMES.ORDER_PARTS
  }

  get onCheckoutScreen(): boolean {
    return this.$route.query.view === PAGE_VIEW.CompleteOrder
  }

  goToOrderParts(): void {
    this.$router.push({ name: PAGE_NAMES.ORDER_PARTS, query: { ...this.$router.currentRoute.query }})
  }

  goToCheckOut(): void {
    this.$router.push({ name: PAGE_NAMES.ORDER_PARTS, query: { ...this.$router.currentRoute.query, view: PAGE_VIEW.CompleteOrder } })
  }
}
