




































import { Component, Watch } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import PasswordForm from '@/components/forms/PasswordForm.vue'
import { User } from '@/api/users'
import { uuid } from '@/api/models'
import { Department } from '@/api/departments'
import { USER_ACTIONS } from '@/store/modules/user'
import Loading from '@/components/Loading.vue'
import LogoutIcon from '@/assets/img/log-out.svg'
import NavBarPopup from '@/components/nav-bar/NavBarPopup'
import MenuPopup from '@/components/nav-bar/MenuPopup.vue'

@Component({
  components: {
    MenuPopup,
    Loading,
    Modal,
    PasswordForm,
    LogoutIcon
  }
})
export default class UserMenu extends NavBarPopup {
  MODAL_TYPE = MODAL_TYPE
  firstName = ''
  lastName = ''
  clientDepartmentMap = new Map<uuid, Department[]>()
  loading = false

  get user(): User {
    return this.$store.getters.user
  }

  get isFormDirty(): boolean {
    return this.firstName !== this.user.first_name || this.lastName !== this.user.last_name
  }

  @Watch('user', { immediate: true })
  userChanged(newUser: User | null): void {
    this.firstName = newUser?.first_name ?? ''
    this.lastName = newUser?.last_name ?? ''
    newUser?.clients.forEach(client => {
      const departments: Department[] = []
      newUser?.departments.forEach(department => {
        if (department.client_id == client.id) {
          departments.push(department)
        }
      })
      this.clientDepartmentMap.set(client.id, departments)
    })
  }

  changePassword(): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.CHANGE_PASSWORD })
  }

  async saveChanges(): Promise<void> {
    this.loading = true
    try {
      await this.$store.dispatch(USER_ACTIONS.UPDATE_NAME, { firstName: this.firstName, lastName: this.lastName })
    } catch (error) {
      console.error('Unable to update user information: ', error)
    }
    this.loading = false
  }

  async signOut(): Promise<void> {
    try {
      await this.$store.dispatch(USER_ACTIONS.SIGN_OUT)
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }
}
