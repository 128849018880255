import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { module as dryingUnitReports, namespace as dryingUnitReportsNamespace } from './drying-unit-reports'
import { module as InterConnectLockReports, namespace as InterConnectLockReportsNamespace } from './inter-connect-lock-reports'

export interface DeviceFilters {
  deviceIds?: string[]
  lastUpdatedStart?: Date
  lastUpdatedEnd?: Date
}

export interface SharedDeviceFilters {
  deviceNames: string[]
  deviceSerialNumbers: string[]
  departmentNames: string[]
  locations: string[]
  contacts: string[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ReportsState {}

export const namespace = 'reports'

export const module: Module<ReportsState, RootState> = {
  namespaced: false,
  state: {},
  getters: {} as GetterTree<ReportsState, RootState>,
  mutations: {} as MutationTree<ReportsState>,
  actions: {} as ActionTree<ReportsState, RootState>,
  modules: {
    [dryingUnitReportsNamespace]: dryingUnitReports,
    [InterConnectLockReportsNamespace]: InterConnectLockReports,
  } as ModuleTree<RootState>
}
