




import { Component, Vue } from 'vue-property-decorator'
import DevicesTable from '@/views/manage-devices/tables/DevicesTable.vue'
import { DeviceType } from '@/api/devices'

@Component({
  components: {
    DevicesTable,
  }
})
export default class CabinetDevices extends Vue {
  DeviceType = DeviceType
}
