
















import { Component, Prop, Vue } from "vue-property-decorator"
import CabinetIcon from "@/assets/img/device-cabinet-icon.svg"
import { Notification } from "@/api/notifications"
import NotificationMenuItem from '@/components/nav-bar/notifications/NotificationMenuItem.vue'


@Component({
  components: {
    CabinetIcon,
    NotificationMenuItem,
  },
})
export default class CabinetTransactionNotification extends Vue {
  @Prop({ required: true }) transactionAlert!: Notification
}
