







import { Component, Vue } from 'vue-property-decorator'
import InformationForm from '@/views/order-parts/complete-order/InformationForm.vue'
import OrderSummary from '@/views/order-parts/complete-order/OrderSummary.vue'

@Component({
  components: {
    InformationForm,
    OrderSummary,
  }
})
export default class CompleteOrder extends Vue {}
