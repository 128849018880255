import instance, { API, formatOrderDescription, OrderDescription, OrderDirection, PaginatedResponse } from '.'
import { uuid } from './models'
import qs from 'qs'
import { Device } from '@/api/devices'
import { Optional } from '@/util'
import { Client } from '@/api/clients'
import { Department } from '@/api/departments'
import { AxiosResponse } from 'axios'

export interface LockUserLite {
  id: uuid
  name: string
}

export interface LockUser extends LockUserLite {
  clientId: uuid
  departmentId: uuid
  client: Optional<Client>
  department: Optional<Department>
  pin: Optional<string>
  rfid: Optional<string>
  email: string
}

export interface LockDeviceAccessGrant {
  device: Device
  isAdmin: boolean
  latch1Access: boolean
  latch2Access: boolean
}

export interface LockDeviceAccessGrantForm {
  deviceId: uuid
  isAdmin: boolean
  latch1Access: boolean
  latch2Access: boolean
}

export class LockUserController {
  constructor(private api: API, private path: string = 'lock_users') {}

  async create(newLockUser: LockUser): Promise<LockUser> {
    const response = await this.api.authenticated.post<LockUser>(this.path, newLockUser)
    return response.data
  }

  async list(offset: number, limit: number, _order: OrderDescription[], proxyIds: string[] = []): Promise<PaginatedResponse<LockUser>> {
    const fallback = { field: 'lock_user.id', direction: 'desc' as OrderDirection }
    const order = [..._order, fallback]
    const orderBy = formatOrderDescription(order)
    const clientIds = proxyIds
    const response = await this.api.authenticated.get<PaginatedResponse<LockUser>>(this.path, {
      params: { limit, offset, orderBy, clientIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })

    return response.data
  }

  async get(lockUserId: uuid): Promise<LockUser> {
    const response = await this.api.authenticated.get<LockUser>(`${this.path}/${lockUserId}`)
    return response.data
  }

  async getLockAssociations(lockUserId: uuid): Promise<PaginatedResponse<LockDeviceAccessGrant>> {
    const response = await this.api.authenticated.get<PaginatedResponse<LockDeviceAccessGrant>>(`${this.path}/${lockUserId}/device_access`)
    return response.data
  }

  async update(lockUser: LockUser): Promise<LockUser> {
    const response = await this.api.authenticated.put<LockUser>(`${this.path}/${lockUser.id}`, lockUser)
    return response.data
  }

  async delete(lockUserId: uuid): Promise<unknown> {
    const response = await this.api.authenticated.delete<unknown>(`${this.path}/${lockUserId}`)
    return response.data
  }

  async pair(lockUserId: uuid, lockDeviceGrants: LockDeviceAccessGrant[]): Promise<unknown> {
    const request = {
      grants: lockDeviceGrants
    }

    const response = await this.api.authenticated.post<unknown>(`${this.path}/${lockUserId}/device_access`, request)
    return response.data
  }

  async unpair(lockUserId: uuid, lockDeviceIds: uuid[]): Promise<AxiosResponse> {
    const response = await this.api.authenticated.delete<unknown>(`${this.path}/${lockUserId}/device_access`, {
      params: { deviceIds: lockDeviceIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    return response
  }

  async sendPinEmail(lockUserId: uuid): Promise<unknown> {
    const response = await this.api.authenticated.post<unknown>(`${this.path}/${lockUserId}/send_pin_email`)
    return response.data
  }

  async batchUpdate(clientID: uuid, file: File): Promise<LockUser[]> {
    const formData = new FormData()
    formData.append('file', file)
    const response = await this.api.authenticated.put<PaginatedResponse<LockUser>>(`${this.path}/batch_update/${clientID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data.items
  }

  async replaceBulkDeviceAccess(clientID: uuid, userIDs: uuid[], grants: LockDeviceAccessGrantForm[]): Promise<AxiosResponse> {
    const response = await this.api.authenticated.post<unknown>(`${this.path}/bulk_replace_device_access/${clientID}`, {
      user_ids: userIDs,
      grants: grants,
    })
    return response
  }
}

export default new LockUserController(instance)
