import { Vue, Component } from "vue-property-decorator"

import DataTable from '@/components/DataTable.vue'
import Loading from '@/components/Loading.vue'
import PageBannerHeader from '@/components/PageBannerHeader.vue'

// TableBasedView should be used as the parent class for any Vue component that
// wants to display results in a table and make use of the DataTable component.
//
@Component({
  components: {
    DataTable,
    Loading,
    PageBannerHeader
  }
})
export default class TableBasedView extends Vue {}
