

















import { Component, Prop, Vue } from 'vue-property-decorator'
import ChartCard from '@/components/charts/ChartCard.vue'
import LostConnectionIcon from '@/assets/img/lost-connection-icon.svg'
import ConnectionIcon from '@/assets/img/connection-icon.svg'
import { PAGE_NAMES, PROXY_ID } from '@/api/misc'
import { ACTIONS, REPORT_TYPE, LAST_SEEN_THRESHOLD } from '@/store/modules/reports/inter-connect-lock-reports'
import { LoadingStatus } from '@/store/types'
import { InterConnectLockTrackingChartData } from '@/store/modules/dashboard/inter-connect-lock-tracking-table'

@Component({
  components: {
    ChartCard,
    LostConnectionIcon,
    ConnectionIcon,
  }
})
export default class LostConnectionChart extends Vue {
  @Prop({ required: true }) chartData!: InterConnectLockTrackingChartData
  @Prop({ required: true }) status!: LoadingStatus

  get selectedChartData(): number[] {
    return [
      this.chartData.totalMissingConnectionLocks,
      this.chartData.totalLocks - this.chartData.totalMissingConnectionLocks,
    ]
  }

  get issues(): number {
    return this.chartData.totalMissingConnectionLocks
  }

  get description(): string {
    return this.issues > 0 ? 'cannot connect to the internet' : 'All devices are connected to the internet'
  }

  viewReport(): void {
    this.$store.dispatch(ACTIONS.CLEAR_ALL)
    this.$store.dispatch(ACTIONS.UPDATE_REPORT_TYPE, REPORT_TYPE.CURRENT_SETTINGS)
    this.$store.dispatch(ACTIONS.UPDATE_LOCK_SETTINGS_FILTERS, { lastSeenThresholds: [LAST_SEEN_THRESHOLD.TWENTY_FOUR_HOURS] })
    this.$router.push({ name: PAGE_NAMES.SMART_LOCK_REPORTS, query: { [PROXY_ID]: this.$router.currentRoute.query.proxyId }})
  }
}
