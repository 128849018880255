















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Toggle extends Vue {
  @Prop() value!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: 'medium'}) size!: string

  checked(isChecked: boolean): void {
    this.$emit('input', isChecked)
  }

  get label(): string {
    return this.value ? 'On': 'Off'
  }
}
