






















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DatePicker } from 'v-calendar'

@Component({
  components: {
    DatePicker
  }
})
export default class DatepickerWrapper extends Vue {
  @Prop({ default: false }) isRange!: boolean
  @Prop({ default: '' }) placeholder!: string
  @Prop({ default: '' }) rangeStartPlaceholder!: string
  @Prop({ default: '' }) rangeEndPlaceholder!: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: false }) required!: boolean
  /** Amount of days to automatically set on load for ranges ex: 14 == now -> 2 weeks from now */
  @Prop({ default: 0 }) rollingDays!: number
  /** Debounce time in milliseconds */
  @Prop({ default: 200 }) inputDebounceTime!: number
  /** Inital value to display */
  @Prop() value!: Date

  date: Date | null = null
  range: { start: Date, end: Date } | null = null

  mounted(): void {
    if (this.isRange && this.rollingDays > 0) {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + this.rollingDays);
      this.range = {
        start: new Date(),
        end: futureDate
      }
    }

    if (this.value) {
      this.date = this.value
    }
  }

  @Watch('range')
  rangeChanged(newRange: { start: Date, end: Date }): void {
    // Set start to beginning of day and end to end of day
    newRange.start.setHours(0, 0 ,0, 0)
    newRange.end.setHours(23, 59, 59, 999)
    this.$emit('rangeChange', newRange)
  }

  @Watch('value')
  valueChanged(): void {
    this.date = this.value
  }

  @Watch('date')
  dateChanged(newDate: Date): void {
    this.$emit('dateChange', newDate)
  }
}
