


















































import { Component, Vue } from 'vue-property-decorator'
import WarningIcon from '@/assets/img/warning-icon.svg'
import ErrorIcon from '@/assets/img/error.svg'
import ArrowIcon from '@/assets/img/arrow-forward-icon.svg'
import devices, { Cabinet, DeviceType } from '@/api/devices'
import { MaintenanceAlert, MAINTENANCE_STATUS } from '@/api/maintenance-alerts'
import { PaginatedResponse } from '@/api'
import { PAGE_NAMES, PROXY_ID } from '@/api/misc'
import { ACTIONS as REPORT_ACTIONS, DRYING_UNIT_REPORT_TYPE } from '@/store/modules/reports/drying-unit-reports'
import { convertToUuidArray } from '@/util'
import Card from '@/components/Card.vue'

@Component({
  components: {
    WarningIcon,
    ErrorIcon,
    ArrowIcon,
    Card,
  }
})
export default class MaintenanceAlerts extends Vue {
  MAINTENANCE_STATUS = MAINTENANCE_STATUS

  devices: MaintenanceAlert[] = []
  OFFSET = 0
  PAGE_LIMIT = -1
  EXPIRATION_DURATION = 26 // Weeks

  async mounted(): Promise<void> {
    const proxyIds = convertToUuidArray(this.$route.query.proxyId)

    this.devices = await this.loadData(proxyIds)
  }

  async loadData(proxyIds: string[]): Promise<MaintenanceAlert[]> {
    const deviceResults = await devices.list(
      this.OFFSET,
      this.PAGE_LIMIT,
      undefined,
      proxyIds.length > 0 ? proxyIds : this.$store.getters.clientId,
      [DeviceType.CABINET],
      [],
    ) as PaginatedResponse<Cabinet>

    const results: MaintenanceAlert[] = []
    for (let device of deviceResults.items) {
      // Extract the filter last reset
      let filterLastReset = new Date(device?.cabinetSettings?.cabinetFilterLastReset ?? '')
      filterLastReset = this.getXWeeksForward(filterLastReset, this.EXPIRATION_DURATION)
      if (device.cabinetSettings && this.validateRange(filterLastReset)) {
        results.push({
          name: device.name,
          date: filterLastReset,
          status: this.determineExpired(filterLastReset),
          type: 'Filter',
        })
      }

      // Extract the hose last reset
      let hoseLastReset = new Date(device?.cabinetSettings?.cabinetHoseLastReset ?? '')
      hoseLastReset = this.getXWeeksForward(hoseLastReset, this.EXPIRATION_DURATION)
      if (device.cabinetSettings && this.validateRange(hoseLastReset)) {
        results.push({
          name: device.name,
          date: hoseLastReset,
          status: this.determineExpired(hoseLastReset),
          type: 'Hose',
        })
      }
    }

    // Sort items to oldest events first
    return results.sort((a: MaintenanceAlert, b: MaintenanceAlert) => { return a.date.getTime() - b.date.getTime() })
  }

  determineExpired(date: Date): string {
    const currentDate = new Date()

    if (date < currentDate) {
      return this.MAINTENANCE_STATUS.EXPIRED
    }

    return this.MAINTENANCE_STATUS.UPCOMING
  }

  validateRange(date: Date): boolean {
    return date < this.getXWeeksForward(new Date(), 3)
  }

  getXWeeksForward(date: Date, numberOfWeeks: number): Date {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + numberOfWeeks * 7)
    return newDate
  }

  viewReport(): void {
    this.$store.dispatch(REPORT_ACTIONS.CLEAR_ALL)
    this.$store.dispatch(REPORT_ACTIONS.UPDATE_REPORT_TYPE, DRYING_UNIT_REPORT_TYPE.MAINTENANCE_LOG)
    this.$router.push({ name: PAGE_NAMES.DRYING_UNIT_REPORTS, query: { [PROXY_ID]: this.$router.currentRoute.query.proxyId }})
  }
}
