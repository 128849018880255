




































import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class FormWrapper extends Vue {
  @Prop({ default: "Back" }) backButtonText!: string
  @Prop({ default: "Enter" }) submitText!: string
  @Prop() errorMessage?: string
  @Prop({ default: false }) canDelete!: boolean
  @Prop({ default: false }) loading!: boolean
}
