

















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import FormWrapper from '@/components/forms/FormWrapper.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import { PAGE_NAMES, stateListDict } from '@/api/misc'
import { Client, StateOption } from '@/api/clients'
import order, { Order } from '@/api/order'
import { ACTIONS as CART_ACTIONS, GETTERS as CART_GETTERS, OrderedPart } from '@/store/modules/parts/cart'
import api, { PaginatedResponse } from '@/api'


@Component({
  components: {
    FormWrapper,
    SingleselectWrapper,
  }
})
export default class InformationForm extends Vue {
  stateDict = stateListDict
  selectedClientState: StateOption|null = null
  selectedShippingState: StateOption|null = null
  selectedClient: Client|null = null

  newOrder: Order = {
    woocommerceOrderId: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    message: '',
    clientId: '',
    parts: [],
    paymentMethod: '',
    createdAt: null,
    client: null,
  }
  clients: Client[] = []

  preFilledClientInfo = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: ''
  }

  sameAsClientInfoChecked = false

  errorMessage = ''
  status = 'loading'

  async mounted(): Promise<void> {
    await this.loadClients()
    this.status = 'loaded'

    // Load initial data we believe the user might already fill out
    this.newOrder.firstName = this.$store.getters.user?.first_name ?? ''
    this.newOrder.lastName = this.$store.getters.user?.last_name ?? ''
    this.newOrder.email = this.$store.getters.user?.username ?? ''
  }

  async submit(): Promise<void> {
    this.status = 'saving'
    this.newOrder.state = this.selectedShippingState?.abbreviation as string

    if (this.selectedClient) {
      this.newOrder.clientId = this.selectedClient.id
    } else {
      this.errorMessage = "Client is required."
      return
    }

    try {
      // Store a snapshot of the selected parts to submit for the order
      this.newOrder.parts = this.selectedParts
      await order.create(this.newOrder)
      await this.$store.dispatch(CART_ACTIONS.CLEAR)

      this.navigateToOrderParts()
      this.status = 'saved'
    } catch (error) {
      this.errorMessage = error.response?.data.description
      this.status = 'error'
    }
  }

  navigateToOrderParts(): void {
    this.$router.push({ name: PAGE_NAMES.ORDER_PARTS, query: { proxyId: this.$router.currentRoute.query.proxyId } })
  }

  async loadClients(): Promise<void> {
    try {
      const response = await api.authenticated.get<PaginatedResponse<Client>>('clients', {
        params: { limit: -1, offset: 0 }
      })
      this.clients = response.data.items
      this.clients.sort((a, b) => a.name.localeCompare(b.name))
    } catch (error) {
      this.errorMessage = error.response?.data.description
      this.status = 'error'
    }
  }

  clientSelected(client: Client): void {
    this.selectedClient = client
    this.preFilledClientInfo = {
      address1: client.address1,
      address2: client.address2 ?? '',
      city: client.city,
      state: client.state,
      zipcode: client.zipcode
    }
    // Need to do this for the dropdown to look correct
    this.selectedClientState = this.stateDict.find(state => state.abbreviation === client.state) ?? null

    if (this.sameAsClientInfoChecked) {
      this.fillShippingInfoFromClient()
    }
  }

  useClientInfoChecked(): void {
    if (this.sameAsClientInfoChecked) {
      this.fillShippingInfoFromClient()
    } else {
      this.newOrder.address1 = ""
      this.newOrder.address2 = ""
      this.newOrder.city = ""
      this.selectedShippingState = null
      this.newOrder.state = ""
      this.newOrder.zipcode = ""
    }
  }

  get isCartEmpty(): boolean {
    return this.$store.getters[CART_GETTERS.TOTAL_PART_COUNT] === 0
  }

  get isSaving(): boolean {
    return this.status === 'saving'
  }

  get selectedParts(): OrderedPart[] {
    return this.$store.getters[CART_GETTERS.GET_SELECTED_PARTS];
  }

  private fillShippingInfoFromClient(): void {
    this.newOrder.address1 = this.preFilledClientInfo.address1
    this.newOrder.address2 = this.preFilledClientInfo.address2
    this.newOrder.city = this.preFilledClientInfo.city
    this.selectedShippingState = this.stateDict.find(state => state.abbreviation === this.preFilledClientInfo.state) ?? null
    this.newOrder.state = this.selectedShippingState?.abbreviation ?? ''
    this.newOrder.zipcode = this.preFilledClientInfo.zipcode
  }
}
