




import { Component, Vue } from 'vue-property-decorator'
import DevicesTable from '@/views/manage-devices/tables/DevicesTable.vue'
import { ACTIONS } from '@/store/modules/device-management'
import { DeviceType } from '@/api/devices'

@Component({
  components: {
    DevicesTable,
  }
})
export default class ClientDevices extends Vue {
  ACTIONS = ACTIONS
  DeviceType = DeviceType

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }
}
