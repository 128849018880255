










































































































import { Component, Prop, Ref } from "vue-property-decorator"
import RoleBasedView from '@/views/RoleBasedView'
import Wizard from '@/components/Wizard.vue'
import Page from '@/components/WizardPage.vue'
import FormWrapper from '@/components/forms/FormWrapper.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import ClientSelect from '@/components/forms/selects/ClientSelect.vue'
import MultiClientDepartmentSelect from '@/components/forms/selects/MultiClientDepartmentSelect.vue'
import { NewUserForm, USER_TYPE } from '@/api/users'
import axios from 'axios'
import { Client, ClientPermission, PERMISSION_TYPE } from "@/api/clients"
import { ACTIONS } from '@/store/modules/user-management/website-user-management'
import SelectDeviceTable from '@/components/SelectDeviceTable.vue'
import { convertToUuidArray } from '@/util'


@Component({
  components: {
    Wizard,
    Page,
    FormWrapper,
    SingleselectWrapper,
    ClientSelect,
    MultiClientDepartmentSelect,
    SelectDeviceTable,
  }
})
export default class WebsiteUserWizard extends RoleBasedView {
  ACTIONS = ACTIONS
  USER_TYPE = USER_TYPE

  @Prop({ default: false }) editMode!: boolean

  @Ref('wizard') wizard!: Wizard

  modelProxy: NewUserForm = {
    id: null,
    first_name: '',
    last_name: '',
    is_solaire_admin: false,
    user_type: USER_TYPE.NOT_SELECTED,
    username: '',
    client_permissions: [],
    departments: [],
    devices: [],
  }

  selectedClients: Client[] = []
  userLoading = false
  userPosting = false
  errorMessage = ''

  get canEditDevices(): boolean {
    return this.modelProxy.user_type === USER_TYPE.DEPARTMENT_USER
  }

  get clientPermissions(): ClientPermission[] {
    return this.selectedClients.map((client) => {
      return {
        client_id: client.id, action: this.canEditDevices ? PERMISSION_TYPE.VIEW : PERMISSION_TYPE.ADMIN
      }
    })
  }

  async mounted(): Promise<void> {
    const userId = this.$route.query.user
    if (typeof userId === 'string') {
      this.userLoading = true
      try {
        const fullUser = await this.$store.dispatch(this.ACTIONS.GET, userId)
        this.modelProxy = { ...this.modelProxy, ...fullUser }
        this.selectedClients = fullUser.clients
      } catch (err) {
        if (axios.isAxiosError(err)) {
          this.errorMessage = err.response?.data.description
        }
      }
      this.userLoading = false
    }
  }

  onUserTypeChange(newUserType: USER_TYPE): void {
    this.modelProxy.user_type = newUserType
    this.modelProxy.client_permissions = this.clientPermissions
    if (newUserType !== USER_TYPE.DEPARTMENT_USER) {
      this.modelProxy.devices = []
    }
  }

  selectInitialClientIfImpersonating(clients: Client[]): void {
    if (!this.editMode && this.isAdminImpersonating) {
      const proxyIds = convertToUuidArray(this.$route.query.proxyId)
      this.selectedClients = proxyIds.map(id => clients.find(client => client.id === id)) as Client[]
    }
  }

  async nextOrSubmit(): Promise<void> {
    if (this.wizard.selectedIndex === 0 && this.canEditDevices) {
      this.wizard.selectNextPage()
    } else {
      this.errorMessage = ''
      this.userPosting = true

      // update the permission set
      this.modelProxy.client_permissions = this.clientPermissions

      try {
        if (this.editMode) {
          await this.$store.dispatch(this.ACTIONS.UPDATE, this.modelProxy)
        } else {
          await this.$store.dispatch(this.ACTIONS.CREATE, this.modelProxy)
        }
      } catch (err) {
        this.handlePostUserError(err)
      }
      this.userPosting = false
      this.$parent.$emit('close')
    }
  }

  handlePostUserError(err: unknown): void {
    if (axios.isAxiosError(err)) {
      switch(err.response?.data.description) {
        case 'Username already exists':
          this.wizard.selectPreviousPage()
          this.errorMessage = 'Email already in use'
          break
        case null:
        case undefined:
          this.errorMessage = 'Undefined error'
          break
        default:
          this.errorMessage = err.response?.data.description
      }
    }
  }

  async deleteUser(): Promise<void> {
    this.errorMessage = ''
    try {
      await this.$store.dispatch(this.ACTIONS.DELETE, this.modelProxy.id)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.errorMessage = err.response?.data.description
      }
    }
    this.$parent.$emit('close')
  }
}
