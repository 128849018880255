import Vue from 'vue'
import Vuex from 'vuex'
import { module as clientManagement, namespace as clientManagementNamespace } from './modules/client-management'
import { module as deviceState, namespace as deviceManagementNamespace } from './modules/device-management'
import { module as orderParts, namespace as orderPartsNamespace } from './modules/parts/index'
import { module as userManagement, namespace as userManagementNamespace } from './modules/user-management/index'
import { module as dashboardState, namespace as dashboardNamespace } from './modules/dashboard'
import { module as reportsState, namespace as reportsNamespace } from './modules/reports'
import { module as clientGroupState, namespace as clientGroupNamespace } from './modules/client-group-management'
import { module as notificationManagementState, namespace as notificationManagementNamespace} from './modules/notification-management'
import { module as notificationAlertState, namespace as notificationAlertNamespace } from './modules/notification-alerts'
import { module as modalState } from './modules/modal'
import { module as userState } from './modules/user'

Vue.use(Vuex)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {

}

export default new Vuex.Store<RootState>({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    [clientManagementNamespace]: clientManagement,
    [userManagementNamespace]: userManagement,
    [deviceManagementNamespace]: deviceState,
    [dashboardNamespace]: dashboardState,
    [orderPartsNamespace]: orderParts,
    [clientGroupNamespace]: clientGroupState,
    [reportsNamespace]: reportsState,
    [notificationManagementNamespace]: notificationManagementState,
    [notificationAlertNamespace]: notificationAlertState,
    modalState,
    userState,
  },
})
