



















import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import MinusIcon from '@/assets/img/minus-icon.svg';
import PlusIcon from '@/assets/img/plus-icon.svg';

@Component({
  components: {
    MinusIcon,
    PlusIcon
  }
})
export default class Counter extends Vue {
  MAX_VALUE = 2147483647
  @Prop({ default: "0" }) initialValue!: string
  value = "0"

  mounted(): void {
    this.value = this.initialValue
  }

  get valueLength(): number {
    return this.value.length
  }
  
  valueInput(): void {
    this.emitValueChange()
  }
  
  @Watch("value")
  valueWatcher(newValue: string): void {
    const numVal = parseInt(newValue, 10)
    if (numVal > this.MAX_VALUE) {
      this.value = this.MAX_VALUE.toString(10)
    } else if (numVal < 0 || isNaN(numVal)) {
      this.value = "0"
    } else {
      this.value = numVal.toString(10)
    }
  }
  
  decrement(): void {
    const newValue = parseInt(this.value, 10) - 1
    this.value = newValue.toString(10)
    if (newValue >= 0) {
      this.emitValueChange()
    }
  }
  
  increment(): void {
    const newValue = parseInt(this.value, 10) + 1
    this.value = newValue.toString(10)
    if (newValue <= this.MAX_VALUE) {
      this.emitValueChange()
    }
  }
  
  emitValueChange(): void {
    this.$emit('valueChange', parseInt(this.value, 10))
  }
}
