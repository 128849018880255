




















































import { Component } from 'vue-property-decorator'
import DataTable from '@/components/DataTable.vue'
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import { TableColumn } from '@/components/table-column'
import InterConnectLockIcon from '@/assets/img/inter-connect-lock-icon.svg'
import TableFooter from '@/components/TableFooter.vue'
import { Lock } from '@/api/devices'
import { convertToUuidArray, getLocaleStringFromIsoDate } from '@/util'
import { uuid, timestamp } from '@/api/models'
import { ACTIONS, GETTERS } from '@/store/modules/dashboard/inter-connect-lock-tracking-table'
import { ACTIONS as REPORT_ACTIONS, REPORT_TYPE as SMART_LOCK_REPORT_TYPE } from '@/store/modules/reports/inter-connect-lock-reports'
import { PAGE_NAMES, PROXY_ID } from '@/api/misc'
import { DateTime } from 'luxon'
import ArrowIcon from '@/assets/img/arrow-forward-icon.svg'
import NoProblemLockTrackingIcon from '@/assets/img/no-problems-lock-tracking-icon.svg'
import LowBatteryIcon from '@/assets/img/low-battery-icon.svg'
import LostConnectionIcon from '@/assets/img/lost-connection-icon.svg'

@Component({
  components: {
    DataTable,
    InterConnectLockIcon,
    TableFooter,
    ArrowIcon,
    NoProblemLockTrackingIcon,
    LowBatteryIcon,
    LostConnectionIcon,
  }
})
export default class InterConnectLockTrackingTable extends VuexBackedTableBasedView<Lock> {
  ACTIONS = ACTIONS
  GETTERS = GETTERS

  clientIds: uuid[] = []
  proxyIds: uuid[] = []

  loading = true

  async mounted(): Promise<void> {
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(convertToUuidArray(clientFilterIds))
    this.loading = false
  }

  destroyed(): void {
    this.$store.dispatch(this.ACTIONS.CLEAR)
  }

  viewReport(): void {
    this.$store.dispatch(REPORT_ACTIONS.CLEAR_ALL)
    this.$store.dispatch(REPORT_ACTIONS.UPDATE_REPORT_TYPE, SMART_LOCK_REPORT_TYPE.TRANSACTION_LOG)
    this.$router.push({ name: PAGE_NAMES.SMART_LOCK_REPORTS, query: { [PROXY_ID]: this.$router.currentRoute.query.proxyId }})
  }

  get columns(): TableColumn<Lock>[] {
    return [
      {
        id: 'deviceName',
        title: 'Lock Name',
        headerColumn: true,
        sticky: true,
        value: (row: Lock) => row.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'department',
        title: 'Department',
        value: (row: Lock) => row.department.name,
        field: 'department.name',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'location',
        title: 'Location',
        value: (row: Lock) => row.location ?? '',
        field: 'device.location',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'lastAccessed',
        title: 'Last Accessed',
        value: (row: Lock) => getLocaleStringFromIsoDate(row.lastAccessed),
        field: 'device.last_accessed',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'lastConnected',
        title: 'Last Connected',
        value: (row: Lock) => getLocaleStringFromIsoDate(row.lastSeen),
        field: 'device.last_seen',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'attention',
        title: 'Needs Attention',
        headerClass: 'columnSmall',
      },
    ]
  }

  dateIsOverADayAgo(lastSeen: timestamp): boolean {
    const yesterday = DateTime.now().minus({ days: 1 })
    const lockLastSeen = DateTime.fromISO(lastSeen)
    return lockLastSeen < yesterday
  }
}
