<template>
  <div class="popup">
    <slot></slot>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MenuPopup extends Vue {}
</script>

<style scoped>
.popup {
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow: visible;
}
</style>