import { Module } from 'vuex'
import { RootState } from '@/store'
import {
  namespaced,
  TABLE_ACTIONS,
  TABLE_GETTERS,
  tableActions,
  tableGetters,
  tableMutations,
  tableState,
  TableState,
} from '@/store/mixins/table'
import { PaginatedResponse } from '@/api'
import notifications, {
  getCabinetNotificationTypes,
  getLockNotificationTypes,
  NotificationPreference,
  NotificationPreferenceUpdate
} from '@/api/notifications'
import { DeviceType } from '@/api/devices'
import { DateTime } from 'luxon'

enum NOTIFICATION_ACTIONS {
  UPDATE_PREFERENCE = 'UPDATE_PREFERENCE',
  SET_NOTIFICATIONS_TYPE = 'SET_NOTIFICATIONS_TYPE',
}

enum NOTIFICATION_MUTATIONS {
  UPDATE_NOTIFICATIONS_TYPE = 'UPDATE_NOTIFICATIONS_TYPE',
}

export const namespace = 'notificationManagement'
export const ACTIONS = namespaced({ ...TABLE_ACTIONS, ...NOTIFICATION_ACTIONS }, namespace)
export const GETTERS = namespaced({ ...TABLE_GETTERS }, namespace)


interface NotificationManagementState extends TableState<NotificationPreference> {
  notification_types: DeviceType
}

export function defaultState(): NotificationManagementState {
  return {
    ...tableState<NotificationPreference, NotificationManagementState>(),
    notification_types: DeviceType.CABINET
  }
}

export const module: Module<NotificationManagementState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: tableGetters<NotificationPreference, NotificationManagementState>(),
  mutations: tableMutations<NotificationPreference, NotificationManagementState>({
    [NOTIFICATION_MUTATIONS.UPDATE_NOTIFICATIONS_TYPE]: (state: NotificationManagementState, deviceType: DeviceType) => state.notification_types = deviceType,
  }),
  actions: tableActions<NotificationPreference, NotificationManagementState>({
    [TABLE_ACTIONS.FETCH_LIST]: async ({ state }): Promise<PaginatedResponse<NotificationPreference>> => {
      const userNotificationPreferences = await notifications.list(state.offset, state.limit, state.order)
      const notificationTypes = state.notification_types === DeviceType.CABINET ? getCabinetNotificationTypes() : getLockNotificationTypes()

      return {
        items: notificationTypes.map(alertType => ({
          alertType,
          createdAt: DateTime.now().toISO(),
          lastUpdated: DateTime.now().toISO(),
          enabled: userNotificationPreferences.items.some(pref => pref.alertType === alertType),
          interval: userNotificationPreferences.items.find(item => item.alertType === alertType)?.interval
        } as NotificationPreference)),
        total: notificationTypes.length
      }
    },
    [NOTIFICATION_ACTIONS.UPDATE_PREFERENCE]: async (_, notificationPreference: NotificationPreferenceUpdate): Promise<{ enabled: boolean; interval: string | undefined; }> => {
      await notifications.update({
        alertType: notificationPreference.alertType,
        enabled: notificationPreference.enabled,
        interval: notificationPreference.interval
      })
      const { enabled, interval } = notificationPreference
      return { enabled, interval }
    },
    [NOTIFICATION_ACTIONS.SET_NOTIFICATIONS_TYPE]: ({ commit }, deviceType: DeviceType): void => {
      commit(NOTIFICATION_MUTATIONS.UPDATE_NOTIFICATIONS_TYPE, deviceType)
    }
  }),
}
