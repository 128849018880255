


















































import { Component } from 'vue-property-decorator';
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import { TableColumn } from '@/components/table-column'
import TableFooter from '@/components/TableFooter.vue'
import { Device, DeviceType } from '@/api/devices'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import { ACTIONS, GETTERS } from '@/store/modules/device-management'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import { convertToUuidArray, getMonthYearFromIsoDate, getPriceFromString, monthDiff } from '@/util'
import Modal from '@/components/Modal.vue'
import ClientSubscriptionWizard from '@/components/forms/ClientDeviceSubscriptionWizard.vue'
import EditButton from '@/components/EditButton.vue'

@Component({
  components: {
    TableFooter,
    Modal,
    ClientSubscriptionWizard,
    EditButton,
    DeviceCabinetIcon,
  }
})
export default class ClientSubscriptions extends VuexBackedTableBasedView<Device> {
  MODAL_TYPE = MODAL_TYPE
  ACTIONS = ACTIONS
  GETTERS = GETTERS

  clientIds: string[] = []

  async mounted(): Promise<void> {
    this.clientIds = convertToUuidArray(this.$route.query.clientId)

    await this.$store.dispatch(this.ACTIONS.UPDATE_DEVICE_TYPES, [DeviceType.CABINET, DeviceType.LOCK])
    await this.loadData(this.clientIds)
  }

  editDevice(row: Device): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.EDIT_DEVICE_SUBSCRIPTION, device: row.id })
  }

  get numberOfDevices(): number {
    return this.results.length
  }

  get paidTotal(): string {
    return getPriceFromString(this.results.reduce((sum, { subscriptionPaid }) => sum + Number(subscriptionPaid), 0).toString())
  }

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }

  get columns(): TableColumn<Device>[] {
    return [
      {
        title: 'Name',
        headerColumn: true,
        sticky: true,
        value: (row) => row.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        title: 'Device ID',
        value: (row) => row.serialNumber,
        field: 'device.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        title: 'Subscription Start',
        value: (row) => getMonthYearFromIsoDate(row.subscriptionStart),
        field: 'device.subscription_start',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        title: 'Subscription End',
        value: (row) => row.subscriptionEnd ? getMonthYearFromIsoDate(row.subscriptionEnd): '',
        field: 'device.subscription_end',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        title: 'Months Remaining',
        value: (row) => row.subscriptionEnd ? monthDiff(new Date(row.subscriptionStart), new Date(row.subscriptionEnd)).toString() : '',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        title: 'Paid',
        value: (row) => row.subscriptionPaid ? getPriceFromString(row.subscriptionPaid) : '',
        field: 'device.subscription_paid',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        title: 'Edit',
        headerClass: 'columnSmall',
      }
    ]
  }
}
