import { Module } from 'vuex'
import { RootState } from '@/store'
import {
  namespaced,
} from '@/store/mixins/table'
import notifications, {
  Notification,
} from '@/api/notifications'
import { uuid, timestamp } from '@/api/models'

enum NOTIFICATION_ACTIONS {
  LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
  DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_SEEN = 'UPDATE_NOTIFICATIONS_SEEN',
  MARK_NOTIFICATIONS_VIEWED = 'MARK_NOTIFICATIONS_VIEWED',
}

enum NOTIFICATION_GETTERS {
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATION_COUNT = 'NOTIFICATION_COUNT',
  LATEST_EVENT = 'LATEST_EVENT',
}

enum NOTIFICATIONS_MUTATIONS {
  UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS',
  MARK_NOTIFICATIONS_SEEN = 'MARK_NOTIFICATIONS_SEEN',
  MARK_NOTIFICATIONS_VIEWED = 'MARK_NOTIFICATIONS_VIEWED',
}

export const namespace = 'notificationAlert'
export const ACTIONS = namespaced(NOTIFICATION_ACTIONS, namespace)
export const GETTERS = namespaced(NOTIFICATION_GETTERS, namespace)


interface NotificationAlertState {
  notifications: Notification[]
}

function defaultState(): NotificationAlertState {
  return {
    notifications: [],
  }
}

export const module: Module<NotificationAlertState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    [NOTIFICATION_GETTERS.NOTIFICATIONS]: (state: NotificationAlertState): Notification[] => state.notifications,
    [NOTIFICATION_GETTERS.NOTIFICATION_COUNT]: (state: NotificationAlertState): number => state.notifications?.length ?? 0,
    [NOTIFICATION_GETTERS.LATEST_EVENT]: (state: NotificationAlertState): timestamp|null => {
      // https://stackoverflow.com/questions/36577205/what-is-the-elegant-way-to-get-the-latest-date-from-array-of-objects-in-client-s
      if (state.notifications.length) {
        const event = state.notifications.reduce((a, b) => (a.createdAt > b.createdAt ? a : b))
        return event.createdAt
      }
      return null
    }
  },
  mutations: {
    [NOTIFICATIONS_MUTATIONS.UPDATE_NOTIFICATIONS]: (state, notifications: Notification[]): void => {
      state.notifications = notifications
    },
    [NOTIFICATIONS_MUTATIONS.REMOVE_NOTIFICATION]: (state, notificationId: uuid): void => {
      const index = state.notifications.findIndex((notification) => notification.id === notificationId)
      if (index >= 0) {
        state.notifications.splice(index, 1)
      }
    },
    [NOTIFICATIONS_MUTATIONS.REMOVE_ALL_NOTIFICATIONS]: (state): void => {
      state.notifications.splice(0, state.notifications.length)
    },
    [NOTIFICATIONS_MUTATIONS.MARK_NOTIFICATIONS_SEEN]: (state): void => {
      state.notifications.forEach((notification: Notification) => notification.seen = true)
    },
    [NOTIFICATIONS_MUTATIONS.MARK_NOTIFICATIONS_VIEWED]: (state): void => {
      state.notifications.forEach((notification: Notification) => notification.viewedLocally = true)
    },
  },
  actions: {
    [NOTIFICATION_ACTIONS.LOAD_NOTIFICATIONS]: async ({ commit }): Promise<void> => {
      try {
        const response = await notifications.getNotifications(0, -1)

        if (response) {
          commit(NOTIFICATIONS_MUTATIONS.UPDATE_NOTIFICATIONS, response.items)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [NOTIFICATION_ACTIONS.DELETE_NOTIFICATION]: async ({ commit }, notificationId: uuid): Promise<void> => {
      try {
        await notifications.deleteNotification(notificationId)
        commit(NOTIFICATIONS_MUTATIONS.REMOVE_NOTIFICATION, notificationId)
      } catch (error) {
        console.error(error)
      }
    },
    [NOTIFICATION_ACTIONS.DELETE_ALL_NOTIFICATIONS]: async ({ commit }, eventDate: timestamp): Promise<void> => {
      try {
        await notifications.deleteAllNotifications(eventDate)
        commit(NOTIFICATIONS_MUTATIONS.REMOVE_ALL_NOTIFICATIONS)
      } catch (error) {
        console.error(error)
      }

    },
    [NOTIFICATION_ACTIONS.UPDATE_NOTIFICATIONS_SEEN]: async ({ commit }, eventDate: timestamp): Promise<void> => {
      try {
        await notifications.updateSeen(eventDate)
        commit(NOTIFICATIONS_MUTATIONS.MARK_NOTIFICATIONS_SEEN)
      } catch (error) {
        console.error(error)
      }
    },
    [NOTIFICATION_ACTIONS.MARK_NOTIFICATIONS_VIEWED]: async ({ commit }): Promise<void> => {
      commit(NOTIFICATIONS_MUTATIONS.MARK_NOTIFICATIONS_VIEWED)
    },
  },
}
