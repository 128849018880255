import { Module } from 'vuex'
import clientGroups, { ClientGroup } from '@/api/client-groups'
import { RootState } from '@/store'
import { PaginatedResponse } from '@/api'
import { namespaced } from '@/store/mixins/table'

export enum CLIENT_GROUP_ACTIONS {
  FETCH_LIST = 'FETCH_LIST',
  CREATE_CLIENT_GROUP = 'CREATE_CLIENT_GROUP',
}

export enum CLIENT_GROUP_MUTATIONS {
  UPDATE_USE_CACHE = 'UPDATE_USE_CACHE'
}

interface ClientGroupState {
  useCache: boolean
}

export const namespace = 'clientGroupManagement'
export const ACTIONS = namespaced(CLIENT_GROUP_ACTIONS, namespace)

export const module: Module<ClientGroupState, RootState> = {
  namespaced: true,
  state: {
    useCache: false,
  },
  getters: {},
  mutations: {
    [CLIENT_GROUP_MUTATIONS.UPDATE_USE_CACHE]: (state, useCache) => {
      state.useCache = useCache
    }
  },
  actions: {
    [CLIENT_GROUP_ACTIONS.CREATE_CLIENT_GROUP]: async ({ commit }, clientGroupName: string): Promise<ClientGroup> => {
      const clientGroup = await clientGroups.create(clientGroupName)
      commit(CLIENT_GROUP_MUTATIONS.UPDATE_USE_CACHE, false)
      return clientGroup
    },
    [CLIENT_GROUP_ACTIONS.FETCH_LIST]: async ({ state, commit }): Promise<PaginatedResponse<ClientGroup>> => {
      const groups = await clientGroups.list(0, -1, [], state.useCache)
      commit(CLIENT_GROUP_MUTATIONS.UPDATE_USE_CACHE, true)
      return groups
    },
  }
}